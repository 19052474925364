/**
 * Tunnel light (no header)
 */
body.in_tunnel:not(.step_1) {

    #site_head_wrap,
    #cart_total .order_gift_form,
    #cart_total .mot_personnalise_form {
        display: none;
    }

    a#cartLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 281px;
        height: 50px;
        background: transparent url(../svg/pierre-marcolini.svg) no-repeat center;
        background-size: contain;
        margin: 4rem auto 2.8rem;
        font-size: 0;
    }

    .sticky+#site_global_wrap {
        margin: 0 auto;
    }

    #cart_total .bill_line {

        &.total_produit,
        &.discount {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            font-size: 15px;

            label:not(.w-text-input) {
                font-family: $primaryFont;
                font-size: 15px;
                text-transform: initial;
                letter-spacing: initial;
            }
        }

        &.total_produit {
            cursor: pointer;
            margin-top: 1.9rem;

            label {
                position: relative;
                cursor: pointer;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $primaryColor;
                }

                &:after {
                    content: '+';
                    margin-left: 0.5rem;
                }
            }

            &:hover label:before {
                display: none;
            }

            &.open label:after {
                content: '-';
            }
        }

        &.discount {
            margin: 1rem 0 1.5rem;
        }
    }

    #ui-datepicker-div {
        width: 325px;
        background-color: $whiteColor;
        border: 2px solid $lightBorder;
        z-index: 11!important;

        .ui-widget-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            background-color: $lightBorder;
            font-size: 15px;
        }

        .ui-datepicker-prev,
        .ui-datepicker-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1rem;

            span {
                font-size: 0;
            }

            &:before {
                font-family: monospace;
            }
        }

        .ui-datepicker-prev {
            left: 1rem;

            &:before {
                content: '<';
            }
        }

        .ui-datepicker-next {
            right: 1rem;

            &:before {
                content: '>';
            }
        }

        table.ui-datepicker-calendar {
            width: 80%;
            margin: 0.5rem auto;
            border-collapse: separate;
            border-spacing: 0.1rem;
            font-size: 15px;

            th,
            td {
                width: 24px;
                height: 24px;
                text-align: center;
                vertical-align: middle;
                padding: 0.4rem 0 0.1rem;

                &.ui-datepicker-unselectable {
                    color: $borderColor;
                }

                &:not(.ui-datepicker-unselectable):hover {
                    background-color: $ctaColor;
                    color: $whiteColor;
                    transition: background-color ease-in-out 150ms;

                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        color: $whiteColor;
                    }
                }

                a:hover {
                    background-color: $ctaColor;
                    color: $whiteColor;
                }
            }

            th:not(.ui-datepicker-unselectable) {
                font-weight: 400;

                &:hover {
                    background-color: $whiteColor;
                    color: $primaryColor;
                }
            }

            tbody tr td {
                border: 1px solid $borderColor;
            }
        }
    }
}

/**
 * Delivery bloc
 */
.bloc_livraison {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 96.8%;
    margin: 8px 0 50px 11px;
    padding: 3.1rem 1.5rem 1.6rem;
    border: 1px solid $borderColor;
    transition: border-color 0.3s ease-in-out;

    .checkbox {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -7.5px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid $borderColor;
        background-color: $whiteColor;
        box-shadow: inset 0 0 0 2px $whiteColor;
        transition: all 0.3s ease-in-out;
    }

    &:hover,
    &.active {
        border-color: $ctaColor;

        .checkbox {
            background-color: $ctaColor;
            border-color: $ctaColor;
        }
    }

    .left_delivery {
        position: relative;
        z-index: 2;

        .adresse {
            text-transform: capitalize;
        }
    }

    .choix_livraison {
        display: block !important;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 0;
        margin: 0;
        appearance: none;
        z-index: 1;

        &:not(#livr_multiple) {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .title_type_livraison,
    .transporteur_info {
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
    }

    .title_type_livraison {
        top: -0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 2rem;
        padding: 0 2rem;
        background-color: $whiteColor;
        font-size: 24px;
    }

    .transporteur_info {
        top: 1.1rem;
        font-family: $ctaFont;
        font-size: 14px;
        text-transform: lowercase;
        letter-spacing: 0.2rem;
    }

    #infos_livr_domicile {
        position: relative;
        font-size: 15px;
        z-index: 2;
    }

    p.adresse {
        margin: 0.2rem 0 0.8rem;
    }

    a.show_modif_info {
        display: inline-block;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        font-family: $primaryFont;
        font-size: 14px;
        color: $primaryColor !important;
        text-decoration: underline;
        line-height: initial;
        text-align: left;
        letter-spacing: initial;
        box-shadow: none;
        z-index: 2;

        span {
            color: $primaryColor !important;
            display: inline-block;
            text-decoration: inherit;

            &:first-letter {
                text-transform: uppercase;
            }
        }

        &:hover {
            text-decoration: none;
            color: $primaryColor;
        }

        &::after {
            content: " ";
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            z-index: 1;
        }
    }

    .block-delivery-date {
        margin-top: 20px;

        label {
            display: block;
            font-family: $ctaFont;
            font-size: 14px;
            text-transform: lowercase;
            letter-spacing: 0.2rem;
            margin-bottom: 0.8rem;
        }

        input.order_delivery_date {
            width: 325px;
            height: 48px;
            border: 1px solid $borderColor !important;
            text-indent: 1rem;
        }

        .mention_supplementaire {
            margin-top: 12px;
            color: $secondaryColor;
            font-size: 13px;
            font-style: italic;
            line-height: 15px;
        }

        .err_delivery_date {
            margin-top: 12px;
            color: $red;
            font-size: 13px;
        }
    }

    .right_delivery {
        position: absolute;
        top: 110px;
        right: 1.5rem;
        font-size: 24px;
        z-index: 2;
    }

    .adresse_change {
        position: relative;
        padding: 2rem 0 0 0;
        z-index: 2;

        .delivery_form_title {
            font-family: $ctaFont;
            font-size: 13px;
            text-transform: lowercase;
            letter-spacing: 0.2rem;
            margin-bottom: 0.8rem;
        }

        .w-dropdown {
            border-color: $borderColor;
        }

        .w-input-element:not([type='radio']):not([type='checkbox']) {
            border-color: $borderColor;
            box-sizing: border-box;
            border-radius: 0;
        }

        .w-group-label {
            display: none;
        }

        .w-radio-group .w-radio-input .w-input-element+.w-input-label span {
            color: $primaryColor;
        }

        .wrapper_form_adresse{
            
            .w-input-group {
                margin-bottom: 0rem;
            }
            
            .w-newuser-form{
                .last-is-hidden + .w-form-line{
                    display: none;
                }

                .form-element-wrapper{
                    display: flex;
                    .has-civility, .addrCivility{
                        width: 50%;
                        margin-right: 1rem;
                    }
                    .w-form-line:last-of-type{
                        margin-bottom: 1.2rem;
                    }
                }
            }

            .elem_global {
                margin-top: 0;
            }
        }
    }

    .choix_relais_relais_mag {
        position: relative;
        padding-top: 2rem;
        z-index: 2;
        order: 100; // position the block at the bottom of the parent

        .w-form-line::after {
            content: '';
            display: block;
            background-image: url("../img/maps/marker.png");
            background-size: contain;
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
        }
    }

    &.transporteur_Retrait {
        .choix_relais_relais_mag {
            .title_choix_relais {
                display: block;
                font-family: $ctaFont;
                font-size: 14px;
                text-transform: lowercase;
                letter-spacing: 0.2rem;
                margin-bottom: 0.8rem;
            }

            .displayed_result {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                #map_canvas {
                    width: 524px;
                    height: 300px;
                    margin-right: 16px;

                    .gm-style {
                        .gm-ui-hover-effect {
                            position: absolute!important;
                        }

                        .gm-style-iw-c {
                            padding: 1.5rem !important;

                            .gm-style-iw-ch {
                                display: none;
                            }
                        }

                        .gm-style-iw-d {
                            overflow: scroll!important;
                        }
                    }
                }

                .sidebar-right {
                    width: calc(100% - 540px);
                    height: 300px;
                    padding-right: 17px;
                    overflow: hidden;

                    .lb-wrap {
                        -ms-overflow-style: none;
                        scrollbar-width: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }

                    .lb-v-scrollbar {
                        width: 1px;

                        .lb-v-scrollbar-slider {
                            width: 1px;
                        }
                    }

                    .details {
                        .kp {
                            font-size: 15px;
                            line-height: 18px;
                            border-bottom: solid 1px #E7E7E5;
                            padding-bottom: 8px;
                            margin-bottom: 8px;

                            .distance {
                                display: none;
                            }

                            .city {
                                text-transform: capitalize;                                
                            }

                            .country {
                                display: block !important;
                            }

                            .btn_container {
                                margin-top: 8px;

                                .relayLink {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body.cart.step_2 .bloc_livraison .checkbox{
    top: 50%;
    z-index: 2;
}

.choix_type_adresse {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    font-family: $ctaFont, sans-serif;
    font-size: 14px;
    text-transform: lowercase;
    margin-bottom: 1rem;

    .type_adresse {
        position: relative;
        font-size: 14px;
        text-transform: lowercase;
        padding-bottom: 0.25rem;
        letter-spacing: 0.25rem;
        line-height: 1.05rem;
        margin-right: 1.2rem;
        white-space: nowrap;
        cursor: pointer;

        &.actif {
            color: $ctaColor;
        }

        &.ami {
            display: none;
        }

        &:hover:after,
        &.actif:after {
            content: '';
            display: block;
            position: absolute;
            top: 110%;
            left: 0;
            width: 100%;
            height: 1px;
        }

        &:hover:after {
            background-color: $primaryColor;
        }

        &.actif:after {
            background-color: $ctaColor;
        }

        +.type_adresse {
            margin-left: 1rem;
        }
    }

    +.w-form-line {
        display: none !important;
    }
}

/**
 * Block other delivery title display
 */

.w-newuser-form{
    legend span{
        display: block;
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 400;
        font-size: .9375rem;
        line-height: 18px;
    }
    .elem_global{
        &.delivery{
            .w-has-tel{
                .w-input-container:nth-child(1){
                    display: none;
                }
                .w-input-container:nth-child(2){
                    margin-left: 0;
                }
            }
        }
    }
}

a.itm_back_btn,
.soloTel.telInfos {
    display: none;
}

body.cart.step_2_0 {

    .wrapper_mes_adresses{
        .form_submit{
            .button{
                display: none;
            }
        }
    }
    .edit_adresse {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .w-input-element:not([type='radio']):not([type='checkbox']),
        input[type='text'].w-input-element,
        .w-dropdown {
            box-sizing: border-box;
            border-color: $borderGrey;
        }

        &.sameBillAddress {
            margin-bottom: -1rem;
        }

        .w-input-label {
            font-size: 14px;
            color: $secondaryColor;
        }

        .w-radio-input .w-input-element+.w-input-label span,
        .w-checkbox-input .w-input-element+.w-input-label span {
            color: $primaryColor;
        }
    }

    form#adresseForm {
        padding: 0.2rem 1rem 0 0.3rem;
        box-sizing: border-box;
    }

    .w-group-label {
        font-family: $primaryFont;
        font-size: 42px;
        font-style: italic;
        margin-bottom: 0.9rem;
    }

    #adresseForm {
        .w-tel-input.w-has-error #iconValidtelephone {
            display: none;
        }

        .errormsgadd {
            position: relative;
            top: 0.1rem;
        }
    }
}

.wrapper_mes_adresses,
.edit_adresse {
    width: 100%;
}

.edit_adresse {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .w-form-line {
        &.sameBillAddress {
            margin-bottom: -1rem;
        }
        &.addrSociety{
            margin-bottom: 1.2rem!important;
        }
    }

    .w-input-group{
        &.accountCreate{
            display: none;
        }
    }

    .w-input-label {
        font-size: 14px;
        color: $secondaryColor;
    }

    .w-radio-input .w-input-element+.w-input-label span,
    .w-checkbox-input .w-input-element+.w-input-label span {
        color: $primaryColor;
    }

    .w-submit-button {
        padding: 0 3%;
        color: $whiteColor;
        box-shadow: inset 0 0 0 2px $whiteColor;
        background-color: $ctaColor;
        height: 48px;
        &.w-loader{
            height: 48px;
        }
    }

    .notice{
        display: block!important;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $secondaryColor;
    }
}

/**
 * Delivery right side
 */
.total_produit_dropdown {
    padding-top: 35px;
    order: 2;

    @media screen and (min-width: 1200px) {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .cart_product_line {
        margin: 10px 0 15px;
        border-bottom: 0;
        height: auto;

        @media screen and (min-width: 1400px) {
            height: 80px;
        }

        .cart_product_pic {
            width: 96px;
        }

        .cart_product_desc {
            width: calc(100% - 96px);
            padding-left: 3.5%;
        }

        .wrapper_cart_product_desc {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
        }

        .cart_product_subtitle {
            display: none;
        }

        .cart_product_modify {
            display: none;
        }

        .wrap_titre_prix {
            flex-direction: column;
            flex: initial;
            height: initial;

            a.product_mod {
                width: 100%;
                margin-bottom: 0.6rem;
            }
        }

        .cart_product_sizecol.quantity {
            position: unset;
            left: auto;
            width: auto;

            label {
                font-family: $primaryFont;
                margin-bottom: 0;
            }
        }

        .wrapper_cart_product_price {
            justify-content: flex-start;
            align-items: baseline;
            width: 100%;
            text-align: left;
            margin-left: 0;
            margin-bottom: 0.6rem;

            label {
                display: none !important;
            }
        }

        .pricetag {
            width: fit-content;

            +.pricetag {
                position: initial;
                margin: 0 0 0 0.5rem;
            }
        }
    }
}

#cart_total .bill_line.total_produit_dropdown label:not(.w-text-input) {
    font-family: $primaryFont;
    font-size: 15px;
    text-transform: initial;
    letter-spacing: initial;
    color: $secondaryColor;
}

.w-input-container.checkCgv {
    .w-checkbox-input {
        height: initial;
        margin-bottom: 2.5rem;
    }

    .w-checkbox-input .w-input-element+.w-input-label {
        align-items: flex-start;
        font-family: $primaryFont;
        font-size: 15px;
        color: $primaryColor;
        line-height: 1.2;
        padding-left: 1.5rem;

        span {
            margin-left: 0;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            border-color: $black;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
                color: $primaryColor;
            }
        }
    }
}

#err_cgv_compulsory {
    position: absolute;
    margin: -1.8rem 0 0 1.5rem;
    color: $red;
    font-size: 13px;
}

.wrapper_form_adresse {
    .clearfix.choix_type_adresse {
        display: none;
    }

}

.multidelivery_product_container .err_delivery_date {
    margin-top: 12px;
    color: $red;
    font-size: 13px;
}

.multidelivery_product_container .err_delivery_date {
    margin-top: 12px;
    color: $red;
    font-size: 13px;
}

#step1 {
    #msg_unvalid_destination {
        color: $red;
        display: none;
    }

    .destination_error {
        color: $red !important;
    }
}