.accordion,
.accordion ul,
.accordion li,
.accordion a {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    font-weight: normal;
    text-decoration: none;
    line-height: 1;
    font-size: $fs_txt;
    position: relative;
    color: $primaryColor;
    font-family: $primaryFont;
}

.accordion a {
    line-height: 1;
}

.accordion {
    width: 100%;
}

.accordion > ul > li {
    border-bottom: 2px solid $backgroundColor;
    transition-property: background;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.accordion > ul > li > a {
    display: block;
    color: $primaryColor;
    transition-property: background, border;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.accordion > ul > li > a > span,
.accordion > ul > li > a > h2 {
    display: block;
    padding: 20px 0px 20px 0px;
    width: 100%;
    line-height: 0.7;
}

.accordion > ul > li > a:hover {
    text-decoration: none;
}

.accordion > ul > li.active ul {
    display: block;
}

.accordion > ul > li.has-sub > a span,
.accordion > ul > li.has-sub > a h2 {
    position: relative;

    &:after {
        content: '+';
        display: inline-block;
        width: 5px;
        height: 5px;
        position: absolute;
        right: 5px;
    }
}

.accordion > ul > li.has-sub.active > a span,
.accordion > ul > li.has-sub.active > a h2 {
    &:after {
        content: '-';
        display: inline-block;
        width: 5px;
        height: 5px;
        position: absolute;
        right: 3px;
    }
}

/* Sub menu */
.accordion ul ul {
    display: none;
    border: none;
}

.accordion ul ul li {
    padding: 0 0;
    border: none;
    line-height: 18px;

    .carac_container {
        position: relative;
        padding-bottom: 20px;
    }
}

.accordion ul ul a {
    text-align: left;
    display: block;
    color: $primaryColor;
    font-weight: 400;
    font-family: $fs_txt;
    line-height: 25px;
    padding: 25px;
    border: none;
    transition-property: color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

// MEDIA QUERIES
@media all and (max-width: 1024px) {
    .accordion {
        width: 100%;
    }

    .accordion ul ul a {
        display: block;
        margin: 0;
        width: inherit;
        padding-left: 40px;
    }
}