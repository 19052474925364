.w-form-line {
    margin-bottom: 1.2rem!important;

    &.last-is-hidden, &:last-of-type {
        margin-bottom: 1.2rem;
         + .w-form-line{
            display: none;
         }
    }
}

.w-input-label{
    color: $secondaryColor;
}
.w-nomempty .w-input-label{
    color: $grey;
}
.w-contact-form .file-input-row label:not(.w-loader):focus,
.w-contact-form .file-input-row label:not(.w-loader):hover,
.w-submit-button:not(.w-loader):focus,
.w-submit-button:not(.w-loader):hover,
button.w-submit-button:not(.w-loader):focus,
button.w-submit-button:not(.w-loader):hover {
    background-color: $ctaColor;
    color: white;
}

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,  // TODO:
input[type=password].w-input-element,   // Get rid
input[type=text].w-input-element,   // of these
textarea.w-input-element {  // override rules
    height: 48px;
    box-sizing: border-box;
    border-radius: 0;
    border: 1px solid $borderGrey;

    &:focus {
        outline: none;
        box-shadow: 0 0 1px $ctaColor!important;
        border-color: $ctaColor!important;
    }

    &::placeholder {
        color: $grey;
    }
}

.w-input-note{
    font-size: .7rem;
    line-height: 1;
}

.w-dropdown {
    height: 48px;
    border-radius: 0;
    border: 1px solid $borderGrey;

    &.w-has-error{
        .w-input-element{
            border-color: inherit;
        }
    }

    &.w-focused {
        outline: none;
        box-shadow: 0 0 1px $ctaColor!important;
        border-color: $ctaColor!important;
    }

    &::placeholder {
        color: $grey;
    }
    
}

.w-submit-button,
button.w-submit-button {
    height: 48px;
    background-color: white;
    padding: 0;
    border: 1px solid $ctaColor;
    font-family: $ctaFont;
    font-size: $fs_mid;
    color: $ctaColor;
    letter-spacing: 0.2em;
    text-transform: lowercase;
    transition: border, opacity, background-color ease-in-out 250ms;
    box-shadow: inset 0 0 0 2px white;
    outline: 0;

    &:not(.w-loader):hover,
    &:not(.w-loader):focus {
        background-color: $ctaColor;
        color: white;
    }

    &.w-loader {
        background: $ctaColor url(../svg/loader_white.svg) no-repeat center;
        background-size: auto 80%;
    }
}

/**
 * Footer newsletter
 */
.w-newsletter-form .w-form-line.w-submit .w-submit-button {
    width: fit-content;
    height: 46px;
    border: 0;

    &:hover,
    &:focus {
        background-color: white;
        color: $ctaColor;
    }
}

/**
 * Basket promo code
 */
.w-coupon-form {
    .w-form-line {
        .w-input-element:not([type=radio]):not([type=checkbox]),
        input[type=text].w-input-element {
            border-radius: 0;
        }

        &.w-submit {
            width: 20%;
            min-width: 46px;
            height: 46px;
            top: 1px;
            right: 1px;
            transform: translateY(0);

            .w-submit-button {
                width: 100%;
                height: 100%;
                background-color: $ctaColor;
                font-family: $ctaFont;
                font-size: 15px;
                text-transform: lowercase;
                padding: 0;
                margin: 0;
                color: $whiteColor;
                box-shadow: inset 0 0 0 2px $whiteColor;

                &:hover {
                    background-color: $whiteColor;
                    color: $ctaColor;
                }
            }

            .w-loader.loading {
                background-size: 50% !important;
                background: $ctaColor url(../svg/loader_white.svg) no-repeat center;
            }
        }

        #input_codePromo {
            width: 75%;
        }
    }
}

/**
 * Switch on/off facturation
 */
.w-form-line.addrType {
    .w-checkbox-input .w-input-element {
        +.w-input-label {
            &:before {
                width: 30px;
                height: 15px;
                border-radius: 15px;
                background-color: #ccc;
                box-shadow: none;
                transition: background-color .2s ease-in-out;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 15px;
                height: 15px;
                background-color: white;
                border: 1px solid #ccc;
                border-radius: 50%;
                transition: left .2s ease-in-out;
            }
        }

        &:checked {
            +.w-input-label {
                &:before {
                    background-color: $ctaColor;
                }

                &:after {
                    left: 15px;
                }
            }
        }
    }
}

/**
 *
 */
.w-form-line.w-submit {
    position: relative;
}

.optin_container .w-input-note.w-input-error {
    display: none !important;
}