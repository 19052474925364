/**
 * Buttons
 */

$lineHeight: 1.05rem;

.loader_scroll,
.form_submit,
.btn_container {
    position: relative;

    &.loading {
        .loader {
            z-index: 1;
            opacity: 1;
        }
    }
}

.button,
.w-submit-button,
button.w-submit-button,
.tg-module .btn-product,
.button--primary {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    text-align: center;
    background-color: $white;
    font-family: $ctaFont;
    font-size: $fs_mid;
    color: $ctaColor;
    letter-spacing: 0.2em;
    text-transform: lowercase;
    text-decoration: none;
    line-height: .9;
    transition: border, opacity, background-color ease-in-out 250ms;
    border: 1px solid $ctaColor;
    box-shadow: inset 0 0 0 2px $white;
    cursor: pointer;
    outline: 0;

    &:hover {
        background-color: $ctaColor;
        color: $white;
    }

    +.loader {
        background: #fff url(../svg/loader.svg) no-repeat center !important; 
        background-size: auto 80% !important;
        box-shadow: inset 0 0 0 2px $white;
        border: 1px solid $ctaColor;
    }
}

.button--secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    text-align: center;
    background-color: $ctaColor;
    font-family: $ctaFont;
    font-size: $fs_mid;
    color: $white;
    letter-spacing: 0.2em;
    text-transform: lowercase;
    text-decoration: none;
    line-height: .9;
    transition: border, opacity, background-color ease-in-out 250ms;
    border: 1px solid $ctaColor;
    box-shadow: inset 0 0 0 2px $white;
    cursor: pointer;
    outline: 0;

    &:hover {
        background-color: $white;
        color: $ctaColor;
    }

    +.loader {
        background: $white url(../svg/loader_white.svg) no-repeat center;
        background-size: auto 80%;
        box-shadow: inset 0 0 0 2px $white;
        border: 1px solid $ctaColor;
    }
}

/**
 * General loader styles
 */
.loader,
.w-submit-button.w-loader,
button.w-submit-button.w-loader {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    padding: 0;
}

.w-form .w-submit .w-loader.loading {
    z-index: 1;
    background-image: url(../svg/loader_white.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50px;
}

footer#copyright .bot_news .w-btn-wrapper .w-loader {
    border: none !important;
    background-color: #fff !important;
    background: url(../svg/loader.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25%;
    width: 100%;
}

footer.footer .w-newsletter-form .w-btn-wrapper .w-submit-button span {
color: #ce7a51;
font-family: sackersgothicstdheavy, sans-serif;
font-style: normal;
font-weight: 850;
font-size: 14px;
line-height: 17px;
letter-spacing: .2em;
text-transform: lowercase;
}