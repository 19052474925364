.footer_sitemap {
    .page_satellite {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 1282px;
        margin: 0 auto 70px;

        .title_sitemap {
            font-family: $primaryFont;
            font-style: italic;
            font-weight: 400;
            font-size: 60px;
            line-height: 72px;
            color: $primaryColor;
            margin: 0 0 25px;
        }

        .siteMap {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;

            .sitemap_section,
            .sitemap_selection {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-right: 80px;

                h2 {
                    font-family: $ctaFont;
                    font-style: normal;
                    font-weight: 850;
                    font-size: 14px;
                    line-height: 27px;
                    letter-spacing: 0.2em;
                    color: $primaryColor;
                    text-transform: lowercase;
                }

                .site_map_ul {

                    .plan_titre_objet,
                    .ss_cat_name {
                        display: block;
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 25px;
                        color: $primaryColor;

                        &:first-letter {
                            text-transform: uppercase;
                        }

                        span:hover {
                            color: $ctaColor;
                        }
                    }
                }
            }
        }
    }
}