.contact_faq {
    #breadcrumbs {
        max-width: 1680px;
        margin: 0 auto;
    }

    #contact_form {
        .w-contact-form .w-form-line .w-input-note {
            margin: .33333em 0 .66667em;
        }
    }
}

.wrapperContentFaqForm {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1680px;
    margin: 0 auto;

    * {
        box-sizing: border-box;
    }

    .wrap_content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        #block_img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            max-width: 840px;
            margin-right: 5.4%;

            img {
                width: 100%;
            }
        }
    }

    #contact_form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 39.9%;
        max-width: 670px;
        margin-top: 30px;

        .titre_contact {
            font-family: $primaryFont;
            font-style: italic;
            font-weight: 400;
            font-size: 60px;
            line-height: 72px;
            color: $primaryColor;
            margin-bottom: 3px;
        }

        .wrapperErrors {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: $primaryColor;
            width: 100%;

            p {
                margin-bottom: 20px;
            }

            .red {
                color: $red;
            }

            .valid {
                color: $green;
            }
        }

        form .w-form-line:nth-of-type(2) .w-input-label span::after {
            content: '*';
        }
    }
}

.w-contact-form {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;

    .w-form-line {
        width: 100%;
        margin-bottom: 20px;

        &:nth-of-type(1),
        &:nth-of-type(2) {
            &.half_width {
                width: calc((100% - 16px) / 2);
            }
        }

        .w-input-label {
            color: $grey;
        }

        .w-input-element,
        .w-dropdown {
            border-radius: 0;
            border-color: $lighterGrey;
        }

        .w-textarea {
            .w-input-element {
                height: 156px;
            }
        }

        .w-input-note {
            margin-top: 0;
        }

        &.w-submit {
            margin: 0;
            max-width: 171px;
        }
    }

    .file-input-row {
        margin-top: 0;
        width: 100%;

        .w-form-line {
            .ajout_pj_label {
                font-family: $ctaFont;
                font-style: normal;
                font-weight: 850;
                font-size: 11px;
                line-height: 13px;
                text-align: center;
                letter-spacing: 0.2em;
                color: $primaryColor;
                text-transform: none;
                text-decoration: underline;
                text-underline-offset: 8px;
                width: auto;
                height: auto !important;
                border: 0 none !important;
            }
        }
    }

    .file-list {
        width: 100%;

        .rowList {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    .w-lines-group {
        display: none;

        .w-fieldset-label,
        .w-form-line {
            font-size: 12px;
            color: $primaryColor;
        }

        .w-fieldset-label {
            margin-bottom: 1rem;
        }

        .w-form-line {
            margin-bottom: 0.5rem;
        }

        .w-line-label,
        .w-radio-input .w-input-element + .w-input-label {
            font-size: 12px;
            color: $primaryColor;
        }
    }

    #recaptcha {
        width: 100%;
        margin-bottom: 18px;
        transform: scale(0.8);
        transform-origin: left;
    }
}