/******************************************************************************/
/* MEDIA QUERIES  */
/******************************************************************************/

@mixin largeScreen {
    @media screen and (max-width: $wrapperXLarge) {
        @content;
    }
}

@mixin averageScreen {
    @media screen and (max-width: $wrapperLarge) {
        @content;
    }
}

@mixin mediumScreen {
    @media screen and (max-width: $wrapperMedium) {
        @content;
    }
}

@mixin midMediumScreen {
    @media screen and (max-width: $wrappermidMedium) {
        @content;
    }
}

@mixin midSmallScreen {
    @media screen and (max-width: $wrappermidSmall) {
        @content;
    }
}

@mixin smallScreen {
    @media screen and (max-width: $wrapperSmall) {
        @content;
    }
}

@mixin smallXScreen {
    @media screen and (max-width: $wrapperXSmall) {
        @content;
    }
}
