@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

body {
    font-family: $primaryFont;
    color: $primaryColor;
    font-size: $fs_def;
    box-sizing: border-box;
    font-weight: 400;
    color: $primaryColor;
    padding-top: $headerHeightMedium;
    overflow-x: hidden;

    &.bandeauDelivery {
        padding-top: $headerHeight;
    }

    @media screen and (max-width: 1200px) {
        padding-top: $headerHeightSmall;
        
        &.bandeauDelivery {
            padding-top: 117px;
        }
    }

    #breadcrumbs {
        width: 100%;
        margin: 0 auto;
    }

    h1,
    h2,
    h3 {
        font-weight: 400;
    }

    .text-indent{
        text-indent: -10000px;
        height: 0;
    }

    .ill_img {
        background-size: 0 0 !important;
        width: 0;
        height: 0;
    }

    .container {
        width: 96%;
        max-width: $wrapperRayon;
        margin: 0 auto;
    }

    &.homepage{
        .container:nth-child(1) {
            max-width:100%;
            width: 100%;
            padding: 0;
        }
    }

    .page_wrapper {
        max-width: $wrapperXLarge;
        width: 100%;
        margin: 0 auto;
        position: relative;
    }

    #site_global_wrap {
        padding-bottom: 2rem; 
        
        &:has(.splioSdk) {
            min-height: 100dvh;
            position: relative;

            &:not(:has(.splio-display)) {
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: $white !important;
                    background: url(../../svg/loader.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 5%;
                }
            }

            .splio-loyalty-summary {
                padding-top: 1rem !important;
                padding-bottom: 3rem !important;
            }
        }
    }

    #site_global_wrap,
    #site_head_wrap {
        width: 100%;
    }

    .cache {
        display: none;
    }

    /* GLOBAL SHADES */
    .shad {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        background-color: rgba($primaryColor, 0.3);
        overflow-y: auto;

        &.actif {
            display: block;
        }

        &#shad_abox {
            z-index: 99;
        }

        &.white {
            background-color: rgba(255, 255, 255, 0.9);
        }
        &.dispatch_shad {
            z-index: 101;
        }
    }

    #shad_menu,
    #shad_link,
    #shad_search,
    #shad_filter {
        display: block !important;
        background-color: rgba($primaryColor, 0.3);
        opacity: 0;
        z-index: -10;
        margin-top: 40px;
        transition: opacity 0.3s linear, z-index 0s linear 0.3s,
            margin 0s linear 0.3s;

        &.sticky {
            margin-top: 0;
        }

        &.actif {
            opacity: 1;
            z-index: 8;
            transition: opacity 0.3s linear 0.2s, z-index 0s linear;
        }

        &.filter_on {
            z-index: 5;
        }
    }

    #shad_search {
        &.actif {
            z-index: 5;
        }

        &.filter_on {
            z-index: 5;
        }
    }

    /*  VUE.JS TRANSITIONS */
    .pagefade-enter-active,
    .pagefade-leave-active {
        transition: opacity 0.5s linear;
    }

    .pagefade-enter,
    .pagefade-leave-to {
        opacity: 0;
    }

    [v-cloak] {
        display: none;
    }

    /* IE "X" FROM SEARCH INPUT FIELD */
    input::-ms-clear,
    select::-ms-expand,
    input::-ms-reveal {
        display: none;
    }

    .swiper-lazy-preloader {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: none !important;
        background-color: $whiteColor;
        width: 100%;
        height: 100%;
        min-height: 339px;
        z-index: 10;
        animation: none;
        position: absolute;
        left: 0;
        top: 0;

        &:after {
            position: absolute;
            width: 42px;
            height: 42px;
            margin-left: -21px;
            margin-top: -21px;
            transform-origin: 50%;
        }
    }

    .dynasearchwrapper {
        .wrap_rolloverproduit {
            bottom: 18px;
        }

        .wishlistBtn{
            display: none !important;
        }
    }

    .no_cents {
        display: none;
    }
}

.sticky + #site_global_wrap {
    margin: 0;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1080px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1) {
        padding-top: 62px;
    }
}

.shad.actif#shad_menu {
    display: block !important;
}

input[type="number"] {
    -moz-appearance: textfield;
}