.page_rayon,
#search_page .search_recap {
    width: 100%;
    max-width: $wrapperRayon;
    margin: 0 auto;
}

body {
    &.category, .category.search_in{

        .footer {
            padding-top: 3.75rem;
        }

        .page_rayon {
            position: relative;
            padding: 4.375rem 0;

            /** skin checkbox **/
            .holder_filters {
                .filters_container.bottom {
                    margin-top: 1.3rem;
                    padding-right: 10px;
                }
            }

            .customChecker.product_check,
            .form_itm {
                input[type='checkbox'] {
                    display: inline-block;
                    vertical-align: middle;
                    appearance: none;
                    background-color: $whiteColor;
                    font: inherit;
                    color: $ctaColor;
                    width: 15px;
                    height: 15px;
                    border: 1px solid $backgroundColor;
                    position: relative;

                    &:checked,
                    &:hover {
                        cursor: pointer;
                        border: 1px solid $ctaColor;

                        &::before {
                            content: '';
                            background: $ctaColor;
                            position: absolute;
                            height: 9px;
                            width: 9px;
                            z-index: 2;
                            opacity: 1;
                            left: 2px;
                            top: 2px;
                        }
                    }
                }

                &.is_checked {
                    input[type='checkbox'] {
                        cursor: pointer;
                        border: 1px solid $ctaColor;

                        &::before {
                            content: '';
                            background: $ctaColor;
                            position: absolute;
                            height: 9px;
                            width: 9px;
                            z-index: 2;
                            opacity: 1;
                            left: 2px;
                            top: 2px;
                        }
                    }

                    label {
                        color: $ctaColor;
                    }
                }
            }

            #content_obj {
                padding-left: 334px;
                overflow: hidden;
                min-height: 80vh;
                @media screen and (max-width: 1680px){
                    padding-left: 301px;
                }
                @media screen and (max-width: 1280px){
                    padding-left: 280px;
                }
            }

            /* FILTERS SECTION */
            #rayon_sticky_block {
                position: absolute;
                top: 26px;
                left: 0;
                height: 100%;
                width: 244px;
            }

            /* Sticky position */
            .holder_filters {
                max-width: 250px;
                width: 100%;
                position: sticky;
                top: 86px;
                left: 0;

                .wrapper_scrollable span.title,
                #filter_trier {
                    display: none;
                }

                /* Global filters wrapper in order to init overlayScrollbars */
                .wrapper_scrollable {
                    max-height: 86vh;

                    & > .os-scrollbar-vertical {
                        padding: 0;
                        bottom: 10px;
                        width: 1px;
                        top: 0px;
                        right: 0px;
                    }

                    & > .os-scrollbar-horizontal {
                        display: none;
                    }
                }

                .wrapper_filtre.sscateg .categ_name_filter {
                    display: inline-block;
                    font-family: $ctaFont;
                    font-size: $fs_mid;
                    text-transform: lowercase;
                    line-height: 1.0625rem;
                    /* identical to box height */
                    letter-spacing: 0.2em;
                    color: $primaryColor;
                    margin-bottom: 0.5rem;
                }

                .sscateg_name_filter {
                    display: inline-block;
                    font-family: $primaryFont;
                    font-size: $fs_mid;
                    line-height: 24px;
                    /* identical to box height */
                    color: $primaryColor;
                    width: 100%;

                    /* Here sscateg_name_filter gets trigger_dropdown style so modify ::after style due to space-between justify-content */
                    &::after {
                        flex-grow: 1;
                        text-align: right;
                    }

                    &.current {

                        a,
                        span {
                            text-decoration: underline;
                            color: $ctaColor;
                        }
                    }
                }

                .holder_objects,
                .holder_objects_availability {
                    display: none;
                    padding-bottom: 11px;

                    &.actif {
                        display: block;
                    }

                    .menuitem {
                        font-family: $primaryFont;
                        font-size: $fs_mid;
                        line-height: 1.5625rem;

                        .menuitem_name {

                            &.current,
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .holder_subcat {
                    border-bottom: 2px solid $backgroundColor;
                    padding: 15px 0;

                    &.actif {
                        border-bottom: 1px solid $ctaColor;
                    }
                }

                /* container */
                .filters_container {
                    &.top {
                        padding-bottom: 2.4rem;

                        .form_submit {
                            margin-top: 0.625rem;

                            .button--secondary,
                            .secondary {
                                max-width: 228px;
                                padding: 16px 20px;
                                height: auto;
                            }
                        }
                    }

                    &.bottom {
                        margin-top: 1.4rem;
                    }
                }

                /* filters style */
                .wrapper_filtre {
                    border-bottom: 2px solid $backgroundColor;
                    position: relative;

                    &.sscateg {
                        border-bottom: 1px solid $ctaColor;
                    }

                    .filter_name {
                        font-family: $ctaFont;
                        font-size: 0.875rem;
                        text-transform: lowercase;
                        line-height: 19px;
                        letter-spacing: 0.2em;
                        color: $primaryColor;
                        padding: 10px 0;
                    }
                }

                .selected_options {
                    &.scrollable {
                        max-height: 90px;
                        overflow: hidden;
                    }

                    &.priceOption {
                        .tri_price {
                            display: block;
                            font-size: $fs_min;

                            &.actif {
                                text-decoration: underline;
                            }
                        }
                    }

                    .form_itm {
                        display: block;
                        font-size: $fs_mid;
                        margin-top: -6px;
                        margin-bottom: 6px;

                        input[type='checkbox'] {
                            left: -2px;
                        }

                        label {
                            vertical-align: middle;
                            display: inline-block;
                            font-family: $primaryFont;
                            font-size: $fs_mid;
                            line-height: 25px;
                            /* identical to box height */
                            color: $primaryColor;
                            cursor: pointer;

                            &:hover,
                            &.is_checked {
                                color: $ctaColor;
                            }
                        }

                        &.is_checked {
                            input[type='checkbox'] {
                                border: 1px solid $ctaColor;

                                &::before {
                                    content: '';
                                    background: $ctaColor;
                                    position: absolute;
                                    height: 9px;
                                    width: 9px;
                                    z-index: 2;
                                    opacity: 1;
                                    left: 2px;
                                    top: 2px;
                                }
                            }

                            label {
                                color: $ctaColor;
                            }
                        }
                    }

                    &:not(.actif) {
                        display: none;
                    }
                }

                #filter_price {
                    .selected_options {
                        margin: 0.1rem 6px 2.7rem 0;

                        &.actif {

                            .fix_handler_prices{
                                bottom: 7px;
                                position: absolute;
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                            }

                            .handler_price {
                                font-size: 15px;
                                line-height: 18px;
                                color: $ctaColor;
                                top: -7px;
                                left: 0px;
                                position: relative;

                            }
                        }
                    }

                    .ui-slider {
                        position: relative;
                        text-align: left;
                        z-index: 0;

                        .ui-slider-handle {
                            z-index: 2;
                            position: absolute;
                            width: 14px;
                            height: 14px;
                            margin-top: 0.5rem;
                            outline: none;
                            cursor: pointer;
                            top: 2px;

                            &::before {
                                content: '';
                                display: block;
                                transform: translateY(-12px);
                                width: 6px;
                                height: 6px;
                                background-color: $ctaColor;
                                border-radius: 50%;
                            }
                        }

                        .ui-slider-range {
                            z-index: 1;
                            position: absolute;
                            display: block;
                            border: 0;
                        }
                    }

                    .ui-slider-horizontal {
                        height: 2px;
                        border-radius: 5%;
                        margin-top: 6px;
                        background-color: $backgroundColor;
                        width: 98%;

                        .ui-slider-range {
                            top: 0px;
                            height: 2px;
                            margin: 0;
                        }
                    }
                }

                #filter_caract_in_stock {
                    .wrapper_filtre {
                        .filter_name {
                            padding: 10px 0;
                        }

                        .customChecker {
                            input[type='checkbox'] {
                                left: -2px;
                            }

                            label {
                                vertical-align: middle;
                                display: inline-block;
                                font-family: $primaryFont;
                                font-size: $fs_mid;
                                line-height: 25px;
                                /* identical to box height */
                                color: $primaryColor;
                                cursor: pointer;

                                &:hover,
                                &.is_checked {
                                    color: $ctaColor;
                                }
                            }

                            &.is_checked {
                                label {
                                    color: $ctaColor;
                                }
                            }
                        }
                    }
                }

                .filter_attributes {
                    &:nth-last-child(2) {
                        .wrapper_filtre {
                            border: 0;
                        }
                    }

                    .customChecker {
                        input[type='checkbox'] {
                            left: -2px;
                        }

                        label {
                            vertical-align: middle;
                            display: inline-block;
                            font-family: $primaryFont;
                            font-size: $fs_mid;
                            line-height: 25px;
                            /* identical to box height */
                            color: $primaryColor;
                            cursor: pointer;

                            &:hover {
                                color: $ctaColor;
                            }
                        }
                    }
                }

                .filter_checkbox_hide {
                    position: absolute;
                    left: -9999px;
                }

                .trigger_dropdown {
                    cursor: pointer;

                    &::after {
                        content: '+';
                        display: inline-block;
                        width: 5px;
                        height: 5px;
                        position: absolute;
                        right: 12px;
                    }

                    &.actif {
                        &::after {
                            content: '-';
                        }
                    }
                }

                .form_submit {
                    &.margBot {
                        margin-bottom: 1rem;
                    }

                    .button {
                        width: 100%;
                    }
                }
            }

            #trigger_filtre,
            .close_pop {
                display: none;
            }

            #scroll_items {
                .wrapper_bandeau.rayon {
                    @media screen and (max-width: 1440px) {
                        width: 32%;
                    }
                }

                .iframe_container {
                    width: 100%;
                    padding-bottom: 83%;
                    transform: scale(1);

                    @media screen and (max-width: 1280px) {
                        padding-bottom: 72%;
                    }

                    iframe {
                        display: block;
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                    }

                    .link_product_iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 5;
                    }
                }
            }
        }

        /* RAYON */
        &.search_in {
            .page_rayon {
                .holder_filters {
                    .filters_container.bottom {
                        margin-top: 0;
                        padding-top: 21px;
                    }
                }
            }

            .page_rayon .holder_filters .sscateg_name_filter {
                line-height: 22px;
            }
        }

        .breadcrumbs {
            margin-bottom: 0.438rem;
        }

        /* BANDEAU */
        .wrapper_bandeau {
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
            background-color: #faefe9;
            display: block;
            .wrapper_img {
                display: flex;
                align-items: center;
                background-color: $lightBorder;
                width: 90%;
                max-width: $wrapperBandeauRayon;
                justify-content: space-between;
                margin: 0 auto;
                flex-direction: row;
                min-height: 364px;

                .infos {
                    margin-top: -1.5rem;
                    max-width: 52%;
                    padding-right: 0em;

                    .title_h1 {
                        font-family: $primaryFont;
                        font-style: italic;
                        font-size: $fs_xlarge;
                        line-height: 1.4;
                        text-align: right;
                    }

                    .txt_content {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-size: $fs_txt;
                        line-height: 1.125rem;
                        font-weight: 400;
                        text-align: right;
                    }

                    .taunt_click {
                        padding: 25px 10px;
                    }
                }

                .visuel {
                    img {
                        width: 100%;
                        height: auto;
                        vertical-align: top;
                    }
                }
            }
        }

        .text_seo {
            max-width: 800px;
            margin: 0 auto;
            text-align: center;
            font-family: $secondaryFont;
            font-style: italic;
            font-size: $fs_txt;
            line-height: 1.5;

            &.bottom {
                padding: 6rem 0;
            }

            @include mediumScreen {
                padding: 0 $min_pad;
            }
        }

        .seo {
            padding-left: 334px;
            overflow: hidden;

            .txt_seo {
                display: block;
                margin: 0 auto;
                width: 100%;
                max-width: 965px;

                .title {
                    font-family: $primaryFont;
                    font-style: italic;
                    font-weight: 400;
                    font-size: $fs_large;
                    line-height: 50px;
                    margin-bottom: 7px;
                }

                .txt {
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: $fs_txt;
                    line-height: 18px;

                    ul {
                        list-style: unset;
                        padding-left: 3rem;
                    }
                }
            }
        }

        .cms_page_content+.seo {
            padding-left: 0;
        }
    }

    #filter_caract_allergene,
    #filter_caract_gout,
    #filter_caract_origine {
        .wrapper_filtre {
            border-bottom: 0;
        }
    }

    #filter_caract_gout:not(:last-child),
    #filter_caract_allergene:not(:last-child),
    #filter_caract_origine:not(:last-child) {
        .wrapper_filtre {
            border-bottom: 2px solid $backgroundColor;
        }
    }

    /***** JQUERY UI SLIDER - Used for filter price *****/
    .vue-slider {
        position: relative;
        left: 2%;
        padding: 0 !important;
        height: 2px !important;
        border-radius: 5%;
        margin-top: 6px;
        background-color: $backgroundColor;
        width: 94% !important;
        margin-left: 2%;
        margin-right: 2%;

        .vue-slider-dot {
            z-index: 2;
            position: absolute;
            width: 14px;
            height: 14px;
            margin-top: 0.5rem;
            outline: 0;
            cursor: pointer;
            top: 2px;

            .vue-slider-dot-tooltip-bottom {
                bottom: -4px !important;
            }

            &::before {
                content: '';
                display: block;
                transform: translateY(-5.8px);
                width: 6px;
                height: 6px;
                background-color: $ctaColor;
                border-radius: 50%;
            }

            .vue-slider-dot-tooltip-bottom {
                bottom: -4px;
            }

            .vue-slider-dot-tooltip-inner {
                .vue-slider-dot-tooltip-text {
                    display: inline-block;
                    font-size: $fs_txt;
                    line-height: 1.125rem;
                    color: $ctaColor;
                    top: -7px;
                    left: 2px;
                    position: relative;
                    font-family: $primaryFont;
                }
            }
        }

        .vue-slider-rail {
            .vue-slider-dot:last-of-type {
                .vue-slider-dot-tooltip-text {
                    left: -10px !important;
                }
            }
        }

        .vue-slider-process {
            z-index: 1;
            position: absolute;
            display: block;
            border: 0;
            top: 0;
            height: 1px;
            margin: 0;
            background-color: $ctaColor;
        }
    }

    .ui-widget-header {
        background-color: $ctaColor;
    }

    /* PRODUCT SECTION */
    #scroll_items {
        .wrapperProdVertical {
            width: 23.5%;
            margin-bottom: 1.625rem;

            @media screen and (max-width: 1440px) {
                width: 32%;
            }

            @media screen and (max-width: 1023px) {
                width: 49%;
            }

            &.push {
                &:first-of-type {
                    width: 50%;
                }

                &:nth-of-type(2) {
                    width: 100%;
                }

                img {
                    max-width: 100%;
                }

                .taunt_click {
                    display: none;
                }
            }
        }
    }

    #list_item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 2%;

        .wrapper_bandeau {
            position: relative;
            width: 23.5%;
            margin-bottom: 1.625rem;
            margin-left: inherit;
            margin-right: inherit;

            @media screen and(max-width: 1440px) {
                width: 32%;
            }

            @media screen and(max-width: 1280px) {
                width: 49%;
            }

            .wrapper_img {
                justify-content: center;
                min-height: inherit;
                width: 100%;

                .infos {
                    position: absolute;
                    width: calc(100% - 74px);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -60%);
                    max-width: 100%;
                    margin-top: inherit;
                    margin-left: inherit;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .taunt_click {
                        padding: 0;
                        color: $whiteColor;
                        font-family: $primaryFont;
                        font-style: italic;
                        font-weight: 400;
                        font-size: $fs_subtitle;
                        line-height: 3.125rem;
                        text-align: center;
                        text-transform: none;

                        &::after {
                            content: attr(data-cta);
                            display: block;
                            color: $whiteColor;
                            text-align: center;
                            font-style: normal;
                            font-family: $ctaFont;
                            text-transform: lowercase;
                            font-size: $fs_mid;
                            line-height: 1rem;
                            margin: 21px 0 0 0;
                            padding-bottom: 6px;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            width: 110px;
                            height: 1px;
                            background-color: $whiteColor;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }

                .visuel {
                    position: relative;
                    width: 100%;

                    img {
                        width: 100%;
                    }

                    .pushBasketText {
                        position: absolute;
                        width: 100%;
                        padding: 22px 20%;
                        top: 50%;
                        transform: translateY(-50%);
                        font-family: $primaryFont;
                        font-style: italic;
                        font-weight: 400;
                        font-size: 36px;
                        line-height: 43px;
                        text-align: center;
                        top: 0;
                        color: $whiteColor;
                        font-weight: normal;
                        text-transform: capitalize;

                        span {
                            font-family: $ctaFont;
                            font-style: normal;
                            font-weight: 850;
                            font-size: 14px;
                            line-height: 17px;
                            text-align: center;
                            letter-spacing: 0.2em;
                            color: $whiteColor;
                            text-transform: lowercase;
                            text-decoration: underline;
                        }
                    }
                }
            }

            &.rayon {
                .wrapper_img .infos {
                    z-index: 2;
                }
            }
        }
    }
}

.load_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .nbProdDisplayed {
        display: none;
    }

    .loader_scroll {
        position: relative;

        .button {
            padding: 0 3em;
        }
    }
}

/* PAGINATION */
.bot_pager {
    display: none;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;

    > .pagination {
        padding-right: $min_pad;

        > * {
            font-size: $fs_min;
        }

        > *:not(.prev):not(.next):not(:first-child):not(.hellip) {
            display: inline-block;

            &::before {
                content: '·';
                display: inline-block;
                text-align: center;
                font-weight: 600;
                padding: 0 0.625rem;
            }
        }

        /* PREVENT FIRST PAGINATION ITEM FROM GETTING A BULLET */
        > .prev + a {
            &::before {
                content: none !important;
            }
        }

        .active {
            font-weight: 700;
            font-size: $fs_def;
        }

        .hellip {
            padding-left: 0.625rem;
        }
    }

    .see_all_product {
        font-family: $secondaryFont;
        font-style: italic;
        font-size: $fs_txt;
        text-decoration: underline;
    }

    .prev,
    .next {
        display: none;
    }
}

/* SEARCH LOADER */
.dynasearchwrapper .fullpage-loader,
#search_results .search_loader {
    display: flex;
    justify-content: center;

    span {
        font-size: 0;
    }

    &::after {
        content: '';
        display: block;
        background: transparent url(../svg/loader.svg) center no-repeat !important;
        background-size: 85% 85% !important;
        border: 0;
        background-repeat: no-repeat;
        background-color: transparent;
        width: 100%;
        height: 3.4rem;
    }
}

/** SEARCH PAGE **/
#search_page {
    padding-top: 26px;

    .fullpage-loader {
        text-align: center;

        &::after {
            background: transparent url(../svg/loader.svg) center no-repeat !important;
            background-size: 85% 85% !important;
            border: 0;
            content: '';
            height: 4rem;
        }
    }

    .recap {
        position: relative;
        display: flex;
        width: 100%;
        margin: 0 auto;
        max-width: $wrapperXSmall;
        outline: none;
        width: 100%;
        background-color: transparent;
        padding-bottom: 16px;
        border: 0;
        border-bottom: 1px solid $ctaColor;
        margin-top: 25px;
        justify-content: flex-start;

        &::after {
            position: absolute;
            top: 56%;
            transform: translateY(-50%);
            right: 0;
            content: url(../svg/icon_search_small.svg);
            width: 16px;
            height: 16px;
            height: 26px;
            display: block;
        }

        .search_wording {
            display: none;
        }

        .search_term {
            font-family: $primaryFont;
            font-style: italic;
            font-size: 42px;
            width: auto;

            &:first-letter {
                text-transform: uppercase;
            }
        }

        .nbr_result {
            color: $ctaColor;
            font-family: $primaryFont;
            font-size: $fs_subtitle;
            font-style: initial;
            text-transform: lowercase;
            padding-left: 10px;
            padding-top: 14px;
        }

        .search_loader {
            span {
                font-size: 0;
            }
        }
    }

    #filter_subcategories {

        .form_itm.subcategory,
        .sscateg_name_filter {
            &:hover {
                color: $ctaColor;
            }

            &.actif {
                text-decoration: underline;
            }
        }

        .checkbox {
            font-size: 0;
            position: absolute;
            left: -99999px;
        }

        .subcat_check {
            position: relative;
            cursor: pointer;

            &:hover,
            &.actif {
                .subcat_check::after {
                    width: 100%;
                    left: 0;
                    right: auto;
                }
            }
        }
    }

    /* NO RESULT */
    .search_details_wrapper {
        .no_result_block {
            max-width: $wrapperXSmall;
            margin: 0 auto;

            @include smallScreen {
                padding: 0 $min_pad;
            }

            .title_no_result {
                font-family: $primaryFont;
                font-style: italic;
                font-size: $fs_large;
                line-height: 50px;
                color: $primaryColor;
                padding-top: 4.788rem;
            }

            .tipsNoResults {
                .tips_title {
                    font-family: $primaryFont;
                    font-size: $fs_txt;
                    line-height: 18px;
                    color: $primaryColor;
                    margin-bottom: 0.025rem;
                }

                .search_tips {
                    padding-left: 8px;

                    li {
                        &.tip {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            line-height: 18px;
                            font-size: $fs_txt;

                            &::before {
                                content: '●';
                                font-family: sans-serif;
                                font-size: 13px;
                                margin: 0 0.4rem 0 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .catPush {
        margin-top: 4.375rem;
        margin-bottom: 0.675rem;

        h2 {
            display: none;

            &.home-module-title {
                display: block;
            }
        }

        .module-tree {
            .arrow {
                display: none;
            }

            .item_subtitle {
                display: none;
            }

            .home_gondole_top {
                .home-module-title {
                    text-align: center;
                    font-family: $primaryFont;
                    font-size: 60px;
                    line-height: 72px;
                    text-transform: lowercase;
                    font-style: italic;
                    margin: 90px -2px 25px 0;

                    &:first-letter {
                        text-transform: capitalize;
                    }
                }
            }

            .swiper-container {
                display: flex;
                max-width: 1022px;
                width: 100%;
                padding-bottom: 0px;

                .swiper-wrapper {
                    justify-content: center;
                    column-gap: 40px;
                }

                .swiper-slide {
                    width: 33%;
                    text-align: center;
                    min-height: 378px;
                    position: relative;
                    margin-bottom: 1.625rem;
                    height: 100%;

                    .hover_alt {
                        top: 0px;
                        position: relative;

                        .eclat_product_1 {
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            z-index: 10;
                        }

                        .eclat_product_2 {
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            z-index: 11;
                        }

                        .specifics_eclats {
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            z-index: 12;
                        }

                        #eclat_stock_limited {
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            z-index: 13;
                        }
                    }

                    .block_lnk {
                        position: relative;
                        text-align: left;

                        img {
                            &:first-child {
                                top: 0;
                                opacity: 1;
                                position: absolute;
                                height: auto;
                                text-align: left;
                                transition: all 0.3s ease-in-out;
                                left: 0px;
                            }

                            &:last-child {
                                top: 0;
                                opacity: 0;
                                position: absolute;
                                height: auto;
                                text-align: left;
                                transition: all 0.3s ease-in-out;
                                left: 0px;
                            }
                        }
                    }

                    .rolloverimgContainer {
                        height: 215px;
                        overflow: hidden;
                        text-align: left;
                        position: relative;
                        left: 0px;
                        width: 100%;
                    }

                    .product_infos {
                        padding-top: 12px 0 0;
                    }

                    .wrapper_description {
                        h3 {
                            font-family: $ctaFont;
                            font-size: 14px;
                            letter-spacing: 0.2em;
                            line-height: 16.8px;
                            margin: 15px 0;
                            padding: 0 2px;
                        }
                    }

                    .infosdispo {
                        display: none;
                    }
                }
            }

            .btn-product {
                display: block;
                margin: 6px auto;
                text-align: center;
                font-family: $ctaFont;
                font-size: 14px;
                text-transform: lowercase;
                letter-spacing: 0.2em;
                line-height: 16.8px;
                height: 50px;
                padding: 15px;
                width: 288px;
                cursor: pointer;

                &:hover {
                    color: $whiteColor !important;
                }
            }
        }
    }
}

#search_page .title_no_result,
.page_rayon .no_result_block {
    margin-bottom: 0.575rem;
}

body:not(.homepage) {
    #top_wrapper .header .top_header_wrapper .block_top_links .block_top_item.search {
        .wrap_field {
            max-width: $wrapperXSmall;

            .aft.search {
                top: 50%;
                transform: translateY(-50%);

                &::after {
                    content: url(../svg/icon_search_small.svg);
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}

/**
 * Search no result gondole
 */
#content_obj.search_gondole {
    position: relative;
    padding: 0rem 0;

    .title_gondole {
        text-align: center;
        font-family: $primaryFont;
        font-size: 60px;
        line-height: 72px;
        text-transform: lowercase;
        font-style: italic;
        margin: 90px -2px 25px 0;

        &:first-letter {
            text-transform: capitalize;
        }
    }

    a.gondole_cta {
        width: fit-content;
        margin: 0 auto;
        color: $ctaColor;
        box-shadow: inset 0 0 0 2px $whiteColor;
        padding: 16px 20px;
        min-width: 290px;

        &:hover {
            background-color: $ctaColor;
            color: $whiteColor;
        }
    }

    .swiper-container {
        display: flex;
        max-width: 1022px;
        width: 100%;
        padding-bottom: 0px;
        min-height: 440px;

        .swiper-wrapper {
            justify-content: center;
            column-gap: 25px;
        }

        .swiper-slide {
            width: 31%;
            text-align: center;
            min-height: 378px;
            position: relative;
            margin-bottom: 1.625rem;
            height: 100%;

            .hover_alt {
                top: 0px;
                position: relative;

                .eclat_product_1 {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    z-index: 10;
                }

                .eclat_product_2 {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    z-index: 11;
                }

                .specifics_eclats {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    z-index: 12;
                }

                #eclat_stock_limited {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    z-index: 13;
                }
            }

            .block_lnk {
                position: relative;
                width: 100%;
                padding-top: calc(250 / 300 * 100%);
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%;
                    max-width: 300px;
                    height: auto;
                    top: 0;
                    opacity: 1;
                    position: absolute;
                    transition: all 0.3s ease-in-out;
                }
            }

            .rolloverimgContainer {
                height: 250px;
                overflow: hidden;
                text-align: left;
                position: relative;
                left: 0px;
                width: 100%;
            }

            .product_infos {
                padding-top: 12px 0 0;
            }

            .wrapper_description {
                h3 {
                    font-family: $ctaFont;
                    font-size: 14px;
                    letter-spacing: 0.2em;
                    line-height: 16.8px;
                    margin: 15px 0;
                    padding: 0 2px;
                }
            }

            .pricetag {
                text-align: center;
                font-family: $primaryFont;
                font-size: 24px;
                line-height: 28.8px;
                letter-spacing: 0.2em;
                text-align: center;
            }

            .item_name {
                width: 65%;
            }

            .item_price {
                margin-bottom: 20px;
            }

            .cta_express {
                bottom: 22px;
            }

            &:hover {
                border: 0px solid transparent;
                .pricetag {
                    text-align: center;
                    font-family: $primaryFont;
                    font-size: 1.125rem;
                    line-height: 21px;
                    left: 0;
                    position: relative;
                    top: 3px;
                    span{
                        font-family: $primaryFont!important;
                        font-size: 1.125rem!important;
                        line-height: 21px!important;
                        text-transform: unset!important;
                        letter-spacing: unset!important;
                    }
                    &::before{
                        content:'|';
                        margin-left: 5px;
                        margin-right: 5px;
                    }

                    &:hover {
                        color: white;

                        &::before {
                            color: white;
                        }
                    }
                }
            }

            .infosdispo {
                display: none;
            }

        }
    }
}

/**
 * CTA line for EN, DE, NL
 */
body {
    &.en {
        #list_item {
            .wrapper_bandeau {
                .wrapper_img {
                    .infos {
                        .taunt_click {
                            &::before {
                                width: 6rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &.de {
        #list_item {
            .wrapper_bandeau {
                .wrapper_img {
                    .infos {
                        .taunt_click {
                            &::before {
                                width: 7rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &.nl {
        #list_item {
            .wrapper_bandeau {
                .wrapper_img {
                    .infos {
                        .taunt_click {
                            &::before {
                                width: 4.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}