body {
    &.faq {
        font-family: $primaryFont;

        .for_mob {
            display: none;
        }

        .theme_page {
            display: none;

            &.actif {
                display: block;
            }
        }

        .cache,
        [v-cloak] {
            display: none;
        }

        .wrapper_moncompte {
            max-width: 1280px;
            width: 100%;
        }

        h1 {
            font-family: $primaryFont;
            font-size: 3.75rem;
            font-style: italic;
            font-weight: 400;
            margin-bottom: 9px;
        }

        .wrapper_menu_compte_wrapper {
            font-size: 2.5rem;
            font-family: $secondaryFont;
            font-style: italic;
            text-align: center;
        }

        .wrapper_menu_compte_wrapper,
        .site_search {
            width: 100%;
            margin: 0 auto;
        }

        .faq_content {
            .theme_global {
                display: flex;
                justify-content: space-between;

                .theme {
                    padding: 4.5rem;
                    width: 33.33%;
                    max-width: 25.625rem;
                    height: 350px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: $grey;
                    box-sizing: border-box;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    margin-right: 26px;

                    &:last-child {
                        margin-right: 0;
                    }

                    .back_opacity {
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(51, 51, 51, 0.4);
                        opacity: 0;
                        z-index: 10000;
                        position: absolute;
                        transition: all 0.5s ease-in-out;

                        &.actif {
                            opacity: 1;
                        }
                    }

                    &:hover {
                        .faq_background_image {
                            filter: none;

                            .back_opacity {
                                opacity: 1;
                            }
                        }
                    }

                    .faq_background_image {
                        transform: scale(1.1);
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-position: right;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }

                    h2 {
                        font-size: 2.625rem;
                        font-family: $primaryFont;
                        font-weight: 100;
                        color: $whiteColor;
                        font-style: italic;
                        text-align: center;
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }

        .faq_level_1 {
            opacity: 0;
            transition: all 0.5s ease-in-out;
            grid-row: 1;
            grid-column: 1;
            pointer-events: none;

            &.active {
                opacity: 1;
                transition: all 0.5s ease-in-out;
                pointer-events: initial;
            }

            .faq_title {
                pointer-events: none;
                font-size: 3.75rem;
                font-style: italic;
                padding-bottom: 0.313rem;
                border-bottom: 0px solid $ctaColor;
            }

            .faq_question {
                padding: 23px 0 20px 0;
                font-family: $ctaFont;
                font-size: 0.875rem;
                letter-spacing: 0.2em;
                text-transform: lowercase;
                border-bottom: 1px solid $ctaColor;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                &::after {
                    content: url(../svg/arrow_orange_bottom.svg);
                    display: inline-block;
                    font-size: 1.5rem;
                    position: relative;
                    transform: rotate(0);
                    transition: all 0.5s ease-in-out;
                    left: -19px;
                    top: -5px;
                }

                &.active {
                    border-bottom: 0;

                    &::after {
                        transform: rotate(180deg);
                        transition: all 0.5s ease-in-out;
                    }

                    & + .faq_reponse {
                        border-bottom: 1px solid $ctaColor;
                    }
                }
            }

            .faq_reponse {
                font-family: $primaryFont;
                color: $primaryColor;
                line-height: 26px;
                font-size: 0.9375rem;
                padding-bottom: 1.875rem;
            }
        }

        .loupe {
            background-image: url('../img/loupe.png');
            height: 10px;
            width: 10px;
            position: relative;
            top: 19px;
        }

        .site_search {
            position: relative;
            margin: 0rem auto 1.2rem auto;

            #faq_search {
                border-top: 0px solid transparent;
                border-left: 0px solid transparent;
                border-right: 0px solid transparent;
                border-bottom: 1px solid $platinum;
                border-radius: 0;
                padding: 0 20px;
                width: 97%;
                height: 2em;
                font-family: $primaryFont;
                color: $primaryColor;
                font-size: 15px;
                margin-bottom: 10px;

                &::after {
                    content: url(../img/loupe.png);
                    position: relative;
                    left: -3px;
                    top: 10px;
                }

                &:focus-visible {
                    border: 0px solid $primaryColor;
                    outline: -webkit-focus-ring-color auto 0px;
                }

                &:focus {
                    border-bottom: 1px solid $primaryColor;
                    box-shadow: 0 0 0 0px $lemongrass;
                    border-top: 0px solid transparent;
                    border-left: 0px solid transparent;
                    border-right: 0px solid transparent;
                }
            }

            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(-70%, -50%);
                content: '';
                display: block;
                width: 17px;
                height: 17px;
                mask-image: url(../svg/spritesheet.svg);
                background-color: $primaryColor;
            }
        }

        .search_results {
            display: none;
            border: 1px solid $dawnPink;
            border-top: none;
            background: $whiteColor;
            position: absolute;
            top: 100%;
            width: 97%;
            z-index: 3;
            padding: 0 1em;
            font-size: 14px;
            letter-spacing: 0.04rem;
            transition: all 0.2s ease-out;
            max-height: 30rem;
            overflow-y: scroll;

            .brand_item {
                padding: 1rem 0;
                cursor: pointer;

                &:first-child {
                    padding-top: 2rem;
                }

                &:last-child {
                    padding-bottom: 2rem;
                }
            }
        }

        .theme_page {
            .theme_detail {
                position: relative;
                display: grid;
                min-height: 100px;
                max-width: 1442px;
                width: 100%;
                margin: 5.5rem auto 0;
            }

            .theme_menu {
                display: none;
            }
        }
    }

    #wrapper_cotnacteznous {
        width: 100%;
        max-width: $wrapperMedium;
        margin: 0 auto;

        @include averageScreen {
            padding: 0 $max_pad;
        }

        .titre_contact {
            font-size: 2.75rem;
            font-family: $secondaryFont;
            font-style: italic;
            text-align: center;
            margin-bottom: 2rem;
        }

        .contentFaqForm {
            display: flex;
            justify-content: space-between;
            column-gap: 2rem;

            @include smallScreen {
                flex-direction: column;
                row-gap: 2rem;
            }
        }

        .wrapperImg {
            @include smallScreen {
                height: 400px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }

        .wrapperContentFaqForm {
            width: 100%;

            #easiformarea {
                #easiformmessages {
                    width: 100%;
                    margin: 0.5rem 0 1.5rem;

                    #recaptcha_message,
                    div {
                        width: 100%;
                    }
                }
            }
        }
    }
}