a#cartLogo {
    display: none;
}

/* HEADER */
#site_head_wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    .low_res {
        display: none !important;
    }

    .container_menu_items {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        column-gap: 57px;

        @media screen and (min-width: 1201px) and (max-width: 1339px){
            column-gap: 1.2rem;
        }

        @media screen and (min-width: 1340px) and (max-width: 1520px) {
            column-gap: 2.15rem;
        }
    }

    .header .container {
        width: 96%;
        max-width: $wrapperRayon;
        margin: 0 auto;
    }

    &.sticky {
        height: $headerHeightSmall;

        #show_top_wish,
        #show_top_cart,
        #topLogin {
            height: calc(100vh - $headerHeightSmall);
        }

        #topLogin,
        #show_top_wish,
        #show_top_cart {
            top: 39px;
            right: -20px;
            padding-top: 16px;

            @media screen and (min-width: 1201px) and (max-width: 1250px) {
                top: 15px;
                padding-top: 17px;
            }

            @media screen and (min-width: 1251px) {
                top: 50px;
                padding-top: 17px;
            }
        }

        .head_top {
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
            transition: box-shadow ease-in-out 250ms;
            &.removeShadow {
                box-shadow: unset;
                transition: box-shadow ease-in-out 250ms;
            }
        }

        .smallLogo {
            z-index: 1;

            a {
                display: block;
                background: url(../svg/logo_sticky.svg);
                width: 39px;
                height: 48px;
            }
        }

        .wrap_lang,
        .customer_service,
        .blog {
            display: none !important;
        }

        .logo {
            display: none !important;
            height: 0;
        }

        #top_wrapper .header {
            padding: 0px 0px;
            max-width: $wrapperXLarge;
            margin: 0 auto;

            @include averageScreen {
                padding: 0px 20px;
            }

            .top_header_wrapper {
                align-items: center;
                height: 100%;

                .block_top_links {
                    align-items: center;

                    .block_top_item {

                        &.my_account,
                        &.wishlist,
                        &.my_basket {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            padding: inherit;
                            position: inherit;
                            left: inherit;
                            top: inherit;
                        }

                        &.wishlist {
                            .cart {
                                left: 6px;
                                top: -3px;
                            }
                        }

                        &.my_basket {
                            min-width: 30px;
                        }

                        /* Hide fake search input when sticky */
                        &.search {
                            border-bottom: none;
                            
                            #trigger_recherche {
                                width: auto;

                                .trigger_txt {
                                    display: none;
                                }

                                .icon_search {
                                    width: 15px;
                                    height: 17px;
                                    background: url(../svg/icon_search_2.svg);
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                }
                            }

                            .wrap_search_field {
                                top: 100%;
                            }
                        }

                        /* Replace "Mon compte" with icon when sticky */
                        &.my_account {
                            min-width: unset;

                            .icon { 
                                width: 15px;
                                height: 17px;
                                background: url(../svg/icon_account.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                top: -3px;
                                position: relative;

                                .bfr.account {
                                    display: none;
                                }
                            }
                        }
                    }

                    &.right {
                        column-gap: 1rem;
                        height: inherit;
                        align-items: center;

                        @media screen and (min-width: 1201px) and (max-width: 1415px) {
                            column-gap: 0.2rem;
                        }

                        @media screen and (min-width: 1201px) and (max-width: 1250px) {
                            transform: translateX(24px);
                        }
                    }

                    &.left {
                        z-index: 1;
                        width: 39px;

                        @media screen and (min-width: 1201px) and (max-width: 1250px) {
                            transform: translateX(-27px);
                        }
                    }
                }
            }

            .bot_header_wrapper {
                margin-top: 0;
                left: 0%;
                transform: none;

                .main_menu_contents {
                    margin-left: -7%;

                    .main_menu_itm {
                        padding: 0;

                        .subCat_item,
                        .entry {
                            align-items: center;
                            padding-bottom: inherit;
                            margin-top: 9px;
                        }
                    }
                }
            }
        }

        .bot_header_wrapper {
            .main_menu_itm {
                display: flex;
                height: 76px;
                align-items: center;
            }
        }
    }
}

.bandeau_on {
    #header_mention_id {
        display: block;
        max-width: 1920px;
        margin: auto;

        .close_delais {
            opacity: 1;
        }
    }
}

.sticky #header_mention_id {
    height: 0;
}

.sticky #header_mention_id .close_delais {
    opacity: 0 !important;
}

/* PREHEADER */
#header_mention_id {
    height: 40px;
    position: relative;
    display: none;

    .promo_swiper {
        height: 100%;
    }

    .swiper-container {
        z-index: 100;
    }

    .swiper-slide {
        padding: $fs_mid 0;
        text-align: center;
        font-family: $primaryFont;
        background-color: $backgroundColor;
    }

    .close_delais {
        transition: opacity ease-in 250ms;
        position: absolute;
        z-index: 101;
        right: 8px;
        opacity: 0;
        top: 50%;
        transform: translateY(-66%);
        cursor: pointer;

        &.close_pop {
            background: url(../svg/icon_close.svg);
            width: 12px;
            height: 12px;
        }
    }

    .wrap_preheadertxt {
        em {
            font-style: italic;
            font-weight: 400;
        }

        strong {
            font-weight: 700;
            font-style: italic;
        }

        a {
            text-decoration: underline;
        }

        span {
            color: $primaryColor;
            font-style: normal;
            font-weight: 400;
        }
    }
}

.head_top {
    position: relative;
    background-color: $whiteColor;
    font-size: $fs_mid;
}

#top_wrapper {
    &.head_top {
        position: relative;
        background-color: $whiteColor;
    }

    .header {
        width: 100%;
        padding: 1.375rem 0 0;
        background-color: $whiteColor;

        @include averageScreen {
            padding: 1.375rem 0px 0;
        }

        //ICONE BURGER
        .burger_menu_container {
            display: none;
        }

        /* Hidings */
        .retour_low_res {
            display: none;
        }

        .top_header_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            min-height: 87px;
            width: 100%;

            .right {
                .my_account:hover {
                    #topLogin {
                        opacity: 1;
                        pointer-events: initial;
                    }
                }
            }

            .block_top_links {
                display: flex;
                transition: opacity 0.4s ease;

                &.left {
                    &.onRollover {
                        .wrap_lang .lang_switch {
                            .switch_arrow {
                                &::after {
                                    background-color: $whiteColor;
                                }
                            }
                        }
                    }

                    .wrap_lang .lang_switch {
                        .switch_arrow {
                            &::after {
                                background-color: $whiteColor;
                            }
                        }
                    }
                }

                /*LANG SWITCH*/
                &.left.onRollover {
                    .customer_service {
                        color: $primaryColor;
                    }
                }

                .wrap_lang {
                    position: relative;
                    display: flex;
                    justify-content: space-between;

                    .lang_switch {
                        position: relative;
                        z-index: 91;
                        display: flex;
                        cursor: pointer;
                        padding-right: calc(10px + 0.5rem);
                        margin-right: .3rem;
                        font-family: $primaryFont;
                        transition: color ease-in-out 0.3s;

                        span {
                            font-size: $fs_mid;
                        }

                        .switch_arrow {
                            position: absolute;
                            width: 10px;
                            height: 6px;
                            bottom: 4px;
                            right: 0;
                            display: block;

                            &::after {
                                position: absolute;
                                left: 50%;
                                transform: translateX(-83%);
                                transform-origin: center;
                                content: '';
                                display: block;
                                background-image: url(../svg/arrow_header.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                width: 8px;
                                height: 5px;
                                transition: transform 0.3s ease,
                                    background-color 0.4s ease;
                                top: 2px;

                                @media all and (-ms-high-contrast: none),
                                (-ms-high-contrast: active) {
                                    background-color: transparent !important;
                                    border: solid $black;
                                    border-width: 0 2px 2px 0;
                                    display: inline-block;
                                    padding: 0px;
                                    transform: rotate(45deg);
                                    width: 8px;
                                    height: 5px;
                                }
                            }
                        }

                        &:hover,
                        &.hovering {
                            color: $ctaColor;

                            & + div + .langRollover, & + .currencyRollover {
                                transform: translateY(263px);
                                background-color: $whiteColor;

                                .holder_langs .lang_itm,
                                .reassu_item,
                                .lang_cta_title {
                                    opacity: 1;
                                    transition: opacity 0.2s 0.3s ease;
                                }
                            }

                            .switch_arrow {
                                &::after {
                                    transform: translateX(-83%) rotate(180deg);

                                    @media all and (-ms-high-contrast: none),
                                    (-ms-high-contrast: active) {
                                        transform: translateX(0%) rotate(45deg);
                                    }
                                }
                            }
                        }
                    }

                    .langRollover, .currencyRollover {
                        position: absolute;
                        width: auto;
                        height: auto;
                        top: -245px;
                        left: 0;
                        transition: all 0.4s ease-in-out;
                        transform: translateY(-100%);
                        padding: 90px 0 0;
                        z-index: 1;

                        &::before {
                            content: '';
                            background-color: $whiteColor;
                            position: absolute;
                            left: -9.8rem;
                            width: 420px;
                            z-index: 1000;
                            height: 120px;
                            border-top: 1px solid $ctaColor;
                        }

                        .holder_langs {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            border-top: 1px solid $ctaColor;
                            width: 240px;
                            z-index: 1000;
                            padding: 2.5rem 0;

                            .lang_itm,
                            .lang_cta_title {
                                line-height: 16.8px;
                                letter-spacing: 2.8px;
                                font-family: $ctaFont;
                                text-transform: lowercase;
                            }

                            .lang_itm,
                            .reassy_item,
                            .lang_cta_title {
                                opacity: 0;
                                transition: opacity 0.2s ease;
                            }

                            .lang_itm {
                                position: relative;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding-bottom: 1.5625rem;

                                &:last-child {
                                    padding-bottom: 0;
                                }

                                &::after {
                                    right: 0;
                                    content: url(../svg/arrow_orange_right.svg);
                                    display: block;
                                    background-repeat: no-repeat;
                                    width: 10px;
                                    height: 10px;
                                    background-size: 100%;
                                    margin-left: 1rem;
                                }
                            }

                            .reassu_item {
                                display: flex;
                                align-items: flex-start;
                                margin: 1rem 0;
                                line-height: 1.5;

                                .check {
                                    margin-right: 1rem;

                                    &::before {
                                        width: 10px;
                                        height: 6px;
                                    }
                                }
                            }

                            .lang_cta_title {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                font-weight: 400;
                                margin-top: 1rem;
                            }
                        }
                    }
                            .langRollover {
                                &::before {
                                height: 190px;
                        }
                    }
                }

                .wrap_pipe {
                    margin-right: 1rem;
                    z-index: 999;
                }

                &.logo {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 100;

                    h1,
                    div {
                        font-size: 0;
                        display: block;
                        width: 281px;
                        height: 41px;
                        background: transparent url(../svg/pierre-marcolini.svg) no-repeat center;
                        background-size: contain;
                    }
                }

                &.right {
                    justify-content: flex-end;
                    align-items: first baseline;
                    column-gap: 16px;
                    height: fit-content;
                    position: static;
                    z-index: 2;

                    @media screen and (max-device-width: $wrappermidSmall) {
                        column-gap: 2.175rem;
                        justify-content: flex-end;
                    }
                }

                .block_top_item_wrapper {
                    display: flex;
                }

                .block_top_item {
                    &.customer_service {
                        position: relative;
                        z-index: 90;
                        margin-left: 1rem; // WP-21407 EVO SWITCH LANG HEADER

                        .top_item_cta {
                            position: relative;
                            z-index: 91;

                            &:hover,
                            &.hovering {
                                color: $ctaColor;

                                & + .serviceRollover {
                                    transform: translateY(263px);

                                    .holder_services {
                                        opacity: 1;
                                        transition: opacity 0.2s 0.3s ease;
                                    }

                                    .service {
                                        opacity: 1;
                                        transition: opacity 0.2s 0.3s ease;
                                    }
                                }

                                .switch_arrow {
                                    &::after {
                                        transform: rotate(180deg);

                                        @media all and (-ms-high-contrast: none),
                                        (-ms-high-contrast: active) {
                                            transform: translateX(0%) rotate(45deg);
                                        }
                                    }
                                }
                            }

                            &.showlayer {
                                padding-right: calc(-4px + 0.5rem);
                                margin-right: 1.875rem;

                                span {
                                    font-size: 14px;
                                }

                                .switch_arrow {
                                    position: absolute;
                                    width: 8px;
                                    height: 5px;
                                    bottom: 4px;
                                    right: -4px;
                                    display: block;

                                    &::after {
                                        position: absolute;
                                        left: 50%;
                                        transform-origin: center;
                                        content: '';
                                        display: block;
                                        background-image: url(../svg/arrow_header.svg);
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        width: 100%;
                                        height: 100%;
                                        transition: transform 0.3s ease,
                                            background-color 0.4s ease;
                                        top: 0px;

                                        @media all and (-ms-high-contrast: none),
                                        (-ms-high-contrast: active) {
                                            background-color: transparent !important;
                                            border: solid $black;
                                            border-width: 0 2px 2px 0;
                                            display: inline-block;
                                            padding: 0px;
                                            transform: rotate(45deg);
                                            width: 8px;
                                            height: 5px;
                                        }
                                    }
                                }
                            }
                        }

                        .serviceRollover {
                            position: absolute;
                            width: 420px;
                            height: auto;
                            top: -245px;
                            left: -8.5rem;
                            transition: all 0.4s ease-in-out;
                            transform: translateY(-100%);
                            padding: 90px 0 0 33px;
                            z-index: 1;

                            &::before {
                                content: '';
                                background-color: $whiteColor;
                                position: absolute;
                                left: -6rem;
                                width: 420px;
                                z-index: 1000;
                                height: 245px;
                                border-top: 1px solid $ctaColor;
                            }
                        }

                        .holder_services {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            width: auto;
                            z-index: 1000;
                            padding: 3rem 0;
                            left: -27px;

                            @media screen and (max-width: 1320px) {
                                padding: 3rem 1.5rem;
                            }
                        }

                        .service {
                            opacity: 0;
                            transition: opacity 0.2s ease;

                            a {
                                position: relative;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding-bottom: 1.5625rem;
                                font-size: $fs_mid;
                                line-height: 16.8px;
                                letter-spacing: 2.8px;
                                font-family: $ctaFont;
                                text-transform: lowercase;
                            }
                        }
                    }

                    // SEARCH
                    &.search {
                        @include midMediumScreen {
                            border: 0;
                        }

                        .loader_scroll {
                            display: none;
                        }

                        border-bottom: 1px solid $ctaColor;
                        max-height: 16px;

                        /* Fake search input */
                        #trigger_recherche {
                            font-family: $primaryFont;
                            font-weight: 400;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 158px;
                            font-size: $fs_mid;
                            cursor: pointer;
                            color: $primaryColor;

                            .trigger_txt {
                                flex-grow: 1;
                                color: $secondaryColor;

                                &::first-letter {
                                    text-transform: uppercase;
                                }
                            }

                            .icon_search {
                                width: 15px;
                                height: 17px;
                                background: url(../svg/icon_search_2.svg);
                                background-repeat: no-repeat;
                                position: relative;
                                top: -4px;
                                right: 0px;
                            }

                            @include midMediumScreen {
                                width: auto;
                                font-size: 0;
                                border: 0;
                            }
                        }

                        .wrap_search_field {
                            position: absolute;
                            left: 0;
                            top: calc(100% + 10px);
                            transition: transform 0.2s ease-in-out,
                                opacity 0.6s ease;
                            transform: translateY(-200%);
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            background: $whiteColor;
                            border-top: 1px solid $ctaColor;
                            z-index: -1;
                            opacity: 0;
                            display: flex;
                            flex-direction: column;
                            padding: 2.6vw 0 0.9vw!important;

                            &.actif {
                                z-index: 1;
                                opacity: 1;
                                transform: translateY(0);
                            }

                            @include averageScreen {
                                padding: 5vw $min_pad;
                            }

                            #search_results {
                                display: inline-block;
                                text-align: center;
                                width: 100%;

                                li {
                                    display: none;
                                    width: 100%;
                                    text-align: center;

                                    &:first-child {
                                        display: inline-block;
                                    }

                                    &:not(.search_loader) {
                                        color: $ctaColor;
                                        font-family: $primaryFont;
                                        font-size: $fs_subtitle;
                                        text-transform: lowercase;

                                        &:not(:last-child) {
                                            margin-bottom: 2rem;

                                            span:nth-child(1) {
                                                display: none;
                                            }
                                        }

                                        &:last-child {
                                            display: none;
                                        }

                                        span {
                                            margin-right: 1rem;
                                        }
                                    }

                                    &.search_loader {
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                }
                            }
                        }

                        .wrap_field,
                        #search_results {
                            max-width: $wrappermidMedium;
                            width: 100%;
                            margin: 0 auto;
                        }

                        .wrap_field {
                            margin-bottom: 2vw;
                            position: relative;

                            .aft.search {
                                position: absolute;
                                top: 10px;
                                right: 0px;

                                &:after {
                                    content: url(../svg/icon_search.svg);
                                    width: 20px;
                                    height: 26px;
                                    display: block;
                                }
                            }
                        }

                        #search_home {
                            outline: none;
                            width: 100%;
                            background-color: transparent;
                            padding-bottom: 16px;
                            border: 0;
                            border-bottom: 1px solid $ctaColor;
                            font-family: $primaryFont;
                            font-style: italic;
                            font-size: $fs_large;
                            letter-spacing: 1.4px;

                            &:first-letter {
                                text-transform: uppercase;
                            }

                            &:focus {
                                outline: none;
                            }

                            &::first-letter {
                                text-transform: uppercase;
                            }
                        }
                    }

                    &.wishlist {
                        display: flex;
                        height: 100%;
                        padding: 0 10px;
                        margin-left: 12px;

                        .cart {
                            width: 16px;
                            height: 14px;
                            background: url(../svg/icon_wishlist.svg);
                            background-size: auto;
                            display: block;
                            position: relative;
                            top: -2px;
                            left: 1px;
                            color: $ctaColor;

                            @media screen and (max-width: 1024px) {
                                top: -1px;
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                height: 1px;
                                width: 24px;
                                background: $ctaColor;
                                bottom: -4px;
                                right: 0;
                                opacity: 0;
                            }

                            span {
                                font-size: 0;
                            }

                            .cart_nbr_prod {
                                position: absolute;
                                left: -15px;
                                top: 3px;
                                color: $ctaColor;
                                font-family: $primaryFont;
                                font-style: normal;
                                font-size: 12px;
                                line-height: 14px;
                                width: 13px;
                                text-align: right;

                                &.basket_qte {
                                    display: none;
                                }
                            }

                            &:hover {
                                &::after {
                                    opacity: 1;
                                }
                            }
                        }

                        &.hover,
                        &:hover {

                            #show_top_wish,
                            #show_top_wish.actif {
                                opacity: 1;
                                pointer-events: initial;
                            }

                            .cart {
                                &::after {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    &.my_account {
                        display: flex;
                        margin-left: 14px;

                        &.hover,
                        &:hover {

                            #topLogin,
                            #topLogin.actif {
                                opacity: 1;
                                pointer-events: initial;
                                transform: translateX(0);
                            }
                        }
                    }

                    &.my_basket {
                        display: flex;
                        height: 100%;
                        padding: 0 10px;
                        margin-left: 0;

                        .cart {
                            width: 12px;
                            height: 15px;
                            background: url(../svg/icon_panier.svg);
                            background-size: 100%;
                            display: block;
                            position: relative;
                            color: $ctaColor;
                            top: -3px;
                            left: 0px;

                            &::after {
                                content: '';
                                position: absolute;
                                height: 1px;
                                width: 24px;
                                background: $ctaColor;
                                bottom: -4px;
                                right: -2px;
                                opacity: 0;
                            }

                            span {
                                font-size: 0;
                            }

                            .cart_nbr_prod {
                                position: absolute;
                                left: -15px;
                                top: 5px;
                                color: $ctaColor;
                                font-family: $primaryFont;
                                font-style: normal;
                                font-size: 12px;
                                line-height: 14px;
                                width: 13px;
                                text-align: right;

                                &.wishlist_qte {
                                    display: none;
                                }
                            }

                            &:hover {
                                &::after {
                                    opacity: 1;
                                }
                            }
                        }

                        &.hover,
                        &:hover {

                            #show_top_cart,
                            #show_top_cart.actif {
                                opacity: 1;
                                pointer-events: initial;
                            }

                            .cart {
                                &::after {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    &.blog {
                        a {
                            span {
                                color: $primaryColor;
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                text-transform: capitalize;
                            }
                        }

                        @media screen and (max-width: 1200px) {
                            display: none;
                        }

                        &:hover {
                            a {
                                span {
                                    color: $ctaColor;
                                }
                            }
                        }
                    }
                }
            }
        }
        .currencyRollover {
            .holder_langs {
                margin-left: -3rem;
            }
        }

        .bot_header_wrapper {
            width: 100%;
            margin-top: 43px;
            min-height: 58px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background-color: $whiteColor;

            .main_menu_contents {
                display: flex;
                justify-content: center;
                font-family: $ctaFont;
                text-transform: inherit;
                align-items: center;
                height: 100%;
                column-gap: 3.6rem;

                @media screen and (max-device-width: $wrappermidSmall) {
                    column-gap: 20%;
                }

                .bloc_top_low_res {
                    display: none;
                }

                /* HEADER ENTRIES */
                .main_menu_itm {
                    font-size: $fs_mid;
                    letter-spacing: 0.2em;
                    line-height: 1.05rem;
                    text-align: center;
                    padding-bottom: 7px;

                    @include mediumScreen {
                        font-size: $fs_mid;
                        letter-spacing: 0.2em;
                        line-height: 1.05rem;
                    }

                    a {
                        color: inherit;
                    }

                    &.menu_itm_hover {
                        cursor: pointer;

                        &:hover {
                            .has_submenu {
                                transition: color ease-in-out 0.3s;
                                color: $ctaColor;

                                a {
                                    transition: color ease-in-out 0.3s;
                                    color: $ctaColor;
                                }
                            }

                            .submenu_wrapper {
                                display: block;
                                animation: fadeIn 0.3s ease-in-out forwards;
                                pointer-events: initial;
                                transition: all 0.2s ease-in-out 0.3s;
                                border-top: 1px solid $ctaColor;
                                background: $whiteColor;
                                z-index: 10;

                                .see_all {
                                    position: absolute;
                                    bottom: 40px;
                                    left: 50%;
                                    transform: translateX(-50%);

                                    a {
                                        font-size: $fs_mid;

                                        span {
                                            color: $ctaColor;

                                            &:hover {
                                                color: $primaryColor;
                                            }
                                        }

                                        &::after {
                                            content: '';
                                            position: absolute;
                                            bottom: -4px;
                                            height: 1px;
                                            background-color: $ctaColor;
                                            width: 100%;
                                            left: 0;
                                        }

                                        &:hover {
                                            &::after {
                                                background-color: $primaryColor;
                                            }
                                        }
                                    }
                                }

                                &.emptyMenu {
                                    display: none;
                                }
                            }
                        }
                    }

                    .subCat_item,
                    .entry {
                        position: relative;
                        z-index: 90;
                        display: flex;
                        text-transform: lowercase;
                        align-items: flex-end;
                        height: 100%;
                        padding-bottom: 0.975rem;
                        min-height: 58px;
                        width: 100%;
                    }

                    .submenu_wrapper {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        padding: 0.9375rem 9rem;
                        box-sizing: border-box;
                        z-index: 2;
                        overflow: hidden;
                        transition: all 0.3s ease-in-out 0.2s;
                        pointer-events: none;
                        display: none;

                        @include mediumScreen {
                            padding: 0.9375rem 4rem;
                        }

                        @media screen and (max-width: 1324px) {
                            padding: 0.9375rem 2rem;
                        }

                        @media screen and (max-width: 1324px) {
                            padding: 0.9375rem 0rem;
                        }

                        .ss_menu_categ_wrapper {
                            display: inline-block;
                            text-align: center;
                            margin-bottom: 3.875rem;
                            width: 100%;
                        }

                        .ss_cat {
                            display: inline-block;
                            width: 100%;
                            max-width: 288px;
                            text-align: left;

                            .ss_cat_item {
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                flex-direction: row-reverse;
                                column-gap: 10px;

                                .ss_cat_name {
                                    font-family: $ctaFont;
                                    text-transform: lowercase;
                                    letter-spacing: 2.8px;

                                    span {
                                        display: table;
                                        min-width: 12%;
                                        transition: color ease-in 250ms;

                                        &:hover {
                                            color: $ctaColor;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.storelocator {
                        .submenu_wrapper {
                            padding: 0;
                        }

                        .swiper-pagination {
                            width: 100%;
                            bottom: 0.3125rem;
                            left: 50%;
                            transform: translateX(-50%);

                            .swiper-pagination-bullet {
                                margin: 0 4px;
                                background: $desertSand;
                            }

                            .swiper-pagination-bullet-active {
                                background: $ctaColor;
                            }
                        }

                        .ss_menu_categ_wrapper {
                            margin-bottom: 0;
                            max-width: 1730px;
                        }

                        .ss_cat {
                            display: flex;
                            justify-content: space-between;
                            max-width: inherit;
                            padding: 1.5rem;
                        
                            .ss_cat_item {
                                display: flex;
                                align-items: center;
                                width: fit-content;
                        
                                &:last-child {
                                    margin-right: 0;
                                }
                        
                                .ss_cat_name {
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;
                                    text-align: center;
                                    width: 100%;
                        
                                    span {
                                        &.visuel {
                                            padding: 0;
                                        }
                        
                                        img {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.our_house {
                        .ss_menu_categ_wrapper {
                            margin-bottom: 0;
                        }

                        .submenu_wrapper {
                            padding: 0;
                        }

                        .ss_cat {
                            display: block;
                            justify-content: unset;
                            width: 100%;
                            text-align: left;
                            padding: 30px 0 0 0;
                            max-width: $maxWidthNavHouse;
                            margin: 0 auto;

                            .ss_cat_item {
                                display: inline-block;
                                width: auto;
                                vertical-align: top;
                                text-align: left;
                                margin-bottom: 50px;

                                .ss_cat_name {
                                    display: block;
                                    align-items: inherit;
                                    flex-direction: inherit;
                                    text-align: left;
                                    margin-right: 0;
                                    column-gap: inherit;

                                    span {
                                        display: block;
                                        min-width: 158px;
                                        max-width: 220px;
                                    }
                                }

                                &:nth-child(1) {
                                    margin-right: 88px;
                                }

                                &:nth-child(2) {
                                    margin-right: 100px;
                                }

                                &:nth-child(3) {
                                    margin-right: 74px;
                                }

                                &:nth-child(4) {
                                    margin-right: 0;
                                }

                                &:nth-child(5) {
                                    margin-right: 88px;
                                }

                                &:nth-child(6) {
                                    margin-right: 69px;
                                }
                            }
                        }

                        @include mediumScreen {
                            .ss_cat {
                                .ss_cat_item {
                                    width: 24%;
                                    margin-right: 0 !important;

                                    .ss_cat_name {
                                        span {
                                            min-width: 20%;
                                            max-width: 260px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.corporate_gifts {
                        .ss_menu_categ_wrapper {
                            margin-bottom: 0;
                        }

                        .submenu_wrapper {
                            padding: 0.9375rem 7rem 1.25rem;
                        }

                        .ss_cat {
                            display: flex;
                            justify-content: space-between;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 50px 35px;
                            width: 100%;
                            text-align: left;
                            padding: 30px 0;
                            max-width: 1300px;
                            margin: 0 auto;
                        }

                        .ss_cat_item {
                            display: inline-block;
                            width: auto;
                            vertical-align: top;
                            text-align: left;
                            white-space: nowrap;
                        }

                        .ss_cat_name {
                            display: block;
                            align-items: inherit;
                            flex-direction: inherit;
                            text-align: left;
                            margin-right: 0;
                            column-gap: inherit;

                            span {
                                display: block;
                                min-width: 158px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.sticky #top_wrapper .header .top_header_wrapper .block_top_links .block_top_item.search .wrap_search_field {
    padding: 7.1vh 0 0.9vh;
}

/*PANIER + WISHLIST */
#wishlist_top.hover,
#wishlist_top:hover,
#cart_top.hover {

    #show_top_wish,
    #show_top_cart {
        transform: translateX(0);
        z-index: 1;
        opacity: 1;
    }
}

// Reassurance in cart and wishlist
.prod_reassurance_swiper {
    margin-left: -1rem;
    padding: 1rem 0;
    max-width: 100%;
}

// Top Wishlist exception
.wishlist {
    &.is_logged {
        #show_top_wish.noprod {
            justify-content: unset;
        }

        .no_product {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
            letter-spacing: 0.2em;
        }
    }

    #show_top_wish {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

#show_top_wish {
    .ajax_wishlist_empty {
        flex-grow: 1;
        display: none;
        text-align: left;
    }

    #wishlist_not_logged {
        .btn_container {
            margin-bottom: 0.5rem;
            position: relative;
        }
    }

    #wishlist_header {
        margin-bottom: 5vmin;
    }

    #wishlist_cart_intro {
        text-align: left;
        font-family: $primaryFont;
        font-size: $fs_mid;
        margin-bottom: 3.35rem;
        line-height: 1.3125rem;
    }

    #wishlist_swiper_title {
        display: block;
        margin: 0 auto 0.75rem;
        font-family: $secondaryFont;
        font-size: 1.25rem;
        text-align: center;
    }

    #wishlist_swiper {

        // To prevent swipes touching
        .menu_tg_holder > a {
            padding-right: 1rem;
            width: 100%;
        }
    }

    #wishlist_footer {
        overflow: hidden;
    }

    // Buttons
    .cart_buttons {
        .btn_container {
            &:not(:last-child) {
                margin-bottom: 0.5rem;

                .button {
                    background-color: $ctaColor;
                    color: white;

                    &:hover {
                        background-color: white;
                        color: $ctaColor;
                    }
                }
            }

            &:last-child {
                .button {
                    height: 66px;
                    padding: 0 1em;
                }
            }
        }

        .btn_container {
            position: relative;
        }
    }

    #cart_list {
        height: 60%;
    }

    // Wishlist total
    .cart_panier_total {
        margin-bottom: 1.2rem;
    }
}

// Top Cart + Wish Rollovers
#show_top_cart,
#show_top_wish {
    .wrap_title {
        text-align: left;
        font-family: $primaryFont;
        font-size: $fs_xlarge;
        font-style: italic;
        text-transform: capitalize;
        margin-bottom: 0.175rem;
        line-height: 4.5rem;
        text-align: left;

        .title {
            font-family: $primaryFont;
            font-size: $fs_xlarge;
            font-style: italic;
            text-transform: capitalize;
            margin-bottom: 2.375rem;
            line-height: 4.5rem;
            text-align: left;
        }
    }

    .wrap_tot_panier {
        border-top: 2px solid $backgroundColor;

        .cart_panier_total {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

#cart_list .product-link {
    padding: 0;
    max-width: 100%;
    margin-bottom: 0;
    display: flex;

    .cart_detail_box {
        width: 100%;
        position: relative;

        .cart_product {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            font-size: 0.9375rem;
            line-height: 2rem;
            font-weight: 400;

            .title {
                margin-bottom: 0.425rem;
                font-family: $primaryFont;
                font-size: 0.9375rem;
                line-height: 1.525rem;
                font-weight: 400;
                width: 82%;
            }

            .item_price {
                margin-top: 0.5rem;
                font-family: $primaryFont;
                font-size: 0.9375rem;
                line-height: 1.125rem;
                font-weight: 400;

                .pricetag {
                    &:nth-child(even) {
                        display: none;
                    }
                }
            }

            .subs_holder {

                .sub_couleur,
                .sub_taille {
                    display: none;
                }

                .sub_quantity {
                    margin-top: 0.5rem;
                    color: $secondaryColor;
                    font-family: $primaryFont;
                    font-size: 0.9375rem;
                    line-height: 1.125rem;
                    text-transform: lowercase;
                    font-style: normal;
                    font-weight: 400;
                }

                .sub_wishlist button {
                    margin-top: 0.6rem;
                    font-family: $primaryFont;
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                    color: $primaryColor;
                    cursor: pointer;
                    text-align: left;
                    padding: 0;
                    border-bottom: 1px solid $primaryColor;
                    width: auto;

                    &:hover {
                        border-bottom: 1px solid $ctaColor;
                        color: $ctaColor;
                    }
                }
            }
        }

        .bask_action {
            cursor: pointer;
            position: absolute;
            top: 0px;
            right: 1rem;

            .remove_prod,
            .remove_top_wishlist {
                width: 12px;
                height: 12px;
                position: absolute;
                cursor: pointer;
                z-index: 2;
                font-size: 0;
                background: url(../svg/icon_close.svg);
                top: -8px;
                right: -2px;
            }
        }

        .modify_prod {
            display: none;
        }
    }
}

#show_top_wish,
#show_top_cart,
#topLogin {
    position: absolute;
    top: 37px;
    right: 0;
    width: 452px;
    height: calc(100vh - 73px);
    background-color: $whiteColor;
    cursor: initial;
    transition: opacity, transform 0.3s ease;
    pointer-events: none;
    opacity: 0;
    padding-top: 73px;
    transform: translateX(100%);

    .wrapper_menu_compte_container,
    .no_product {
        padding: 3.125rem 2.625rem 3.125rem 3.125rem;
    }

    .wrapper_menu_compte_container {
        margin-top: 20px;
        border-top: 1px solid $ctaColor;
    }

    .rollover_title {
        padding: 3.125rem 2.625rem 0 3.125rem;
        margin-top: 20px;
        border-top: 1px solid $ctaColor;
    }

    .cart_product_listing,
    .wrap_tot_panier,
    #wishlist_not_logged {
        width: calc(100% - (2.625rem + 3.125rem));
        margin: 1rem auto 3.125rem;
    }

    @media screen and (max-width: 1200px) {
        padding-top: 18px;
    }

    /* Ultra-wide > 1920px */
    @media screen and (min-width: 1921px) {
        /* Takes into account the extra white spaces on the sides and scrollbar width */
        padding-right: calc((100vw - 1937px) / 2);
        width: calc(452px + ((100vw - 1937px) / 2));
    }

    /* Portrait Paysage IPAD 7 */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
        transform: translateX(0%);
        transition: opacity 0.3s ease;
    }

    /* surface Pro */
    @media only screen and (max-width: 1368px) and (orientation: landscape) {
        transform: translateX(0%);
        transition: opacity 0.3s ease;
    }

    /* GALAXY TAB */
    @media (max-device-width: 800px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
        transform: translateX(0%);
        transition: opacity 0.3s ease;
    }
}

#site_head_wrap.sticky {

    #show_top_cart,
    #show_top_wish,
    #topLogin {
        height: calc(100vh - 51px);
    }
}

#show_top_cart {
    display: flex;
    justify-content: space-between;
    width: 640px;

    /* Without vertical swiper associated products */
    &:not(:has(>.basket_gondole)) {
        width: 480px;
    }
    
    /* Ultra-wide > 1920px */
    @media screen and (min-width: 1921px) {
        /* Takes into account the extra white spaces on the sides and scrollbar width */
        padding-right: calc((100vw - 1937px) / 2);
        width: calc(640px + ((100vw - 1937px) / 2));

        /* Without vertical swiper associated products */
        &:not(:has(>.basket_gondole)) {
            width: calc(480px + ((100vw - 1937px) / 2));
        }
    }

    &.noprod {
        .no_product {
            &:nth-child(3) {
                display: none;
            }
        }
    }

    .wrap_title .title {
        margin-bottom: -0.325rem;
    }

    #cart_list .product-link .cart_detail_box,
    #show_top_wish #cart_list .product-link .cart_detail_box {
        padding: 9px 2px;
    }

    #cart_list .product-link .cart_detail_box .cart_product .title {
        line-height: 1.725rem;
    }

    .upselling.price {
        order: 6;
        padding: 1.0625rem 1.0625rem 0.5rem 1.0625rem;
        display: flex;
        flex-direction: column-reverse;
        row-gap: 1rem;
        text-align: center;
        font-size: $fs_mid;
        line-height: 16.8px;
        color: $ctaColor;

        p {
            font-family: $ctaFont;
            text-transform: lowercase;

            #threshold_fdp_txt {
                font-family: $primaryFont;
                font-weight: 400;
            }
        }

        progress {
            width: 100%;
            height: 5px;
            border-radius: 5px;
            background: $whiteColor;
            border-color: $whiteColor;
            accent-color: $ctaColor;

            &::-webkit-progress-bar {
                background-color: $whiteColor;
                border-radius: 5px;
            }

            &::-webkit-progress-value {
                background-color: $ctaColor;
                border-radius: 5px;
            }

            &::-moz-progress-bar {
                background-color: $ctaColor;
                border-radius: 5px;
            }

            &::-moz-progress-value {
                border-radius: 5px;
            }
        }
    }

    .see_cart {
        margin-bottom: 1rem;

        .button {
            background-color: $ctaColor;
            color: white;

            &:hover {
                background-color: white;
                color: $ctaColor
            }
        }
    }

    .wrap_top_info {
        .title {
            strong {
                font-weight: normal;
            }
        }
    }

    .basket_gondole {
        max-width: 160px;
        width: 100%;
        border-top: 1px solid $ctaColor;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $backgroundColor;

        .title_gondole {
            font-family: $primaryFont;
            font-size: 17px;
            line-height: 20px;
            text-align: left;
            margin: 22px 15px 16px 20px;
            width: 75%;
        }

        #gondole_interest {
            display: flex;
            height: calc(100vh - 166px);

            .swiper-wrapper {
                flex-direction: column;
            }

            .swiper-slide {
                position: relative;
                width: 160px !important;
                padding: 0 22px;
                height: fit-content !important;
                margin-bottom: 15px;

                a {
                    display: flex;
                    flex-direction: column;
                    line-height: normal;

                    &:before {
                        display: none;
                    }

                    img {
                        width: 115px;
                        height: 96px;
                        object-fit: cover;
                    }
                }

                form {
                    position: absolute;
                    top: 76px;
                    right: 31px;

                    .button {
                        font-size: 0;
                        background: transparent;
                        border: none;
                        height: initial;

                        &:before {
                            content: '';
                            display: block;
                            z-index: 10;
                            width: 12px;
                            height: 15px;
                            background: url(../svg/icon_panier.svg);
                            background-size: 100%;
                            display: block;
                            position: relative;
                            color: $ctaColor;
                            top: -3px;
                            left: 0px;
                        }
                    }
                }

                .product_name {
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-weight: 400;
                    color: $primaryColor;
                    margin-top: 10px;
                    // white-space: nowrap;
                    // text-overflow: ellipsis;
                    // overflow: hidden;
                    line-height: 18px;

                    &.product_name_split {
                        // margin-top: unset;
                        display: none;
                    }
                }

                .item_price {
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 13px;
                    text-transform: uppercase;

                    .pricetag {
                        font-family: $primaryFont;
                        font-size: 15px;
                        display: block;
                        margin-top: 6px;
                    }

                    .crossed {
                        text-decoration: line-through;
                    }
                }

                .achat_express_gondole {
                    position: absolute;
                    top: 39%;
                    right: 17%;

                    .button {
                        font-size: 0;
                        background: transparent;
                        border: none;
                        height: initial;

                        &:before {
                            content: '';
                            display: block;
                            z-index: 10;
                            width: 11px;
                            height: 14px;
                            background: url(../svg/icon_panier.svg);
                            background-size: 100%;
                            display: block;
                            position: relative;
                            color: $ctaColor;
                            top: -3px;
                            left: 0px;
                        }
                    }
                }
            }
        }
    }

    .block_basket {
        width: 480px;
    }
}

#show_top_wish {

    #cart_list .product-link .cart_detail_box,
    #show_top_wish #cart_list .product-link .cart_detail_box {
        padding: 9px 5px;
    }
}

#show_top_cart,
#show_top_wish {
    .upselling {
        &.price {
            background-color: $backgroundColor;
            max-width: 360px;
            display: flex;
            width: 100%;
            margin: 1.1875rem auto 0 auto;
            order: 2;
        }
    }

    .no_product,
    .holder_scrollable {
        /* Push height */
        height: calc(100% - 159px);
    }

    .no_product,
    .holder_scrollable {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.813rem;
    }

    .no_product {
        text-align: left;
        font-family: $primaryFont;
        font-size: $fs_mid;
        line-height: 1.3125rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #cart_list {
        max-height: 30vh;
        height: 100%;

        .product-link {
            display: flex;
            align-items: flex-start;
            position: relative;

            &:not(:last-of-type) {
                margin-bottom: 0.6rem;
            }

            .cart_detail_box {
                width: 100%;
                padding: 9px 5px;
            }

            .cart_thumb_holder {
                margin-right: 0.95rem;
            }

            .remove_prod {
                position: absolute;
                top: -20px;
                right: 0;

                .bfr::before {
                    width: 8px;
                    height: 8px;
                }
            }

            .wrapper_price {
                display: flex;
                align-items: flex-end;

                .is_discounted {
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .cart_panier_total {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.25rem 0;

        .titre_tot {
            flex: fit-content;
            font-family: $ctaFont;
            font-size: $fs_mid;
            line-height: 1.0625rem;
            text-transform: lowercase;
            letter-spacing: 0.2em;
        }

        .pricetag {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 1.8125rem;
        }
    }

    .wrap_tot_panier {
        flex-grow: 1;

        .form_submit {
            &:not(:last-child) {
                margin-bottom: 1.2rem;
            }
        }
    }

    .holder_cart_push {
        img {
            max-width: 100%;
        }
    }
}

#show_top_cart #cart_list {
    height: 100%;

    .product-link {
        align-items: center;

        &:not(:last-of-type) {
            margin-bottom: 0.7rem;
        }

        .remove_prod {
            top: -8px;
            right: -5px;
        }
    }
}

#topLogin {
    .wrapper_menu_compte_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .account_name {
        font-family: $primaryFont;
        font-size: $fs_xlarge;
        font-style: italic;
        text-transform: capitalize;
        margin-bottom: 1.775rem;
        line-height: 4.5rem;
        text-align: left;
    }

    .wrapper_menu_compte {
        flex-grow: 1;
        padding-bottom: 1.388rem;
    }

    .item {
        font-family: $ctaFont;
        font-size: $fs_mid;
        letter-spacing: 0.2em;
        margin-bottom: 1.763rem;
        text-transform: lowercase;

        &.wishlist > a {
            display: flex;
            justify-content: space-between;

            .holder_wishNb {
                display: flex;
                align-items: center;
                font-family: $primaryFont;
                font-style: initial;
                font-size: $fs_min;
                text-transform: lowercase;

                #number_wishlist_top {
                    margin-top: 3px;
                }

                .wishFull {
                    margin-left: 0.5rem;
                }
            }
        }

        &.faq {
            display: none;
        }
    }

    .btn_container {
        position: relative;

        .button {
            width: 100%;
        }
    }
}

.switchLangWrapper {
    display: none !important;
}

/* For SAFARI on MAC */
.safari.mac {
    /* Ultra-wide > 1920px */
    @media screen and (min-width: 1921px) {
        #show_top_wish,
        #topLogin {
            /* Takes into account the extra white spaces on the sides WITHOUT a scroll bar */
            padding-right: calc((100vw - 1920px) / 2);
            width: calc(452px + ((100vw - 1920px) / 2));
        }

        #show_top_cart {
            /* Takes into account the extra white spaces on the sides WITHOUT a scroll bar */
            padding-right: calc((100vw - 1920px) / 2);
            width: calc(640px + ((100vw - 1920px) / 2));

            /* Without vertical swiper associated products */
            &:not(:has(>.basket_gondole)) {
                width: calc(480px + ((100vw - 1920px) / 2));
            }
        }
    }
}

/* BURGER MENU */
//AFFICHAGE DU MENU BURGER EN FAIBLE RESOLUTION
@media screen and(max-width: 1200px) {
    #site_head_wrap {
        .low_res {
            display: block !important;
        }

        #top_wrapper {
            .header {
                padding: 0;

                .container {
                    max-width: 100%;
                }

                .top_header_wrapper {
                    justify-content: space-between;
                    align-items: center;
                    min-height: 75px;

                    .bot_header_wrapper {
                        margin-top: 0;
                        min-height: 100vh;
                        position: absolute;
                        left: -50%;
                        top: 0;
                        max-width: 380px;
                        width: 100%;
                        background: $whiteColor;
                        opacity: 0;
                        z-index: -1;
                        margin-left: unset !important;
                        transform: translateX(0%);
                        transition: opacity, left ease-in-out 350ms;

                        &.is_open {
                            opacity: 1;
                            z-index: 1;
                            left: 0;
                        }

                        .main_menu_contents {
                            display: block !important;
                            width: 100% !important;
                            margin-left: inherit !important;

                            &.hover {
                                #menu_boutiques {
                                    .submenu_wrapper {
                                        display: none !important;
                                    }
                                }
                            }

                            .bloc_top_low_res {
                                display: block;

                                .top_content {
                                    padding: 23px 24px;
                                    display: flex;
                                    height: 75px;
                                    align-items: center;
                                    justify-content: flex-start;

                                    .burger_menu_close {
                                        display: block;
                                        background: url(../svg/icon_close_burger.svg);
                                        width: 22px;
                                        height: 22px;
                                        transform: scale(0.7);
                                        cursor: pointer;
                                        opacity: 0;
                                        transition: opacity ease-in-out 250ms;

                                        &.visible {
                                            opacity: 1;
                                        }
                                    }
                                }

                                ul {
                                    li {
                                        position: relative;
                                        display: flex;
                                        height: 64px;
                                        align-items: center;
                                        justify-content: flex-start;
                                        background-color: $ctaColor;
                                        border-bottom: 1px solid $whiteColor;

                                        a,
                                        div {
                                            font-family: $primaryFont;
                                            font-size: 1.4rem;
                                            line-height: 1.8125rem;
                                            color: $whiteColor;
                                            font-style: normal;
                                            font-weight: 400;
                                            padding-left: 64px;
                                            background-repeat: no-repeat;
                                            background-position: center center;
                                            width: 100%;

                                            &::first-letter {
                                                text-transform: capitalize;
                                            }

                                            &::before {
                                                content: '';
                                                position: absolute;
                                                top: 50%;
                                                transform: translateY(-63%);
                                                left: 18px;
                                            }

                                            &.home {
                                                &::before {
                                                    background-image: url(../svg/icon_burger_accueil.svg);
                                                    width: 22px;
                                                    height: 24px;
                                                }
                                            }

                                            &.account {
                                                &::before {
                                                    background-image: url(../svg/icon_burger_compte.svg);
                                                    width: 25px;
                                                    height: 25px;
                                                }
                                            }

                                            &.wishlist_low_res {
                                                &::before {
                                                    background-image: url(../svg/icon_burger_favorite.svg);
                                                    width: 28px;
                                                    height: 24px;
                                                }

                                                .cart {
                                                    padding-left: 0;
                                                }

                                                .cart_nbr_prod {
                                                    float: right;
                                                    padding-right: 20px;
                                                }
                                            }

                                            &.customer {
                                                &::before {
                                                    content: '';
                                                    background: url(../svg/icon_burger_service_client.svg);
                                                    width: 26px;
                                                    height: 26px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .container_menu_items {
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: unset;
                                width: 100%;
                                overflow: hidden;
                                position: relative;
                                min-height: 56vh;
                            }

                            .main_menu_itm {
                                height: auto !important;
                                border-bottom: 2px solid $backgroundColor;
                                position: relative;
                                justify-content: unset;
                                position: unset;

                                .entry {
                                    padding-bottom: unset !important;
                                    align-items: center;
                                    min-height: 64px;
                                    padding-left: 64px;
                                    height: 64px !important;

                                    &::after {
                                        content: '';
                                        background: url(../svg/arrow_next.svg) no-repeat center center;
                                        width: 10px;
                                        height: 21px;
                                        position: absolute;
                                        right: 26px;
                                        transform: scale(0.8);
                                        z-index: -1;
                                    }

                                    &.has_submenu {
                                        font-family: $ctaFont;
                                        font-style: normal;
                                        font-weight: 850;
                                        font-size: $fs_mid;
                                        line-height: 17px;
                                        opacity: 1;
                                        transition: opacity 0.2s linear;

                                        a {
                                            width: 100%;
                                            height: 100%;
                                            display: flex;
                                            align-items: center;
                                        }

                                        &:hover {
                                            opacity: 0.75;
                                        }

                                        &::after {
                                            content: '';
                                            background: url(../svg/arrow_next.svg) no-repeat center center;
                                            width: 10px;
                                            height: 21px;
                                            position: absolute;
                                            right: 26px;
                                            transform: scale(0.8);
                                        }
                                    }
                                }

                                &.next_menu .submenu_wrapper {
                                    left: 0;
                                    transform: translate(0);
                                }

                                .submenu_wrapper {
                                    width: 100%;
                                    z-index: 100;
                                    top: 0;
                                    left: 50%;
                                    transform: translate(50%);
                                    transition: transform 0.1s linear;
                                    display: block !important;
                                    height: 100%;
                                    overflow: unset;
                                    opacity: 0;
                                    background: $whiteColor;
                                    padding: 0;
                                    border: 0;
                                    min-height: 56vh;

                                    .retour_low_res {
                                        cursor: pointer;
                                        height: 64px;
                                        display: flex;
                                        align-items: center;
                                        padding-left: 44px;
                                        font-size: 17px;
                                        line-height: 20px;
                                        background-color: $backgroundColor;
                                        font-family: $primaryFont;
                                        color: $primaryColor;
                                        text-transform: capitalize;

                                        &:first-letter {
                                            text-transform: capitalize;
                                        }

                                        &::before {
                                            content: '';
                                            position: absolute;
                                            background: url(../svg/icon_back.svg) 0 0 no-repeat;
                                            width: 11px;
                                            height: 5px;
                                            display: inline-block;
                                            padding: 4px;
                                            left: 23px;
                                        }
                                    }

                                    .ss_menu_categ_wrapper {
                                        height: 100%;
                                        min-height: 56vh;

                                        .see_all {
                                            display: flex;
                                            justify-content: flex-start;
                                            align-items: center;
                                            column-gap: 0;
                                            height: 64px;
                                            width: 100%;
                                            padding-left: 20px;
                                            display: none !important;
                                        }

                                        .ss_cat {
                                            font-family: $ctaFont;
                                            font-style: normal;
                                            font-weight: 850;
                                            font-size: $fs_mid;
                                            line-height: 17px;
                                            opacity: 1;
                                            transition: opacity 0.2s linear;
                                            max-width: 100%;
                                            padding: 0;

                                            .ss_cat_item {
                                                position: relative;
                                                display: flex;
                                                justify-content: flex-start;
                                                align-items: center;
                                                column-gap: 0;
                                                height: 64px !important;
                                                background: $whiteColor;
                                                border-bottom: 2px solid $backgroundColor;
                                                width: 100%;
                                                margin-bottom: 0;

                                                &::after {
                                                    content: '';
                                                    background: url(../svg/arrow_next.svg) no-repeat center center;
                                                    width: 10px;
                                                    height: 21px;
                                                    position: absolute;
                                                    right: 26px;
                                                    transform: scale(0.8);
                                                }

                                                .push_sscateg {
                                                    display: none !important;
                                                }

                                                .ss_cat_name {
                                                    width: 100%;
                                                    padding-left: 64px;
                                                    height: 64px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: flex-start;
                                                    flex-direction: revert;

                                                    span {
                                                        min-width: auto;
                                                        max-width: 100%;
                                                        white-space: nowrap;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        width: 270px;
                                                        display: block;
                                                    }

                                                    .visuel {
                                                        img {
                                                            display: none;
                                                        }
            
                                                        + span {
                                                            position: relative;
                                                            top: 50%;
                                                            text-align: left;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .ss_cat.menu_ss_cat_list {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;

                                        .ss_cat_item {
                                            .ss_cat_name {
                                                display: block;
                                            }
                                        }                                                                                 
                                    }
                                }
                            }
                        }

                        .switchLangWrapper {
                            position: absolute;
                            bottom: 20px;
                            left: 24px;
                            width: 85%;
                            background: $whiteColor;
                            padding: 4px;
                            z-index: 100;
                            display: block !important;

                            div {
                                &.lang_selected {
                                    background: $whiteColor;
                                    padding: 1.1rem 1.5rem;
                                    width: 100%;
                                    background-color: transparent;
                                    position: relative;
                                    color: $primaryColor;
                                    font-weight: 400;
                                    font-size: 18px;
                                    border: 1px solid $ctaColor;
                                    height: 50px;
                                    font-family: $primaryFont;
                                    outline: 0;
                                    display: block;
                                    cursor: pointer;
                                    text-transform: capitalize;                                 
                                }
                            }

                            ul {
                                position: absolute;
                                bottom: 55px;
                                width: 97.4%;
                                background-color: $whiteColor;
                                border: 1px solid rgba(0, 0, 0, 0.4);
                                z-index: -1;
                                display: none;

                                li {
                                    background-color: transparent;
                                    position: relative;
                                    color: $primaryColor;
                                    font-weight: 400;
                                    font-size: 18px;
                                    height: 50px;
                                    font-family: $primaryFont;
                                    line-height: 1;
                                    outline: 0;
                                    padding: 15px;

                                    a {
                                        span {
                                            text-transform: capitalize;
                                        }
                                    }
                                }

                                &.show {
                                    display: block;
                                    z-index: 100;
                                }
                            }
                        }
                    }

                    .block_top_links {
                        &.left {
                            .wrap_lang {
                                display: none;
                            }

                            .customer_service {
                                display: none;
                            }
                        }

                        &.right {
                            column-gap: 11px;
                            height: 75px;
                            align-items: center;
                        }

                        .block_top_item {
                            &.search {
                                display: flex;
                                border: 0;
                                max-height: 100%;
                                margin-bottom: 17px;

                                #trigger_recherche .icon_search {
                                    top: -1px;

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        height: 1px;
                                        width: 10px;
                                        background: $ctaColor;
                                        bottom: -2px;
                                        right: 0;
                                        opacity: 0;
                                    }

                                    &:hover::after {
                                        opacity: 1;
                                    }
                                }

                                .wrap_search_field {
                                    padding: 3vh 3vw;
                                    top: 100%;
                                }
                            }

                            &.my_account {
                                margin-left: 18px;
                                align-items: center;
                                margin-bottom: 17px;

                                .icon {
                                    background: url(../svg/icon_account.svg);
                                    width: 15px;
                                    height: 17px;
                                    position: relative;
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    top: -1px;
                                    left: 3px;

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        height: 1px;
                                        width: 14px;
                                        background: $ctaColor;
                                        bottom: -4px;
                                        right: 0;
                                        opacity: 0;
                                    }

                                    &:hover::after {
                                        opacity: 1;
                                    }

                                    span {
                                        text-indent: -10000px;
                                        display: block;
                                    }
                                }
                            }

                            &.wishlist {
                                align-items: center;
                                margin-bottom: 17px;
                            }

                            &.my_basket {
                                align-items: center;
                                margin-bottom: 17px;
                                height: 91px;

                                .cart {
                                    top: -2px;
                                }
                            }
                        }
                    }
                }
            }
        }

        // BURGER OPEN
        #top_wrapper .header {

            //ICONE BURGER
            .burger_menu_container {
                display: flex;
                height: 22px;
                cursor: pointer;

                .burger_menu {
                    position: relative;
                    width: 32px;
                    height: 2px;
                    margin-top: 8px;
                    background-color: $ctaColor;

                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 32px;
                        height: 2px;
                        background-color: $ctaColor;
                        transition: all 0.2s ease-out;
                    }

                    &::before {
                        top: 10px;
                    }

                    &::after {
                        bottom: 10px;
                    }

                    &.is_clicked {
                        margin-top: 0;
                        background-color: transparent;
                        transform: scale(0.7);

                        &::before {
                            transform: rotate(45deg);
                        }

                        &::after {
                            bottom: -10px;
                            transform: rotate(-50deg);
                        }
                    }
                }
            }
        }

        &.sticky {
            left: inherit;
            transform: inherit;

            .header {
                padding: 0 !important;

                .container {
                    max-width: 100%;
                }
            }

            #top_wrapper .header .top_header_wrapper {
                justify-content: space-between;
                min-height: inherit;
                max-height: 75px;

                .block_top_links {
                    width: auto;

                    &.left {
                        width: 100%;

                        .smallLogo a {
                            display: none !important;
                        }
                    }

                    &.logo {
                        display: block !important;
                        height: auto;
                    }

                    &.right {
                        width: 100%;
                        column-gap: 20px;
                        justify-content: flex-end;
                        height: 75px;
                    }

                    .block_top_item {
                        &.search {
                            #trigger_recherche .icon_search {
                                top: -1px;
                                right: -9px;
                            }
                        }

                        &.my_account {
                            min-width: inherit !important;

                            .icon {
                                left: 0px;
                                top: -1px;
                            }
                        }

                        &.wishlist {
                            min-width: inherit;

                            .cart {
                                left: -1px;
                                top: -2px;
                            }
                        }

                        &.my_basket {
                            .cart {
                                left: -10px;
                                top: -2px;
                            }
                        }
                    }
                }
            }
        }
    }

    #show_top_cart #cart_list {
        max-height: 23vh;
        height: 100%;

        .product-link {
            align-items: center;

            &:not(:last-of-type) {
                margin-bottom: 0.7rem;
            }

            .remove_prod {
                top: -8px;
                right: -5px;
            }
        }
    }
}

/* Portrait Paysage IPAD 7 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
    #site_head_wrap {
        .low_res {
            display: block !important;
        }

        #top_wrapper {
            .header {
                padding: 0;

                .container {
                    max-width: 100%;
                }

                .top_header_wrapper {
                    justify-content: space-between;
                    align-items: center;
                    min-height: 75px;

                    .bot_header_wrapper {
                        margin-top: 0;
                        min-height: 100vh;
                        position: absolute;
                        left: -50%;
                        top: 0;
                        max-width: 380px;
                        width: 100%;
                        background: $whiteColor;
                        opacity: 0;
                        z-index: -1;
                        margin-left: unset !important;
                        transition: opacity, left ease-in-out 350ms;

                        &.is_open {
                            opacity: 1;
                            z-index: 1;
                            left: 0;
                        }

                        .main_menu_contents {
                            display: block !important;
                            width: 100% !important;

                            &.hover {
                                #menu_boutiques {
                                    .submenu_wrapper {
                                        display: none !important;
                                    }
                                }
                            }

                            .bloc_top_low_res {
                                display: block;

                                .top_content {
                                    padding: 23px 24px;
                                    display: flex;
                                    height: 75px;
                                    align-items: center;
                                    justify-content: flex-start;

                                    .burger_menu_close {
                                        display: block;
                                        background: url(../svg/icon_close_burger.svg);
                                        width: 22px;
                                        height: 22px;
                                        transform: scale(0.7);
                                        cursor: pointer;
                                        opacity: 0;
                                        transition: opacity ease-in-out 250ms;

                                        &.visible {
                                            opacity: 1;
                                        }
                                    }
                                }

                                ul {
                                    li {
                                        position: relative;
                                        display: flex;
                                        height: 64px;
                                        align-items: center;
                                        justify-content: flex-start;
                                        background-color: $ctaColor;
                                        border-bottom: 1px solid $whiteColor;

                                        a,
                                        div {
                                            font-family: $primaryFont;
                                            font-size: 1.4rem;
                                            line-height: 1.8125rem;
                                            color: $whiteColor;
                                            font-style: normal;
                                            font-weight: 400;
                                            padding-left: 64px;
                                            background-repeat: no-repeat;
                                            background-position: center center;
                                            width: 100%;

                                            &::first-letter {
                                                text-transform: capitalize;
                                            }

                                            &::before {
                                                content: '';
                                                position: absolute;
                                                top: 50%;
                                                transform: translateY(-63%);
                                                left: 18px;
                                            }

                                            &.home {
                                                &::before {
                                                    background-image: url(../svg/icon_burger_accueil.svg);
                                                    width: 22px;
                                                    height: 24px;
                                                }
                                            }

                                            &.account {
                                                &::before {
                                                    background-image: url(../svg/icon_burger_compte.svg);
                                                    width: 25px;
                                                    height: 25px;
                                                }
                                            }

                                            &.wishlist_low_res {
                                                &::before {
                                                    background-image: url(../svg/icon_burger_favorite.svg);
                                                    width: 28px;
                                                    height: 24px;
                                                }

                                                .cart {
                                                    padding-left: 0;
                                                }

                                                .cart_nbr_prod {
                                                    float: right;
                                                    padding-right: 20px;
                                                }
                                            }

                                            &.customer {
                                                &::before {
                                                    content: '';
                                                    background: url(../svg/icon_burger_service_client.svg);
                                                    width: 26px;
                                                    height: 26px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .container_menu_items {
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: unset;
                                overflow: hidden;
                                position: relative;
                                min-height: 56vh;
                            }

                            .main_menu_itm {
                                height: auto !important;
                                border-bottom: 2px solid $backgroundColor;
                                position: relative;
                                justify-content: unset;
                                position: unset;

                                .entry {
                                    padding-bottom: unset !important;
                                    align-items: center;
                                    min-height: 64px;
                                    padding-left: 64px;
                                    height: 64px !important;

                                    &::after {
                                        content: '';
                                        background: url(../svg/arrow_next.svg) no-repeat center center;
                                        width: 10px;
                                        height: 21px;
                                        position: absolute;
                                        right: 26px;
                                        transform: scale(0.8);
                                        z-index: -1;
                                    }

                                    &.has_submenu {
                                        font-family: $ctaFont;
                                        font-style: normal;
                                        font-weight: 850;
                                        font-size: $fs_mid;
                                        line-height: 17px;
                                        opacity: 1;
                                        transition: opacity 0.2s linear;

                                        a {
                                            width: 100%;
                                            height: 100%;
                                            display: flex;
                                            align-items: center;
                                        }

                                        &:hover {
                                            opacity: 0.75;
                                        }

                                        &::after {
                                            content: '';
                                            background: url(../svg/arrow_next.svg) no-repeat center center;
                                            width: 10px;
                                            height: 21px;
                                            position: absolute;
                                            right: 26px;
                                            transform: scale(0.8);
                                        }
                                    }
                                }

                                &.next_menu .submenu_wrapper {
                                    left: 0;
                                    transform: translate(0);
                                }

                                .submenu_wrapper {
                                    width: 100%;
                                    z-index: 100;
                                    top: 0;
                                    left: 50%;
                                    transform: translate(50%);
                                    transition: transform 0.1s linear;
                                    display: block !important;
                                    height: 100%;
                                    overflow: unset;
                                    opacity: 0;
                                    background: $whiteColor;
                                    padding: 0;
                                    border: 0;
                                    min-height: 56vh;

                                    .retour_low_res {
                                        cursor: pointer;
                                        height: 64px;
                                        display: flex;
                                        align-items: center;
                                        padding-left: 44px;
                                        font-size: 17px;
                                        line-height: 20px;
                                        background-color: $backgroundColor;
                                        font-family: $primaryFont;
                                        color: $primaryColor;
                                        text-transform: capitalize;

                                        &:first-letter {
                                            text-transform: capitalize;
                                        }

                                        &::before {
                                            content: '';
                                            position: absolute;
                                            background: url(../svg/icon_back.svg) 0 0 no-repeat;
                                            width: 11px;
                                            height: 5px;
                                            display: inline-block;
                                            padding: 4px;
                                            left: 23px;
                                        }
                                    }

                                    .ss_menu_categ_wrapper {
                                        height: 100%;
                                        min-height: 56vh;

                                        .see_all {
                                            display: flex;
                                            justify-content: flex-start;
                                            align-items: center;
                                            column-gap: 0;
                                            height: 64px;
                                            width: 100%;
                                            padding-left: 20px;
                                            display: none !important;
                                        }

                                        .ss_cat {
                                            font-family: $ctaFont;
                                            font-style: normal;
                                            font-weight: 850;
                                            font-size: $fs_mid;
                                            line-height: 17px;
                                            opacity: 1;
                                            transition: opacity 0.2s linear;
                                            max-width: 100%;
                                            padding: 0;

                                            .ss_cat_item {
                                                position: relative;
                                                display: flex;
                                                justify-content: flex-start;
                                                align-items: center;
                                                column-gap: 0;
                                                height: 64px !important;
                                                background: $whiteColor;
                                                border-bottom: 2px solid $backgroundColor;
                                                width: 100%;
                                                margin-bottom: 0;

                                                &::after {
                                                    content: '';
                                                    background: url(../svg/arrow_next.svg) no-repeat center center;
                                                    width: 10px;
                                                    height: 21px;
                                                    position: absolute;
                                                    right: 26px;
                                                    transform: scale(0.8);
                                                }

                                                .push_sscateg {
                                                    display: none !important;
                                                }

                                                .ss_cat_name {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: flex-start;
                                                    flex-direction: revert;
                                                    width: 100%;
                                                    padding-left: 64px;
                                                    height: 64px;

                                                    span {
                                                        display: block;
                                                        min-width: auto;
                                                        max-width: 100%;
                                                        width: 270px;
                                                        white-space: nowrap;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        text-align: left;
                                                    }

                                                    .visuel {
                                                        img {
                                                            display: none;
                                                        }
            
                                                        + span {
                                                            position: relative;
                                                            top: 40%;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .ss_cat.menu_ss_cat_list {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;

                                        .ss_cat_item {
                                            .ss_cat_name {
                                                display: block;
                                            }
                                        }                                        
                                    }
                                }
                            }
                        }

                        .switchLangWrapper {
                            position: absolute;
                            bottom: 20px;
                            left: 24px;
                            width: 85%;
                            background: $whiteColor;

                            select {
                                padding: 1rem;
                                width: 100%;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;

                                &.menu_lang_switch_mob {
                                    background-color: transparent;
                                    position: relative;
                                    color: $primaryColor;
                                    font-weight: 400;
                                    font-size: 18px;
                                    border: 1px solid $ctaColor;
                                    font-family: $primaryFont;
                                    line-height: 1;
                                    outline: 0;
                                }
                            }

                            &::after {
                                content: '';
                                width: 15px;
                                height: 7px;
                                display: inline-block;
                                background: url(../svg/arrow_select.svg) no-repeat center right;
                                background-size: 15px 7px;
                                position: absolute;
                                right: 20px;
                                transform: translateY(-50%);
                                top: 50%;
                            }
                        }
                    }

                    .block_top_links {
                        &.left {
                            .wrap_lang {
                                display: none;
                            }

                            .customer_service {
                                display: none;
                            }
                        }

                        &.right {
                            column-gap: 11px;
                            height: 75px;
                            align-items: center;
                        }

                        .block_top_item {
                            &.search {
                                display: flex;
                                border: 0;
                                max-height: 100%;
                                margin-bottom: 17px;

                                #trigger_recherche .icon_search {
                                    top: -1px;

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        height: 1px;
                                        width: 10px;
                                        background: $ctaColor;
                                        bottom: -2px;
                                        right: 0;
                                        opacity: 0;
                                    }

                                    &:hover::after {
                                        opacity: 1;
                                    }
                                }

                                .wrap_search_field {
                                    padding: 5vh 3vw;
                                }
                            }

                            &.my_account {
                                margin-left: 18px;
                                align-items: center;
                                margin-bottom: 17px;

                                .icon {
                                    background: url(../svg/icon_account.svg);
                                    background-repeat: no-repeat;
                                    width: 14px;
                                    height: 14px;
                                    position: relative;
                                    top: -1px;
                                    left: 3px;

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        height: 1px;
                                        width: 14px;
                                        background: $ctaColor;
                                        bottom: -4px;
                                        right: 0;
                                        opacity: 0;
                                    }

                                    &:hover::after {
                                        opacity: 1;
                                    }

                                    span {
                                        text-indent: -10000px;
                                        display: block;
                                    }
                                }
                            }

                            &.wishlist {
                                align-items: center;
                                margin-bottom: 17px;
                            }

                            &.my_basket {
                                align-items: center;
                                margin-bottom: 17px;
                                height: 91px;

                                .cart {
                                    top: -2px;
                                }
                            }
                        }
                    }
                }
            }
        }

        // BURGER OPEN
        #top_wrapper .header {

            //ICONE BURGER
            .burger_menu_container {
                display: flex;
                height: 22px;
                cursor: pointer;

                .burger_menu {
                    position: relative;
                    width: 32px;
                    height: 2px;
                    margin-top: 8px;
                    background-color: $ctaColor;
                    margin-left: 10px;

                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 32px;
                        height: 2px;
                        background-color: $ctaColor;
                        transition: all 0.2s ease-out;
                    }

                    &::before {
                        top: 10px;
                    }

                    &::after {
                        bottom: 10px;
                    }

                    &.is_clicked {
                        margin-top: 0;
                        background-color: transparent;
                        transform: scale(0.7);

                        &::before {
                            transform: rotate(45deg);
                        }

                        &::after {
                            bottom: -10px;
                            transform: rotate(-50deg);
                        }
                    }
                }
            }
        }

        &.sticky {
            left: inherit;
            transform: inherit;

            .header {
                padding: 0 !important;

                .container {
                    max-width: 100%;
                }
            }

            #top_wrapper .header .top_header_wrapper {
                justify-content: space-between;
                min-height: inherit;
                max-height: 75px;

                .block_top_links {
                    width: auto;

                    &.left {
                        width: 100%;

                        .smallLogo a {
                            display: none !important;
                        }
                    }

                    &.logo {
                        display: block !important;
                        height: auto;
                    }

                    &.right {
                        width: 100%;
                        column-gap: 20px;
                        gap: 20px;
                        justify-content: flex-end;
                        height: 75px;
                    }

                    .block_top_item {
                        min-width: 40px !important;

                        &.search {
                            min-width: 14px !important;

                            #trigger_recherche .icon_search {
                                top: -1px;
                                right: -9px;
                            }
                        }

                        &.my_account {
                            min-width: 16px !important;

                            .icon {
                                left: 0px;
                                top: -1px;
                            }
                        }

                        &.wishlist {
                            min-width: 30px !important;

                            .cart {
                                left: -1px;
                                top: -2px;
                            }
                        }

                        &.my_basket {
                            .cart {
                                left: -10px;
                                top: -2px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and(max-height: 800px) {
    #show_top_cart {
        height: 90vh;

        #cart_list {
            max-height: 30vh;
            min-height: 25vh;
            height: 100%;

            .product-link {
                align-items: center;

                &:not(:last-of-type) {
                    margin-bottom: 0.7rem;
                }

                .remove_prod {
                    top: -8px;
                    right: -5px;
                }
            }
        }
    }
}

.product_page #show_top_cart {
    .wrap_title {
        margin-left: 3.125rem;
    }

    .rollover_title .wrap_title {
        margin-left: 0;
    }
}

.lang_switch_flag {
    width: 18px;
    height: 12px;
}