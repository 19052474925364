body.store_locator,
body.satellite_store_details {
    overflow-x: hidden;
}

body.store_locator {
    .main_wrapper {
        padding-bottom: 50px;
    }

    h1 {
        margin-bottom: 3.125rem;
    }

    .phone_store {
        display: none;
    }
}

body.satellite_store_details {
    .main_wrapper {
        padding-bottom: 7px;
    }
}

body.store_locator #site_global_wrap,
body.satellite_store_details #site_global_wrap {
    h1 {
        display: block;
        text-align: center;
        font-family: $primaryFont;
        font-size: $fs_xlarge;
        font-style: italic;
        text-transform: capitalize;
        line-height: 4.5rem;
    }

    input[type='text']:focus {
        box-shadow: 0 0 1px $ctaColor;
        border: 2px solid $ctaColor;
    }

    .w-input:focus,
    .w-dropdown:focus {
        box-shadow: 0 0 1px $ctaColor;
        border: 2px solid $ctaColor;
    }

    .w-dropdown.w-focused {
        box-shadow: 0 0 1px $ctaColor;
        border: 2px solid $ctaColor;
    }

    .os-scrollbar-vertical {
        right: -4px;
    }

    .storeloc_form .field_country .w-input-label {
        font-size: 12px;
        left: 0.775rem;
        top: 25px;
    }
}

.wrapper_store {
    padding-bottom: 4.25rem;
}

.store_locator_page,
.store_details_page {
    width: 98%;
    max-width: $wrappermidMedium;
    margin: 0 auto;

    @include smallScreen {
        max-width: $wrapperXSmall;
    }
}

.store_locator_page {
    width: 100%;
    max-width: $wrappermidMedium;
    margin: 0 auto;

    .locator_heading {
        width: 100%;
    }

    .storeloc_titles {
        text-align: center;
    }

    .subtitle {
        display: none;
    }

    .resultat_recherche {
        margin: 2rem 0;
        display: flex;
        justify-content: center;
        font-family: $primaryFont;
        font-style: italic;
        font-size: $fs_txt;
        display: none !important;
    }
}

.storeloc_form {
    .ou {
        font-family: $ctaFont;
        text-transform: lowercase;
        font-size: $fs_mid;
        line-height: 1.0625rem;
        letter-spacing: 0.2em;
        position: relative;
        top: -3px;
        left: -2px;
    }

    .loc_input {    
        width: calc(100% - 2.6rem);
        height: 46px;
        padding: 0 1rem;
        font-size: 14px;
        line-height: 21px;
        color: #868686;
        position: relative;
        top: -1px;
    }

    .wrap_fields {
        height: 50px;
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }

    .loc_input,
    .field_country .w-dropdown {
        border: 1px solid $borderGrey;
        outline: 0;
    }

    .field_country {
        width: 173px;
        height: 100%;

        .w-dropdown {
            &::after {
                border: 0;
                width: 16px;
                height: 16px;
                background-image: url(../svg/arrowBottom.svg);
                right: 0.8125rem;
            }
        }

        .w-input-label {
            font-size: 14px;
            line-height: 21px;
        }

        #search_pays {

            option,
            + .w-input-label {
                font-size: 14px !important;
                font-family: Arial, Helvetica, sans-serif;
                line-height: 21px;
                color: $placeholder;
            }
        }
    }

    .field_address {
        position: relative;
        flex-grow: 1;
        margin-left: 5px;

        .icon_search {
            display: none;
        }
    }

    .loc_btn {
        width: fit-content;
        cursor: pointer;
        padding: 16px 38.9px;
        margin-bottom: 0.725rem;
        margin-top: 0.525rem;

        span {
            position: relative;
            top: -1px;
            left: 1px;
        }
    }
}

.wrapper_retailers_map {
    display: flex;
    justify-content: space-between;
    column-gap: 1.5rem;
    margin-top: 2.5313rem;

    .map {
        width: 56% !important;
        height: 550px;
    }

    .liste_distributeur {
        width: 44.2%;
        height: 550px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 2rem;
        row-gap: 2rem;
        max-width: 554.05px;
        overflow: auto;
        scrollbar-color: $ctaColor $lightGrey;
        scrollbar-width: thin;

        .elem_liste_distributeur {
            width: calc(50% - 1.8rem);

            @include smallScreen {
                width: calc(100% - 2rem);
            }
        }

        .elem_list_contents {
            height: 100%;
            padding: 0 0.063rem 2.5rem 0;
        }

        .storePic {
            display: none;
        }
    }

    .info_popup,
    .infos_block {
        .name {
            font-family: $primaryFont;
            font-weight: 400;
            font-size: $fs_subtitle;
            line-height: 1.8125rem;
        }

        .ville {
            color: $ctaColor;
        }

        .address {
            font-family: $primaryFont;
            font-size: $fs_txt;
            font-weight: 400;
            line-height: 1.125rem;
        }
    }

    .store_more a,
    .cta_row a {
        text-decoration: underline;
        text-transform: lowercase;
        font-style: normal;
        font-weight: 400;
        font-size: $fs_txt;
        line-height: 1.125rem;
    }

    .info_popup .cta_row,
    .infos_block .store_more {
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 400;
        font-size: $fs_txt;
        line-height: 1.125rem;
        position: relative;
    }

    .info_popup .cta_row {
        display: flex;
        column-gap: 1rem;
    }

    .infos_block {
        display: flex;
        flex-direction: column;
    }
}

/***** Store details *****/
.storedetails_titles .title {
    text-align: center;
    font-family: $primaryFont;
    font-style: italic;
    font-size: $fs_xlarge;
    line-height: 72px;

    span {
        color: $ctaColor;
    }
}

.wrapper_retailers_map_details {
    display: flex;
    justify-content: space-between;
    column-gap: 10%;
    padding-top: 3.125rem;

    .map {
        width: 68.6%;
        max-width: 878px;
        height: 536px;
    }

    .storeDetails {
        width: 21.5%;
        display: flex;
        flex-direction: column;

        /* Portrait */
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
            width: 28%;
        }
    }

    .btn_return {
        justify-content: flex-start;
        font-style: normal;
        font-size: $fs_txt;
        line-height: 1.125rem;
        font-family: $primaryFont;
        padding-left: 1.0625rem;
        position: relative;

        &::before {
            background-image: url(../svg/arrow_left.svg);
            position: absolute;
            left: 0px;
            content: '';
            width: 5px;
            height: 11px;
            top: 1.5px;
        }
    }

    .storeInfos {
        padding: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-family: $primaryFont;
        font-style: normal;
        font-size: $fs_txt;
        line-height: 1.125rem;
    }

    .storeTitle,
    .storeAddress,
    .storeSchedule {
        width: 100%;
    }

    .storeTitle,
    .storeHours .label {
        font-family: $primaryFont;
        font-style: normal;

        span {
            color: $ctaColor;
        }
    }

    .storeAddress {
        line-height: 1.3;
    }

    .storeTitle {
        margin-top: 2.8125rem;
        font-size: $fs_subtitle;
        line-height: 1.8125rem;
        margin-bottom: 0;
        text-align: left;
    }

    .storeHours {
        width: 100%;
        margin-top: 1.25rem;

        .label {
            font-style: normal;
            font-weight: 400;
            font-size: $fs_subtitle;
            line-height: 1.8125rem;
            font-family: $primaryFont;
        }

        .time_table {
            font-size: $fs_txt;
            margin-bottom: 1.575rem;

            li {
                display: flex;
                justify-content: space-between;
                line-height: 1.125rem;
                width: 100%;
                font-style: normal;
                font-weight: 400;
                font-size: $fs_txt;
                line-height: 1.2;
                font-family: $primaryFont;

                &.current,
                &.current .day,
                &.current .time {
                    color: $ctaColor;
                }
            }

            @include mediumScreen {
                li {
                    width: 100%;
                }
            }
        }
    }

    .storeActions {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        row-gap: 0.9375rem;

        .button+.button {
            background-color: $ctaColor;
            color: white;
            padding: 2.4em 0;

            &:hover {
                background-color: white;
                color: $ctaColor;
            }
        }

        #go_to_store{
            margin-top: 12px;
            padding: 24px 0;
            height: inherit;
        }
    }

    .favorite_store_button {
        text-transform: lowercase;
        text-decoration: underline;
        margin-top: 1em;
    }
}

.storeImg {
    display: block;
    margin: 40px auto;
    max-width: $wrappermidMedium;

    img {
        width: 100%;
        object-fit: cover;
    }
}

.horaire_today {
    position: relative;
    padding-left: 1rem;
    font-family: $primaryFont;
    font-size: $fs_txt;
    line-height: 1.125rem;

    .txt_store_open,
    .txt_store_close {
        font-family: $primaryFont;
        font-style: normal;
        text-transform: capitalize;
    }

    .txt_store_close {
        display: block;
    }

    .txt_store_open {
        color: $green;
    }

    .txt_store_close {
        color: $red;
    }

    .marker_storeloc {
        display: block;
        position: absolute;
        top: 3px;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;

        &.store_open {
            background-color: $green;
        }

        &.store_close {
            background-color: $red;
        }
    }
}

.gm-style .gm-style-iw-c {
    padding: 2rem !important;
    border-radius: 0;
}

.gm-style .gm-style-iw-d {
    max-height: unset !important;
    overflow: unset !important;
}

.gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
}

.w-sendshop-form {

    .w-input-element:not([type='radio']):not([type='checkbox']),
    input[type='email'].w-input-element {
        padding: 0 15px 0 15px;
    }

    .w-nonempty:not(.no-label) .w-input-element:not([type='radio']):not([type='checkbox']),
    .w-nonempty:not(.no-label) input[type='email'].w-input-element {
        padding-top: 1.15em;
        padding-left: 1.15em;
        width: 92%;
        height: 2.529em;
    }

    .w-form-line {
        font-family: $primaryFont;
        font-size: $fs_mid;
    }

    .w-input-element:not([type='radio']):not([type='checkbox']),
    input[type='email'].w-input-element {
        width: 92%;
        height: 2.529em;
    }
}
