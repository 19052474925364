#popup_savoir_plus {
    width: 750px;
}

.lightbox {
    display: none;
    z-index: 101;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $whiteColor;
    padding: 2rem;
    font-family: $secondaryFont;

    .close_pop {
        cursor: pointer;
    }

    &#abox {
        z-index: 120;
        width: 535px;
        padding: 70px 80px;

        .close_pop {
            background: url('../svg/icon_close.svg');
            width: 12px;
            height: 12px;
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;

            span {
                font-size: 0;
            }
        }

        .header_lightbox {
            .light_title {
                text-transform: uppercase;
                font-size: 50px;
                font-weight: 400;
                line-height: 48px;
                color: $primaryColor;
                margin-bottom: 12px;

                & + p {
                    font-size: 13px;
                    color: $secondaryColor;
                    line-height: 20px;
                    font-weight: 400;
                    padding-right: 70px;
                    margin-bottom: 30px;

                    span {
                        display: block;
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                }
            }

            .txt_alert {
                text-align: center;
            }

            .box_wishlist, .box_favorite_store {
                text-align: left;
            }
        }

        &.actif {
            display: block !important;
        }
    }

    &.actif {
        display: block !important;
        &#popup_dispatch {
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .title {
        font-size: $fs_large;
        font-weight: 400;
        font-style: italic;
        font-family: $primaryFont;
        margin-bottom: 2rem;
        line-height: 45px;
    }

    &.popup_numero_retour {
        padding: 2rem 5rem;
        padding-right: 4rem;
        max-height: 90vh;
        width: 695px;

        .holder_form {
            max-height: 70vh;

            .os-scrollbar-horizontal {
                display: none;
            }
        }

        .return_products_form {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .holder_return_product {
                max-width: 49%;
                width: 100%;
                padding-bottom: $min_pad;
            }

            .divBtnPrintPdf {
                display: flex;
                justify-content: center;
            }
        }

        .texte_num_retour {
            font-size: 2.65rem;
            text-align: center;
            margin-bottom: 0.5rem;
            font-style: italic;
        }

        .recommandation {
            text-align: center;
            margin-bottom: 1.5rem;
            font-style: italic;

            p {
                line-height: 1.25;
            }
        }

        .productReturnListWrapper {
            display: flex;
            align-items: center;

            .formWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                font-family: $primaryFont;
                padding-left: 1rem;
                height: 11rem;

                .holder_subs {
                    .sub {
                        width: 6rem;
                        justify-content: space-between;
                    }

                    .sub_couleur {
                        .color_bullet {
                            width: 15px;
                            height: 15px;
                            box-shadow: inset 0 0 0 1px $whiteColor;
                        }
                    }
                }

                .selectWrapper {
                    display: flex;
                    margin-top: 0.75rem;
                }
            }

            .imgWrapper {
                img {
                    display: block;
                    width: auto;
                    height: 11rem;
                    background-color: $whiteColor;
                }
            }
        }

        .qty_dropdown,
        .motif_dropdown {
            display: flex;
            align-items: center;
            padding-right: 1rem;

            .date_select {
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    position: absolute;
                    top: 35%;
                    right: 20%;
                    pointer-events: none;
                }

                select {
                    padding: 1rem;
                    border-radius: 0;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
                }

                .return_quantity {
                    padding-right: 3.25rem;
                }

                .return_choice {
                    width: 19rem;
                    padding-right: 2rem;
                    text-overflow: ellipsis;
                }
            }
        }

        .motif_dropdown {
            .date_select {
                &::after {
                    top: 35%;
                    right: 6%;
                }
            }
        }

        .drop_label {
            font-family: $primaryFont;
            font-size: $fs_min;
            padding-right: 1rem;

            & + .date_select select {
                border: 1px solid $primaryColor;
                border-radius: 0;
                padding: 0.5rem;
                outline: 0;
            }
        }

        .btn_container.divBtnPrintPdf {
            width: 100%;
        }
    }

    &#e-resaBox {
        background: $white;
        width: 800px;
        height: fit-content;
        transform: none;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        overflow-y: auto;
        z-index: 102;

        .close_pop {
            display: flex;
            align-items: center;
            justify-content: center;
            background: url('../svg/icon_close.svg');
            width: 12px;
            height: 12px;
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 0;
        }

        #content_e-resaBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .popup_title {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 8px;
            }

            .popup_desc {
                font-family: $primaryFont;
                font-size: 15.5px;
                line-height: 1.15;
                text-align: center;
                color: $primaryColor;
                font-weight: 400;
            }

            .steps_num {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 583px;
                margin: 1.7rem 0;

                .step_num {
                    font-family: $ctaFont;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 800;
                    letter-spacing: 2.8px;
                    color: $primaryColor;
                    text-transform: lowercase;
                    text-align: center;

                    &.actif {
                        color: $ctaColor;
                    }
                }
            }

            .step {
                display: none;
                width: 583px;
                margin: 0 auto;

                &.actif {
                    display: block;

                    .step_content {
                        opacity: 1;
                    }
                }

                .w-submit-button,
                .button {
                    width: 130px;
                    background-color: $ctaColor;
                    color: $whiteColor;
                    border-color: $ctaColor;
                    box-shadow: inset 0 0 0 2px $whiteColor;
            
                    &:hover {
                        background-color: $whiteColor;
                        color: $ctaColor;
                    }
            
                    &.loader {
                        width: 100%;
                        background-color: $whiteColor;
                    }
                }

                .store {
                    display: flex;
                    justify-content: space-between;
                    padding-right: 22px;

                    .store_info {
                        width: 100%;
                        max-width: 423px;
                        color: $primaryColor;

                        .distance_store {
                            display: none;
                        }

                        .name_store {
                            width: 90%;
                            font-family: $ctaFont;
                            font-size: 14px;
                            font-weight: 800;
                            line-height: 3;
                            text-transform: lowercase;
                            letter-spacing: 2.8px;
                            margin: 0 0 3px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .phone_store {
                            display: none;
                        }

                        .adresse_store {
                            display: flex;
                            font-family: $primaryFont;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 1.2;
                            margin-bottom: 4px;

                            .adresse::after {
                                content: "-";
                                margin: 0 4px;
                            }
                        }

                        .js_have_horaire_store {
                            display: none;
                            margin-top: 0.8em;
                            font-family: $ctaFont;
                            font-weight: 400;
                            font-size: 11px;
                            line-height: normal;
                            letter-spacing: 2.2px;
                            text-transform: lowercase;
                            text-decoration: none;
                            color: $ctaColor;
                            position: relative;

                            &.actif {
                                display: inline-block;
                            }

                            &:before {
                                content: ' ';
                                display: block;
                                background: $ctaColor;
                                width: 100%;
                                height: 1px;
                                position: absolute;
                                left: 0;
                                bottom: -4px;
                            }
                        }

                        .schedule_store {
                            font-family: $primaryFont;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: normal;
                            height: 0;
                            overflow: hidden;
                            margin-top: 12px;
                            color: $primaryColor;
                            transition: height 0.3s ease-in;

                            &.actif {
                                height: auto;
                            }

                            p:not(:last-of-type) {
                                margin-bottom: 6px;
                            }
                        }
                    }
                }

                .js_store_report {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    padding: 0 15px;
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 1rem;
                    text-align: center;
                }

                .w-input-container.country {
                    max-width: 150px;
                }

                .opening_until {
                    padding-left: 10px;
                    position: relative;
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: normal;

                    span.txt_store_open {
                        color: $green;

                        &::before {
                            height: 7px;
                            width: 7px;
                            border-radius: 50%;
                            background: $green;
                            position: absolute;
                            content: '';
                            bottom: 7px;
                            left: 0;
                        }
                    }

                    span.txt_store_closed {
                        color: red;
                        letter-spacing: 0.2rem;

                        &::before {
                            height: 7px;
                            width: 7px;
                            border-radius: 50%;
                            background: red;
                            position: absolute;
                            content: '';
                            top: 0.2rem;
                            left: 0;
                        }
                    }
                }

                .os-scrollbar.os-scrollbar-vertical {
                    margin-right: -4px;
                }

                .store_locator {
                    height: 299px;
                    width: 583px;
                    overflow-y: auto;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.3s ease-in;
                    scrollbar-color: $ctaColor $lightGrey;
                    scrollbar-width: thin;

                    &::-webkit-scrollbar {
                        width: 2px;               /* width of the entire scrollbar */
                    }
                        
                    &::-webkit-scrollbar-track {
                        background: $lightGrey;        /* color of the tracking area */
                        border-radius: 20px;       /* roundness of the scroll thumb */
                    }
                        
                    &::-webkit-scrollbar-thumb {
                        border-radius: 20px;       /* roundness of the scroll thumb */
                        border: 2px solid $ctaColor;  /* creates padding around scroll thumb */
                    }

                    &.loaded {
                        opacity: 1;
                        visibility: visible;
                    }

                    .form_submit {
                        width: fit-content;
                        padding-top: 25px;

                        .quantity_store .article_disponible {
                            display: none !important;
                        } 

                        .article_non_disponible {
                            font-family: $primaryFont;
                            font-size: 15px;
                            color: #ff6d6d;
                            text-align: center;
                        } 
                    }
                }

                
                &#step3 {
                    .w-form-line.form-eresa-contact {
                        z-index: 1;
                    }

                    .country-list {
                        scrollbar-color: $ctaColor $lightGrey;
                        scrollbar-width: thin;
                    }

                    #error_limit_quota small {
                        text-align: left;
                        color: #ff6d6d;
                        font-size: .7rem;
                        list-style: 1;
                    }

                    .w-submit {
                        justify-content: center;
                        width: 100%;
                        max-width: unset;
                    }
                    
                    .assistance {
                        font-family: $primaryFont;
                        font-size: 12px;
                        color: $primaryColor;

                        a {
                            color: $ctaColor;
                            text-decoration: underline;

                            &:hover {
                                color: $primaryColor;
                            }
                        }
                    }
                }

                .errormsgadd {
                    display: none;
                }

                .infos {
                    font-family: $primaryFont;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: normal;
                    color: $primaryColor;
                    margin-bottom: 20px;
                }

                .optin {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 0 !important;

                    label {
                        width: 100%;
                        font-family: $primaryFont;
                        font-size: 15px;
                        color: $primaryColor;
                    }

                    .checkbox-group {
                        display: flex;
                        width: 100%;
                        gap: 50px;
                        margin-top: 10px;

                        .w-form-line {
                            width: 167px;
                            justify-content: space-between;

                            & + .w-form-line {
                                width: 161px;
                            }
                        }

                        span {
                            display: flex;
                            font-family: 'arial';
                            font-size: 13px;
                            white-space: nowrap;
                        }
                    }
                }

                .utilisation_condition {
                    margin-bottom: 30px !important;
                    
                    .w-input-label::before {
                        border-radius: 0;
                    }
                    
                    span {
                        font-size: 13px;
                        
                        a {
                            text-decoration: underline;
                        }
                    } 
                } 

                &#step4 { 
                    width: 611px;

                    & > .form_submit {
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                    }

                    .adresse_store .adresse {
                        width: 100%;
                    }
                }

                .eresa_choices {
                    display: flex;
                    margin-bottom: 30px;

                    .eresa_prod {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        max-width: 355px;
                        height: 150px;
                        gap: 20px;
                        padding-right: 40px;
                        border-right: 1px solid $primaryColor;

                        img {
                            max-width: 120px;
                        }

                        .eresa_prod_detail {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 10px;
                            width: 100%;
                            max-width: 175px;

                            .prod_title {
                                font-family: $primaryFont;
                                font-size: 15px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            
                            .titre_taille,
                            .titre_couleur {
                                display: none;
                            }
                            
                            .item_price {
                                font-family: $primaryFont;
                                font-size: 15px;
                            }
                            
                            .titre_quantity {
                                display: flex !important;
                                width: 100%;
                                font-family: $primaryFont;
                                font-size: 15px;
                                text-transform: lowercase;
                            }
                        }
                    }

                    .eresa_store_choice {
                        display: flex;
                        align-items: center;
                        max-width: 255px;
                        height: 150px;
                        padding-left: 40px;

                        .store {
                            padding-right: 0;
                        }

                        .name_store {
                            width: 100%;
                        }

                        .form_submit {
                            display: none;
                        }

                        .adresse_store {
                            flex-wrap: wrap;

                            .adresse::after {
                                display: none;
                            }
                        }

                        .opening_until {
                            display: none;
                        }
                    }
                }

                .eresa_procedure_title {
                    font-family: $ctaFont;
                    font-size: 14px;
                    line-height: normal;
                    letter-spacing: 2.8px;
                    text-transform: lowercase;
                    color: $primaryColor;
                    margin-bottom: 7px;
                }

                .eresa_procedure_content {
                    font-family: $primaryFont;
                    font-size: 15px;
                    line-height: normal;
                    color: $primaryColor;
                    &:not(:last-of-type) {
                        margin-bottom: 14px;
                    }
                }
            }
        }
    }

    &#pdvBox {
        width: 100%;
        max-width: 754px;
        max-height: 75vh;
        z-index: 151;

        .field_country,
        .ou,
        .loc_btn,
        .img-loader-container,
        .section-list-store .search-result,
        .store_more,
        [data-is-favorite] {
            display: none;
        }

        .popup_title {
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .close_pop {
            display: flex;
            align-items: center;
            justify-content: center;
            background: url('../svg/icon_close.svg');
            width: 12px;
            height: 12px;
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
        }

        #content_pdvBox {
            max-height: calc(75vh - (45px + 2rem + (2rem *2))); // 45px = height of the title, 2rem = margin bottom of the title/padding of the lightbox
            display: flex;
            flex-direction: column;
        }

        .field_address {
            margin-left: 0;
            margin-right: 37px;
        }
        
        .section-list-store {
            margin-top: 30px;
            padding-right: 37px;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: $ctaColor $lightGrey;
        }

        .elem_liste_distributeur:not(:last-child) {
            border-bottom: 1px solid $ctaColor;
            margin-bottom: 20px;
        }

        .elem_list_contents {
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: auto 210px;
            grid-template-areas: "infos button";
            gap: 10px;
            align-items: center;

            

            .storeInfos {
                grid-area: infos;
                display: flex;
                gap: 4px;
                flex-direction: column;
                font-family: $primaryFont;
                font-size: 15px;
                color: $primaryColor;

                .name {
                    color: $primaryColor;
                    font-size: 24px;
                    font-weight: 400;

                    .ville {
                        color: $ctaColor;
                    }

                    & + span[class] {
                        display: none;
                    }
                }

                .address {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-weight: 400;
                }

                &>.stock_available {
                    position: relative;
                    padding-left: 1rem;

                    .stock_status {
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        position: absolute;
                        top: 1px;
                        left: 0;

                        &.stock_available {
                            background-color: $green;
                        }

                        &.stock_unavailable {
                            background-color: $red;
                        }

                        &.stock_limited {
                            background-color: $yellow;
                        }
                    }
                }

                .phone_store {
                    display: none;
                }

                .horaire_today {
                    margin-bottom: 14px;
                }
            }

            .favorite_store_button {
                grid-area: button;
                width: 210px;

                background-color: $ctaColor;
                color: $whiteColor;
            }
        }
    }
}

#sendwishlistbox {
    width: 600px;

    .popup_title {
        text-align: center;

        .title {
            font-size: 2.5rem;
            margin-bottom: 0.5rem;
        }
    }

    .subtitle {
        font-size: 1.125rem;
    }

    .subtitle,
    form .form_title {
        text-transform: uppercase;
        font-family: $primaryFont;
    }

    form {
        .form_title {
            font-size: $fs_txt;
            margin-bottom: 1rem;
        }

        .w-textarea textarea.w-input-element {
            padding: 0.875rem;
        }

        .w-textarea.w-nonempty:not(.no-label) textarea.w-input-element {
            padding-top: 1.64286em;
        }
    }

    #sendfriend_form_to {
        margin-bottom: 1.375rem;
    }

    #recaptcha {
        > div {
            margin: 0 auto;
        }
    }

    #sendfriend_form_buttons {
        margin: 1.5rem auto 0;
        width: auto;
        max-width: fit-content;
        justify-content: center;
    }

    .w-form-line.w-submit {
        margin: 0 auto;
    }

    #sendfriend_form_froms {
        .w-form-line {
            .w-input-container {
                .w-input {
                    &.w-checkbox-input {
                        .w-input-element {
                            & + .w-input-label {
                                span {
                                    color: $primaryColor;
                                    font-size: 13px;
                                    font-family: $primaryFont;
                                    
                                }

                                &:before {
                                    width: 11px;
                                    height: 11px;
                                    border-color: $borderColor;
                                }
                            }

                            &:checked {
                                & + .w-input-label {
                                    &::before {
                                        box-shadow: inset 0 0 0 1px $whiteColor;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#lightbox_achat_express {
    max-width: 809px;
    width: 100%;
    padding: 0;

    #addToWishlistButton[disabled], .btnAddBasket[disabled] {
        opacity: 0.6;
        cursor: default;
        pointer-events: none;
    }

    .close_pop {
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('../svg/icon_close.svg');
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .prod_top_wrapper {
        display: flex;
    }

    .breadcrumb {
        display: none;
    }

    /* Left side */
    #buyNowSwiper {
        margin-top: 1rem;

        .swiper-slide {
            width: 100%;
            padding-top: calc(250 / 300 * 100%);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
        }

        .img_visu {
            display: none;
        }

        .block_vid,
        .block_vid iframe {
            position: absolute;
            top: 0;
            left: 0;
        }

        .block_vid {
            width: 100%;
            height: 100%;
            z-index: 1;

            iframe {
                pointer-events: none;
                width: 101%;
                height: 101%;
                z-index: 0;
            }
        }
    }

    /* Right side */
    .product_info_wrapper {
        flex-grow: 1;
        padding: 3rem 2.1rem 3rem;
    }

    .stillImageProduct {
        display: none;
    }

    #breadcrumbs,
    #btn_retour_product {
        display: none;
    }

    .more_details, .to_tunnel {
        margin-top: 1.45rem;
        text-align: center;
        box-shadow: 0 0 0 2px white inset !important;
        background: $ctaColor;
        color: white !important;
        border: 1px solid $ctaColor !important;
    }
}

#popup_savoir_plus {
    text-align: center;
}

#ligthbox_send_store_information {
    width: 600px;

    .input_label,
    .w-sendshop-form p {
        margin-bottom: 1rem;
        font-family: $primaryFont;
        font-size: $fs_mid;
    }

    .close_pop {
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('../svg/icon_close.svg');
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .popup_title {
        font-size: 24px;
        font-weight: 400;
        font-family: $primaryFont;
        line-height: normal;
        margin-bottom: 1rem;
    }
}

.lightbox.box_wishlist, .lightbox.box_favorite_store {
    padding: 68px 74px 58px 73px;
    text-align: center;
    max-width: 500px;

    .close {
        position: absolute;
        right: 20px;
        top: 20px;
        background-image: url(../svg/icon_close.svg);
        width: 12px;
        height: 12px;
        cursor: pointer;
    }

    .light_title {
        font-size: $fs_large;
        text-align: center;
        font-style: italic;
        margin-bottom: 1.625rem;
        font-family: $primaryFont;

        & + p {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: $fs_mid;
            line-height: 18px;
            text-align: center;
        }
    }

    .btn_container {
        margin-top: 1.4375rem;
    }
}

/**
 * Overlay lightbox
 */
#exit_overlay_OVI {
    width: 775px;
    height: 450px;
    padding: 0;
    font-family: $primaryFont;
    color: $primaryColor;

    .close_pop {
        display: block;
        position: absolute;
        top: 19px;
        right: 19px;
        width: 14px;
        height: 14px;
        background: transparent url(../svg/icon_close.svg) no-repeat center;
        background-size: cover;
    }

    .lightbox-text-only {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }

    .wrapper_img img {
        vertical-align: top;
    }

    #formu_inscription_news {
        padding: 0 0 30px 40px;
    }

    .title_popup_inscription_home {
        font-size: 42px;
        font-style: italic;
        line-height: 1.2;
    }

    .pop_txt_listing {
        font-size: 15px;
        line-height: 1.1;
        width: 338px;
        margin: 0.4rem 0 1rem;
    }

    form {
        width: 316px;
    }

    .w-form-line {
        margin-bottom: 0.8rem;

        &.w-submit {
            max-width: 100%;
            margin: 0 !important;
        }
    }

    .w-input-element:not([type='radio']):not([type='checkbox']),
    input[type='email'].w-input-element {
        box-sizing: border-box;
        border-radius: 0;
    }

    .w-input-note.error {
        margin: -0.5rem 0 0 0;
    }

    button {
        width: 80%;
        border: 1px solid $ctaColor  !important;
        font-family: $ctaFont;
        font-size: 14px;
        text-transform: lowercase;
        letter-spacing: 0.2rem;
        color: $ctaColor  !important;
        line-height: 0.9;
    }

    .pop_cgv_listing {
        font-size: 13px;
        color: $primaryColor;
        line-height: 1.2;
        margin: 28px 0 0 0 !important;

        a {
            color: $primaryColor;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    #conf_inscription_news {
        padding: 0 0 140px 40px;
    }
}

#modboxpromo {
    #btn_cmd_valid {
        display: flex;
        margin: 2rem auto 0;
    }
}

#selection-offer {
    width: 800px;
    height: 550px;

    .close_pop {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 0.8rem;
        height: 0.8rem;
        background: transparent url(../svg/icon_close.svg) no-repeat center;
        background-size: cover;
        font-size: 0;
        cursor: pointer;
    }

    .selection-offer-header {
        p {
            display: none;

            &.selection-offer-title {
                display: block;
                font-family: $primaryFont;
                font-size: 42px;
                font-style: italic;
                font-weight: 400;
                margin: 0.5rem 0 0 0;
            }
        }
    }

    .selection-offer-content {
        margin: 1rem auto 0;
        padding: 0;
        height: 340px;

        .lb-v-scrollbar,
        .lb-v-scrollbar-slider {
            width: 3px !important;
        }
    }

    .priceOfferedProd {
        display: block !important;
    }

    #wrap_lion {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        height: 317px;

        p {
            text-align: center;

            &.titleOfferedProd {
                font-family: $ctaFont;
                font-size: 14px;
                font-weight: 400;
                text-transform: lowercase;
                line-height: 1.2;
                letter-spacing: 0.1rem;
            }

            &.priceOfferedProd {
                font-family: $primaryFont;
                font-size: 24px;
                margin-top: 1rem;

                .offeredLabel {
                    margin-right: 0.5rem;
                }

                .crossedPrice {
                    text-decoration: line-through;
                }
            }
        }

        .js-form-selection-offer {
            width: 49%;
            max-width: 270px;
            height: 317px;
            min-height: initial;
            margin: 13px 20px 0 0;

            &:before {
                background: $ctaColor;
            }
        }
    }

    #wrap_lion .js-form-selection-offer.actif:before,
    #wrap_lion .js-form-selection-offer:hover:before {
        border-color: $ctaColor;
    }

    #wrap_lion .js-form-selection-offer.actif,
    #wrap_lion .js-form-selection-offer:hover {
        border-color: $ctaColor;
    }
}

#lightbox_achat_express.c_both {
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $whiteColor;

    #ligne_qte {
        display: none;
    }
}

/* COOKIES */
#cookiesLightbox {
    font-family: $primaryFont  !important;
    font-size: $fs_txt  !important;
    padding: 39px 0 12px !important;

    &.testttt {
        display: block !important;
    }

    h3,
    .title {
        font-family: $primaryFont  !important;
        font-style: italic;
        font-weight: 400 !important;
        font-size: $fs_large  !important;
        line-height: 50px;
        color: $primaryColor  !important;
        text-transform: lowercase;
        margin-bottom: 0.6rem !important;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .w-form-line.w-submit .w-btn-wrapper+.w-btn-wrapper {
        margin-left: inherit;
    }

    section {
        box-sizing: border-box;
        font-family: $primaryFont  !important;
        font-size: $fs_txt  !important;

        span {
            &.toggleCookiePrefs {
                display: block;
                font-family: $primaryFont  !important;
                color: $primaryColor  !important;
                font-size: $fs_txt  !important;
                font-weight: 400 !important;
                line-height: 1;
                font-style: normal;
                text-decoration: underline;
                margin-bottom: 48px;
            }
        }
    }

    .toggleCookiePrefs::after {
        display: none !important;
    }

    section:first-of-type {
        line-height: 1.2;
        font-family: $primaryFont  !important;
        font-size: $fs_txt  !important;
        color: $primaryColor  !important;
    }

    a {
        color: $primaryColor;
    }

    .toggleCookiePrefs {
        position: relative;
        display: inline-flex;
        font-family: $ctaFont;
        font-style: normal;
        font-size: .875rem;
        text-decoration: none;
    }

    .w-input-label {
        color: $primaryColor;
    }

    .w-form-line.w-submit {
        flex-direction: column;
        align-items: flex-end;
        margin: 0px auto;
        height: auto;

        .w-btn-wrapper {
            width: 230px !important;
            margin-bottom: 10px;

            &:last-child {
                .toggleCookiePrefs {
                    font-family: $ctaFont;
                    font-size: .875rem;
                    font-style: normal;
                    margin-top: 0;
                }
            }
        }
    }

    .cookiesLightbox_container {
        display: grid;
        grid-template-rows: repeat(5, auto);
        grid-template-columns: auto auto;
        width: 93%;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1680px;
        row-gap: 10px;

        .cookiesLightbox_content {
            grid-row: 1 / 4;
            max-width: 1120px;
            grid-column: 1;
        }

        .cookiesLightbox_preferences {
            width: 100%;
            grid-row: 3 / 6;
            max-width: 1120px;
            grid-column: 1;
        }

        .cookiesLightbox_accept_btn {
            grid-column: 2;
            grid-row: 1;
            width: 230px;
        }

        .cookiesLightbox_refuse_btn {
            grid-column: 2;
            grid-row: 2;
            margin-left: 0;
            width: 230px;
        }

        .cookiesLightbox_custom_btn {
            grid-column: 2;
            grid-row: 3;
            margin-left: 0;
            width: 230px;
        }

        #jsCookieCustom {
            margin-top: 0;
        }
    }

    #cookiePref01 {
        .w-checkbox-input .w-input-element + .w-input-label {
            &::before {
                top: -2px;
            }

            &::after {
                top: -1px !important;
            }
        }
    }

    #cookiePref02,
    #cookiePref03 {
        .icon {
            position: relative;
            top: 6px;
            display: inline-block !important;
            margin-left: 0 !important;
        }

        .w-checkbox-input .w-input-element + .w-input-label {
            &::before {
                top: 4px !important;
            }

            &::after {
                top: 8px !important;
            }
        }
    }
}

#cookiesLightbox .w-checkbox-input .w-input-element:checked + .w-input-label::before {
    background-color: $ctaColor  !important;
}

#cookiesLightbox .w-form-line.w-submit {
    justify-content: flex-end;
    margin-top: -1rem !important;
}

#cookiesLightbox ul li .toggleCookieBloc {
    border-color: $ctaColor  !important;
}

/* LIGHTBOX PRECOMMANDE TUNNEL */
#lightbox_preco {
    padding: 2rem 10vw;
    color: $primaryColor;

    .close_pop {
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('../svg/icon_close.svg');
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;

        span {
            font-size: 0;
        }
    }

    .t_lightbox_preco {
        max-width: 620px;
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 400;
        font-size: $fs_txt;
        line-height: 18px;
        text-align: center;

        span {
            font-family: $primaryFont;
            font-style: italic;
            font-weight: 400;
            font-size: $fs_large;
            line-height: 50px;
            text-align: center;
            display: block;
            padding-bottom: 0.8125rem;
        }
    }

    .blocPrecoWrapper {
        display: block;
        text-align: center;
        width: 100%;
        max-width: 320px;
        margin: 0 auto;

        .bloc_lightbox_preco {
            margin: 30px auto 37px auto;
            position: relative;
            border: 1px solid $ctaColor;

            .ico {
                background-color: $ctaColor;
                border-color: $ctaColor;
                position: absolute;
                top: -8px;
                left: 50%;
                transform: translateX(-50%);
                width: 15px;
                height: 15px;
                border-radius: 50%;
                border: 1px solid $borderColor;
                box-shadow: inset 0 0 0 2px $whiteColor;
                transition: all 0.3s ease-in-out;
            }

            p {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: $fs_txt;
                line-height: 18px;
                text-align: center;
                padding: 42px 43px 29px 43px;

                span {
                    display: block;
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: $fs_subtitle;
                    line-height: 29px;
                    text-align: center;
                    margin: 0 0 5px;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
            }

            br {
                content: '';
                display: none;
            }

            br:after {
                content: '\00a0';
            }

            strong {
                font-weight: normal;
            }
        }
    }

    .btn_container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        button {
            display: inline-block;

            &.loader {
                position: absolute;
                top: 0;
                left: 0;
                cursor: default;
                background: $ctaColor url(../svg/loader_white.svg) center no-repeat;
            }
        }

        .error,
        .success {
            display: none;
        }
    }
}

/* CODE PROMO TUNNEL */
#modboxpromo {
    max-width: 600px;
    padding: 2rem;
    text-align: center;
    flex-direction: column;

    &.actif {
        display: flex;
    }

    .texte {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 0;
        text-align: center;
        color: $secondaryColor;
    }

    #btns_panier {
        width: 100%;
        max-width: 190px;
        margin: 0 auto;
    }
}

.lightbox#sendwishlistbox #sendwishlist #wrapper_sendwishlist #sendfriend_form_froms #recaptcha {
    max-width: none;
    display: flex;
    justify-content: center;
}

/**
 * Legal mentions (bottom site)
 */
.btn_detail_offre {
    display: block;
    margin-top: 1rem;
    font-size: 12px;
}

#mention_legales_pop_up {
    width: 520px;
    height: 250px;

    a.btn_retour {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        background: transparent url(../svg/icon_close.svg) no-repeat center;
        background-size: contain;
        font-size: 0;
    }

    .pop_mention_title {
        font-family: $ctaFont;
        font-size: 16px;
        text-transform: lowercase;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .pop_mention_detail {
        font-size: 14px;
    }
}

/**
 * Ask for a quote
 */
#lightbox_devis_form {
    width: 820px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    padding: 3rem;

    .close_pop {
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('../svg/icon_close.svg');
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
    }

    .title {
        text-align: center;
    }

    .w-input-element {
        box-sizing: border-box;
        border-radius: 0 !important;

        &[type='number'] {
            appearance: none;

            .w-input-label {
                text-indent: 1rem;
            }
        }
    }

    .w-fieldset-label {
        font-family: $primaryFont;
        font-size: 15px;
        color: $primaryColor;
        margin: 0 1.2rem 0 0;
    }

    .w-checkbox-input .w-input-element + .w-input-label,
    .w-radio-input .w-input-element + .w-input-label {
        font-size: 14px;
        color: $primaryColor;
        a{
            text-transform: lowercase;
            text-decoration: underline;
        }

        &:before {
            width: 1.3em;
            height: 1.3em;
        }
    }

    .w-dropdown {
        border-radius: 0;
    }

    .w-form-line {
        &.devisPhoneSociety{
            position: relative;
            z-index: 1;
            .errormsgadd{
                display: none;
            }
        }
        &.devisCurrencyPeople {
            justify-content: space-between;

            .currencyWrapper {
                display: flex;
            }

            .w-input-container {
                position: inherit;
                width: calc(50% - 0.5rem);
            }
        }

        &.devisOptions {
            align-items: flex-end;

            .w-fieldset-label:last-of-type {
                margin-left: 2rem;
            }
        }

        &.w-submit {
            width: fit-content;
            margin: 0 auto;

            button.w-submit-button {
                padding: 0 3em;
            }
        }

        .w-input-container{
            position: unset;
            .w-input{
                &.w-tel-input{
                    .country-list{
                        &::before{
                            content:'';
                            background-color: $whiteColor;
                            height: 200px;
                            width: 100%;
                        }

                    }
                }
            }
        }
    }

    #recaptcha {
        margin-bottom: 1.875rem;
    }

    .w-submit-button,
    button.w-submit-button {
        background-color: $whiteColor;
        color: $ctaColor;
        border-color: $ctaColor;
        box-shadow: inset 0 0 0 2px $whiteColor;
        padding: 0 3em;

        &:hover {
            background-color: $ctaColor;
            color: $whiteColor;
        }

        &.w-loader {
            width: 100%;
            background-color: $ctaColor;
        }
    }
}

#popup_dispatch {
    width: 659px;
    height: 84vh;
    max-height: 589px;
    padding: 0;
    font-family: $primaryFont;
    color: $primaryColor;
    padding: 50px;
    z-index: 102;

    .close_pop {
        display: block;
        position: absolute;
        top: 19px;
        right: 19px;
        width: 14px;
        height: 14px;
        background: transparent url(../svg/icon_close.svg) no-repeat center;
        background-size: cover;
    }
    .popup_dispatch_button {
        width: 190px;
        margin: 0 auto;
    }
    select {
        margin-top: 0.313rem;
        background-size: 24px 16px !important;
        text-indent: 2.5rem;
        color: $black;
    }
    option {
        cursor: pointer;
    }
    .row {
        position: relative;
    }
    .popup_dispatch_arrow {
        &:after {
            position: absolute;
            right: 20px;
            transform-origin: center;
            content: '';
            display: block;
            background-image: url(../svg/arrow_header.svg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            width: 14px;
            height: 6px;
            transition: transform .3s ease,background-color .4s ease;
            bottom: 1.2rem;
        }
    }

}

.popup_dispatch {
    &_img {
        width: 272px;
        display: flex;
        margin: 1rem auto;
    }
    &_txt {
        font-size: 3.125rem;
        font-style: italic;
        text-align: center;
    }
    &_row {
        margin-bottom: 1.5rem ;
        margin-top: 2rem;
    }
    &_row_2 {
        margin-bottom: 2rem;
    }
}