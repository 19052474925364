/**
*
* Link
*
**/
a {
    color: $primaryColor;
    transition: color ease-in 250ms;

    &::after {
        transition: all ease-in 250ms;
    }
}

a:hover {
    color: $ctaColor;
}

// Mixin to create links
@mixin style-cta($textColor, $hoverColor) {
    font-family: $ctaFont;
    text-transform: lowercase;
    letter-spacing: 2.8px;
    color: $textColor;
    display: inline-block;
    line-height: 1;

    &:hover {
        color: $hoverColor;
    }
}

.cta--primary {
    @include style-cta($primaryColor, $ctaColor);
}

.cta--secondary {
    @include style-cta($ctaColor, $primaryColor);
}