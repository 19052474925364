@import '../../_app/_src/scss/desktop/mixins/typeFace';

$sackersgothicstdheavy: (
    normal: (std)
);

@include typeFace('sackersgothicstdheavy', $sackersgothicstdheavy);

/* FONTS */
$primaryFont: 'linotype-sabon', sans-serif;
$secondaryFont: Arial, sans-serif;
$ctaFont: 'sackersgothicstdheavy', sans-serif;
$tertiaryFont: Sabon LT Pro;
$sackersstd: 'sackersgothicstd', sans-serif;
