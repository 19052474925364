.wrap_reassurance {
    max-width: $wrapperXLarge;
    margin: 0rem auto 0;
    background-color: $backgroundColor;
    width: 100%;

    .container_reassurance {
        width: 100%;
        max-width: $wrapperSmall;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        min-height: 110px;
        column-gap: 10%;

        @media screen and (min-width: 1024px) and (max-width: 1100px) {
            column-gap: 9%;
        }

        @media screen and (min-width: 768px) and (max-width: 1024px) {
            column-gap: 100px;
        }

        @include midSmallScreen {
            column-gap: 7.3%;
        }

        @media screen and (max-device-width: $wrappermidSmall) {
            column-gap: 7.3%;
        }

        .reassurance {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3.875rem 0 1.25rem 0;
            background-position: center 1.25rem;
            background-repeat: no-repeat;

            &.faq {
                background-image: url(../svg/icon_faq.svg);
                background-size: 3.375rem 1.8125rem;
            }

            &.service {
                background-image: url(../svg/icon_comments.svg);
                background-size: 2.125rem 2.1875rem;
            }

            &.boutiques {
                background-image: url(../svg/boutiques.svg);
                background-size: 1.4375rem 2.1963rem;
            } 

            &.fidelity {
                background-image: url(../svg/icon_present.svg);
                background-size: 2.1375rem 2.1963rem;
            }

            .title {
                display: block;
                text-transform: lowercase;
                font-size: $fs_mid;
                line-height: 1.05rem;
                font-family: $ctaFont;
                letter-spacing: 2.8px;
                color: $ctaColor;
                text-align: center;
                letter-spacing: 0.2em;
            }

            .txt {
                display: block;
                font-size: 0.9375rem;
                line-height: 1.125rem;
                font-family: $primaryFont;
                color: $primaryColor;
                text-align: center;
                padding-top: 0.41rem;
                max-width: 22ch;
            }
        }

        @include midSmallScreen {
            padding: 0 20px 0px 20px;
        }
    }
}

.footer {
    .footer_content {
        display: block;
        max-width: $wrappermidMedium;
        width: 100%;
        margin: 0 auto;
        padding: 2rem 0rem 1rem 0rem;

        @include mediumScreen {
            max-width: $wrappermidSmall;
            padding: 2rem 20px 1rem 20px;
        }

        @include midSmallScreen {
            max-width: $wrapperXSmall;
            padding: 2rem 0.6rem 1rem 0.6rem;
        }

        @media screen and (max-device-width: $wrappermidSmall) {
            max-width: $wrapperXSmall;
            padding: 2rem 1rem 1rem 1rem;
        }

        .topWrapper {
            display: flex;
            justify-content: space-between;

            @include midSmallScreen {
                justify-content: center;
                flex-wrap: wrap;
            }

            @media screen and (max-device-width: $wrappermidSmall) {
                justify-content: space-between;
                flex-wrap: nowrap;
            }

            .bot_news {
                display: inline-block;
                max-width: 447px;
                width: 100%;

                .w-input-label {
                    span {
                        color: $secondaryColor;
                    }
                }

                @include mediumScreen {
                    max-width: 380px;
                }
            }

            .bot_links {
                float: right;
                max-width: 708px;
                width: 100%;
                margin-top: 40px;

                .SEO {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 0px;
                    column-gap: 100px;

                    @include mediumScreen {
                        column-gap: 7%;
                    }

                    @include midMediumScreen {
                        column-gap: 3.4%;
                    }

                    @include midSmallScreen {
                        column-gap: 8%;
                        margin-top: 35px;
                        text-align: center;
                        flex-wrap: wrap;
                        justify-content: center;
                    }

                    @media screen and (max-device-width: $wrappermidSmall) {
                        column-gap: 2%;
                        margin-top: 0px;
                        text-align: left;
                        flex-wrap: nowrap;
                    }

                    .col {
                        flex: none;
                        flex-grow: 0;

                        &:last-child {
                            margin-left: 11px;
                        }

                        @include mediumScreen {
                            &:first-child {
                                margin: 0 0 0 50px;
                            }

                            text-align: left;
                        }

                        @include midSmallScreen {
                            text-align: center;
                            margin-bottom: 35px;
                        }

                        @media screen and (max-device-width: $wrappermidSmall) {
                            &:first-child {
                                margin: 0 0 0 60px;
                            }

                            width: 30%;
                            text-align: left;
                            margin-bottom: 0;
                        }

                        .col_title {
                            position: relative;
                            display: block;
                            width: 100%;
                            text-align: left;
                            text-transform: lowercase;
                            font-family: $ctaFont;
                            font-size: $fs_mid;
                            font-weight: 850;
                            line-height: 17px;
                            letter-spacing: 0.2em;
                            position: relative;
                            top: -2px;

                            @include midSmallScreen {
                                text-align: center;
                            }

                            @media screen and (max-device-width: $wrappermidSmall) {
                                text-align: left;
                            }
                        }

                        .col_content {
                            .col_links {
                                display: block;
                                color: $primaryColor;
                                font-size: $fs_min;
                                font-weight: 400;
                                font-family: $primaryFont;
                                margin: 1em 0;

                                &:hover {
                                    color: $ctaColor;
                                }
                            }
                        }
                    }
                }
            }

            .bot_news_title {
                font-size: $fs_large;
                line-height: 3.125rem;
                font-family: $primaryFont;
                font-weight: 400;
                font-style: italic;

                @include midSmallScreen {
                    text-align: center;
                }

                @media screen and (max-device-width: $wrappermidSmall) {
                    text-align: left;
                }
            }

            .bot_news_txt {
                font-size: $fs_txt;
                line-height: 1.125rem;
                font-family: $primaryFont;
                font-weight: 400;
                padding: 0.3125rem 20px 0.75rem 0;

                @include midSmallScreen {
                    text-align: center;
                }

                @media screen and (max-device-width: $wrappermidSmall) {
                    text-align: left;
                }
            }

            .w-newsletter-form {
                width: 90%;

                @media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
                    width: inherit;
                }

                .w-input-element:not([type='radio']):not([type='checkbox']),
                .w-newsletter-form input[type='email'].w-input-element {
                    padding-right: 9rem;
                    border-radius: 0;
                    border: 1px solid $ctaColor;

                    &:focus {
                        outline: 1px solid $ctaColor;
                    }

                    &.w-focused {
                        outline: 1px solid $ctaColor;
                    }
                }

                .w-submit {
                    width: 114px;
                    line-height: 3rem;
                    height: 100% !important;
                    position: absolute;
                    right: 5%;
                }
            }

            .socials {
                display: none;
            }

            .wrap_links {
                &.social {
                    display: flex;
                    flex-direction: row;
                    column-gap: 6px;
                    position: relative;
                    top: -2px;
                    align-items: center;

                    @include midSmallScreen {
                        justify-content: center;
                    }

                    @media screen and (max-device-width: $wrappermidSmall) {
                        justify-content: inherit;
                    }

                    .col_title {
                        font-family: $ctaFont;
                        text-transform: lowercase;
                        font-size: $fs_mid;
                        line-height: 0.463rem;
                        letter-spacing: 0.2em;
                        color: $primaryColor;
                    }

                    .col_content {
                        display: inline-block;

                        a {
                            display: inline-block;
                            vertical-align: middle;
                            width: 20px;
                            height: 20px;
                            margin: 0 9px;

                            &.facebook {
                                background: url(../svg/icon_fb.svg);
                            }

                            &.instagram {
                                background: url(../svg/icon_instagram.svg);
                            }

                            &.twitter {
                                background: url(../svg/icon_twitter.svg);
                                width: 17px;
                                height: 18px;
                            }
                        }
                    }
                }
            }
        }

        .wshopCopy {
            float: right;
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: $fs_min;
            line-height: 1.1875rem;
            color: $ctaColor;
            position: relative;
            padding: 4px;

            @include midSmallScreen {
                padding: 40px;
                text-align: center;
                display: block;
                float: none;
                position: relative;
                top: inherit;
                right: inherit;
            }

            @media screen and (max-device-width: $wrappermidSmall) {
                position: relative;
                top: -10px;
                right: 10px;
                padding: 4px;
                float: right;
            }

            .wShop {
                background: url(../svg/logo_wshop.svg);
                width: 40px;
                height: 25px;
                display: inline-block;
                vertical-align: bottom;
            }
        }

        .btn_detail_offre {
            display: none;
        }
    }
}

