// ajout class apparition push panier
/**
 * Cart steps
 */
.valid_form {
    position: absolute;
    top: 15px;
    right: 10px;
}

body.step_2_0 {
    #new_address {
        .w-input-group {
            margin-bottom: 0;
        }

        .w-form-line:nth-child(1) {
            .w-dropdown {
                display: none;
            }

            +fieldset {
                display: none;
            }
        }

        .w-form-line {
            &.addrCivility {
                .w-dropdown {
                    display: block !important;
                }
            }
        }

        .w-newuser-form {
            .w-checkbox-input {
                .w-input-element:checked+.w-input-label::after {
                    left: 14px;
                }

                .w-input-element:checked+.w-input-label::before {
                    background: #ececec;
                }

                .w-input-element+.w-input-label::after {
                    left: 23px;
                    transform: translateX(-13px);
                }

                .w-input-element+.w-input-label::before {
                    background: #909090;
                }
            }
        }

        .w-newuser-form .bill {
            .notice {
                display: none;
            }
        }

        .w-newuser-form {
            .delivery {
                fieldset {
                    legend {
                        display: none;
                    }
                }
            }

            legend {
                &.subtitle {
                    display: block;

                    span {
                        display: block !important;
                    }
                }
            }
        }

    }
}

body.step_2 {
    #new_address {
        .w-input-group {
            margin-bottom: -15px;
        }
    }
}

#payment_form_stripe {
    .saveAlias {
        display: none;
    }

    label {
        margin-top: 10px;
    }
}

.hide_shippingfees {
    display: none !important;
}

nav.tunnel_track {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 96%;
    max-width: 850px;
    margin: 23px auto 49px;

    .tunnel_step {
        position: relative;
        font-family: $ctaFont;
        font-size: 14px;
        color: $primaryColor;
        text-transform: lowercase;
        text-decoration: none;
        letter-spacing: 0.2rem;

        &.on,
        &.passed {
            color: $ctaColor;

            &:last-child() {
                border-bottom: 1px solid $ctaColor;
                padding-bottom: 5px;
            }
        }

        &.on span:after {
            content: '';
            display: block;
            position: absolute;
            top: 125%;
            left: 0;
            width: 100%;
            height: 1px;
            background: $ctaColor;
        }

        +.tunnel_step:before {
            content: '>';
            font-size: 14px;
            position: absolute;
            left: -3.2rem;
            top: 0%;
        }

        span {
            position: relative;
        }
    }
}

/**
 * Cart general styles
 */
.cart {
    &:not(.step_1),
    &:not(.step_1).customer.body_login {
        padding-top: unset;
    }

    &.customer {
        padding: 170px;
    }

    #breadcrumbs.main_ariane {
        .breadcrumb {

            &:nth-of-type(2)::after,
            &:nth-of-type(3) {
                display: none;
            }
        }
    }

    &.step_2 {
        #adresseForm {
            .errormsgadd {
                position: relative;
                top: 0.11rem;
            }
        }
    }
}

.wrapper_panier {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 96%;
    max-width: 1288px;
    margin: 0 auto;
    min-height: 600px;
}

.elem_tunnel {
    &.left_side {
        width: 62%;
        max-width: 797px;

        .transporteur_info {
            text-align: center;
        }
    }

    &.right_side {
        width: 35%;
        max-width: 445px;

        #blocs_address {
            margin-top: 7px;

            .address {
                padding: .625rem .9375rem;
                margin-bottom: 1.25rem;
                position: relative;

                &.delivery {
                    background: $backgroundColor;
                }

                .addr_label {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.5rem;
                    line-height: 29px;
                }

                .infos {
                    .addr_line {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 21px;

                        .full_address {
                            text-transform: capitalize;
                        }
                    }

                }

                .modlinks_wrapper {
                    position: absolute;
                    top: .9375rem;
                    right: .9375rem;

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }

        .pushBasketWrapper {
            position: relative;
            width: 100%;

            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }

            .pushBasketText {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 100%;
                padding: 22px 10%;
                font-family: $primaryFont;
                font-style: italic;
                font-weight: 400;
                font-size: 36px;
                line-height: 43px;
                text-align: center;
                top: 0;
                color: $whiteColor;
                font-weight: normal;
                text-transform: capitalize;
                height: 100%;

                @media only screen and (max-width: 1920px) {
                    font-size: 1.875vw;
                }

                span {
                    font-family: $ctaFont;
                    font-style: normal;
                    font-weight: 850;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    letter-spacing: 0.2em;
                    color: $whiteColor;
                    text-transform: lowercase;
                    text-decoration: underline;
                }
            }
        }

        .pushBasketWrapperText {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: left;
            height: 100%;
            width: 100%;
            font-size: 13px;
            font-weight: 400;
            margin-top: 20px;
            line-height: 1.4;
            flex-direction: column;
        }

        .bloc_question {
            display: none;
        }
    }
}

.cart_main_title {
    font-size: 42px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 17px;

    &.wishlist {
        margin-top: 3.1rem;
    }

    .nb_products {
        display: none;
    }
}

.basket_multi_delivery {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    padding-left: 33px;
    margin-bottom: 20px;

    &::before {
        content: '';
        display: block;
        position: absolute;
        background: url('../svg/icon_info.svg') no-repeat center;
        background-size: contain;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
    }

    .basket_multi_delivery_title {
        color: $ctaColor;
    }
}

.product_no_sfs_warning {
    width: 100%;
    margin: 1rem 0;
    padding: 1rem;
    color: #1f1e1e;
    border: 1px solid #201f1f;
}

.form_submit.stripeSubmit {
    #submit_adyen {
        width: fit-content;
        color: $ctaColor;
        padding: 0 3em;

        &:hover {
            color: white;
        }
    }

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        font-size: 0;
        background: $ctaColor url(../svg/loader_white.svg) no-repeat center;
        background-size: auto 100%;
        border: 1px solid $ctaColor;
        box-shadow: inset 0 0 0 2px $whiteColor;
    }
}

.dropdown_cart_link {
    display: none;
}

.precoBasket {
    .precoBasketIntro {
        display: none;
    }
}

.activPreorder .dropdown_cart_link {
    display: block;
    line-height: 1.2;
    margin-bottom: 1rem;

    &[data-dropdown='exp-2'] {
        margin-top: 1.5rem;
    }

    h3 {
        display: none;
        font-family: $ctaFont;
        text-transform: lowercase;
        font-size: 14px;
        letter-spacing: 0.1rem;
        margin-bottom: 0.2rem;
    }

    .precoSubtitle {
        font-size: 15px;
    }

    .precoDeliveryDate {
        display: none;
        font-size: 15px;
    }
}

/**
 * Cart product line
 */
.cart_product_line {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 130px;
    font-size: 15px;
    border-bottom: 1px solid $lightBorder;
    margin-bottom: 10px;
    margin-top: 25px;

    .dragHandle,
    .dragOverlay,
    .cart_product_brand,
    .cart_product_sizecol.color,
    .line_product_desc_preorder {
        display: none;
    }

    .cart_product_pic {
        position: relative;
        width: 144px;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        .eclat_rect {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .cart_product_desc {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 144px);
        height: calc(100% - 10px);
        padding-left: 6.5%;
        box-sizing: border-box;
    }

    .wrapper_cart_product_desc {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 65.5%;
        height: 100%;
    }

    .wrap_titre_prix {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        line-height: 1.2;
        flex: 1;

        a.product_mod {
            width: 8rem;
        }

        .cart_product_subtitle {
            display: none;
        }
    }

    .line_product_desc {
        strong {
            font-weight: 400;
        }
    }

    .wrapper_cart_product_price,
    .cart_product_sizecol.quantity {
        label {
            display: block !important;
            width: 100%;
            margin-bottom: 1.4rem;

            &> span:first-child:after {
                content: ' :';
                padding-right: 5px;
            }
        }
    }

    .wrapper_cart_product_price {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 75px;
        text-align: center;
    }

    .pricetag {
        width: 100%;
        font-size: 15px;

        +.pricetag {
            position: absolute;
            margin-top: 3.5rem;
            font-size: 12px;
            color: $ctaColor;
            text-decoration: line-through;
        }
    }

    .cart_product_sizecol.quantity {
        position: absolute;
        top: 26%;
        left: 45.5%;
        width: 100px;
        text-align: center;

        .qte_selector {
            position: relative;
            height: 20px;

            label {
                display: none !important;
            }
        }
    }

    .cart_product_excluded {
        position: absolute;
        top: -15px;
        left: -183px;
        width: 100%;
        max-width: 150px;
        text-align: center;
        font-weight: bold;

        @media screen and (min-width: 1224px) {
            left: -191px;
        }

        @media screen and (min-width: 1380px) {
            left: -197px;
        }
    }

    // Cart product quantity change
    .qteWrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 75px;
        height: 20px;

        .change_qte {
            position: absolute;
            width: 20px;
            height: 20px;
            border: 1px solid $ctaColor;
            color: $ctaColor;
            cursor: pointer;

            &.lessQte {
                left: 0;
            }

            &.moreQte {
                right: 0;
            }
        }

        .selected_qte {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            border: 0;
            width: 35px;
            height: 20px;
            font-family: $primaryFont;
            font-size: 15px;
            text-align: center;
            outline: none;
        }
    }

    img.load_qte {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .cart_product_modify {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a.product_mod {
            display: none;
        }

        // Ajax quantity change when moving product from wish to cart on basket page
        .product_quantity_change {
            position: absolute;
            top: 57%;
            left: 30.25%;
            width: 75px;
            text-align: center;
        }

        a.product_towish {
            span {
                text-decoration: underline;
            }
        }

        a.product_del,
        a.remove_wish {
            font-size: 0;
            display: block;
            padding: 0 1rem;
            width: 12px;
            height: 12px;
            background: transparent url(../svg/icon_close.svg) no-repeat center;
            background-size: contain;
            margin-right: 0.3rem;
        }

        a.wishToBasket {
            display: initial;
            padding: 0;
            margin: 0;
            font-family: $primaryFont;
            font-size: 15px;
            letter-spacing: initial;
            border: 0;
            text-decoration: underline;
            width: initial;
            height: initial;
            background: none;
            color: $primaryColor;

            &:hover {
                color: $ctaColor;
            }
        }
    }
}

.line_ssTotal {
    display: none;
}

.cart_main_table.wishlist {
    .cart_product_line {
        .wrapper_cart_product_desc {
            width: 100%;
        }

        .wrap_titre_prix {
            justify-content: flex-start;
        }

        .wrapper_cart_product_price {
            margin-left: 35%;
            width: 75px;
        }
    }
}

/**
 * Cart right side
 */
#cart_total {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 2px solid $ctaColor;
    padding: 2.1rem 1.9rem;

    .restricted_form {
        order: 8;

        .w-checkbox-input .w-input-element:checked+.w-input-label::before, .w-radio-input .w-input-element:checked+.w-input-label::before {
            background-color: $ctaColor ;
        }
        .w-input-label {
            align-items: flex-start;
            margin-top: 40px;
            margin-bottom: 20px;
            &::before {
                width: 13px;
                height: 13px;
                border: 1px solid $ctaColor;
                background-color:$white ;
                position: relative;
                top: 0px;
            }
        }
        span {
            color: rgba($ctaColor, 1);
            font-family: $primaryFont;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .w-input-note {
        position: relative;
    }

    .recap_cart_title {
        order: 1;
        font-size: 24px;
        font-weight: 400;
    }

    .bill_line {
        &.unlimited {
            order: 3;
            font-size: 24px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 9px;

            label {
                font-family: $primaryFont!important;
                text-transform: none!important;
                letter-spacing: normal!important;
            }
        }

        .price {
            font-size: 15px;
        }

        &.total,
        &.coupon,
        &.promo,
        &.discount,
        &.used_cp {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }

        &.total_produit {
            order: 2;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }

        &.promo,
        &.used_cp {
            order: 3;
        }

        &.giftCmd {
            order:6;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding-top: 0.55rem;

            #isGift {
                display: none;
            }

            [type=checkbox]:not(:checked)+label:before {
                box-shadow: inset 0 0 0 0.125em white;
                content: '';
                display: block;
                width: 14px;
                height: 14px;
                margin: 0 0.5rem 0 0;
                border-radius: 0;
                border: 1px solid grey;
                cursor: pointer;
            }

            [type=checkbox]:checked +label:before {
                background-color: #3c3c3c;
                box-shadow: inset 0 0 0 0.125em white;
                content: '';
                display: block;
                width: 14px;
                height: 14px;
                margin: 0 0.5rem 0 0;
                border-radius: 0;
                border: 1px solid grey;
                cursor: pointer;
            }

            label {
                font-family: $primaryFont!important;
                font-size: 14px !important;
                letter-spacing: 0 !important;
                text-transform: unset !important;
                line-height: 16.8px;
                display: -webkit-inline-box;
                width: 90%;
                color: $primaryColor;
            }
        }

        &.total_produit,
        &.discount,
        &.promo {
            margin-top: 10px;
            font-family: $primaryFont !important;
            font-size: 15px !important;
            text-transform: initial !important;
            letter-spacing: initial !important;

            label {
                font-family: $primaryFont !important;
                font-size: 15px !important;
                text-transform: initial !important;
                letter-spacing: initial !important;
            }
        }

        &.total_produit {
            margin-top: 1.9rem
        }

        &.discount {
            order: 4;
        }

        &.total {
            // order: 4;
            order: 5;
            border-top: 1px solid $lightBorder;
            margin-top: 1.1rem;
            padding-top: 1.6rem;
            padding-bottom: 1rem;
        }

        &.coupon {
            order: 6;
            margin-top: 1rem;
        }

        &.used_cp {
            margin-top: 1rem;
            margin-bottom: 1rem;

            .code_promo {
                font-size: 12px;
                display: flex;
            }
        }

        label:not(.w-text-input) {
            font-family: $ctaFont;
            font-size: 14px;
            text-transform: lowercase;
            letter-spacing: 0.2rem;
        }
    }

    .order_gift_form,
    .mot_personnalise_form .w-checkbox-input {
        pointer-events: none;

        .w-input-element+.w-input-label {
            span {
                margin-left: 0;
            }

            &:before {
                display: none;
            }
        }

    }

    .order_gift_form {
        order: 5;
        cursor: pointer;

        .w-input-label {
            cursor: pointer;

            &:before {
                display: none;
            }
        }

        .w-input-label {
            font-family: $ctaFont;
            font-size: 8px;
            color: $primaryColor;
            text-transform: uppercase;
            letter-spacing: 0.2rem;

            &:before {
                width: 1.5em;
                height: 1.5em;
                border: 1px solid $lighterGrey;
            }

            span {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 150%;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $primaryColor;
                }
            }
        }

        .w-input-element + .w-input-label span {
            color: #000;
        }
    }

    .mot_personnalise_form {
        order: 7;

        .w-input-label {
            font-family: $primaryFont;
            font-size: 12px;
            line-height: 16px;
            color: $primaryColor;
            height: 37px;
            align-items: initial;
            padding-top: 20px;

            &#has_perso_word {
                font-size: 14px;
                font-family: $primaryFont!important;
            }
        }

        textarea {
            width: 100%;
            height: 5rem;
            border: 1px solid $lighterGrey;
            padding: 0.5rem;
            font-family: $primaryFont;
            box-sizing: border-box;
            margin-top: 0.8rem;
            resize: none;
        }
    }

    .formPanier_container {
        order: 8;
        margin-top: 1.5rem;

        .form_submit {
            position: relative;

            &.loading {
                .loader {
                    opacity: 1;
                    z-index: 1;
                }
            }
        }

        #btn_cmd_valid,
        .loader {
            width: 100%;
            height: 49px;
        }

        #btn_cmd_valid {
            margin: 0;
            color: $whiteColor;
            box-shadow: inset 0 0 0 2px $whiteColor;
            background-color: $ctaColor;

            &:hover {
                background-color: $whiteColor;
                color: $ctaColor;
            }
            &.disabled_restricted {
                opacity: 0.5;
                pointer-events: none;
            }

        }

        #btn_cmd_valid:disabled {
            opacity: 0.7;
        }

        #btn_cmd_valid:hover:disabled {
            background-color: $ctaColor;
            color: $whiteColor;
        }

        .loader {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0;
            font-size: 0;
            background: $ctaColor url(../svg/loader_white.svg) no-repeat center;
            background-size: auto 100%;
            border: 1px solid $ctaColor;
            box-shadow: inset 0 0 0 2px $whiteColor;
        }
    }

    .upselling.price {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        position: absolute;
        top: 105%;
        left: 0;
        width: 100%;
        height: 75px;
        background-color: $backgroundColor;

        p {
            order: 2;
            width: 85%;
            margin: 0.8rem auto 0;
            font-family: $ctaFont;
            font-size: 10px;
            text-transform: uppercase;
            color: $ctaColor;
            letter-spacing: 0.2rem;
            line-height: 1.3;
        }

        progress {
            order: 1;
            width: 90%;
            margin: 1rem auto 0;
            background: $whiteColor;
            border-color: $whiteColor;
            accent-color: $ctaColor;

            &::-webkit-progress-bar {
                background-color: $whiteColor;
                border-radius: 5px;
            }

            &::-webkit-progress-value {
                background-color: $ctaColor;
                border-radius: 5px;
            }

            &::-moz-progress-bar {
                background-color: $ctaColor;
                border-radius: 5px;
            }

            &::-moz-progress-value {
                border-radius: 5px;
            }
        }
    }

    #threshold_fdp_txt {
        font-family: $primaryFont;
        font-size: 15px;
        font-weight: 400;
    }

    progress {
        appearance: none;
        height: 5px;
    }

    ::-webkit-progress-bar {
        background-color: $whiteColor;
        border-radius: 3px;
    }

    ::-webkit-progress-value {
        background-color: $ctaColor;
        border-radius: 3px;
    }
}

body {
    .bloc_paiement {
        h2 {
            font-family: $primaryFont;
            font-style: italic;
            font-size: 2.625rem;
            line-height: 50px;
        }

        .freeOrderCouponTxt {
            padding: 20px 0;
            line-height: 1.2;
        }
    }

    #cart_total {
        margin-bottom: 2rem;

        .upselling.price {
            display: none;
        }
    }

    &.cart.step_1 {
        .basket_contents.cart_main_table.basket.dropper {
            display: flex;
            flex-direction: column;

            .multi_delivery_product {
                border: 1px solid $ctaColor;
                display: grid;
                grid-template-areas: "head buttons" "qty qty" "products products";

                padding: 20px;
                flex-direction: column;
                align-items: flex-start;

                margin-bottom: 20px;

                .multi_delivery_label {
                    grid-area: head;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-family: $ctaFont;
                    font-size: 14px;
                    font-weight: 850;
                    letter-spacing: 2.8px;
                    text-transform: lowercase;
                    color: $ctaColor;
                    margin-bottom: 15px;

                    &::before {
                        content: '';
                        display: block;
                        height: 14px;
                        width: 15px;
                        background: url(../svg/icon_store_delivery.svg) no-repeat center;
                        background-size: contain;
                    }

                    &.livraison_dom::before {
                        background: url(../svg/icon_home_delivery.svg) no-repeat center;
                        width: 24px;
                    }
                }

                .multi_delivery_buttons {
                    grid-area: buttons;
                    display: flex;
                    gap: 10px;
                    justify-content: flex-end;

                    button {
                        cursor: pointer;
                        text-decoration: underline;
                        font-family: $primaryFont;
                        font-size: 15px;
                    }
                }

                .nb_products {
                    grid-area: qty;
                    font-size: 15px;
                    font-weight: 400;
                    color: $ctaColor;
                    margin-bottom: 6px;
                }

                .product_line_basket {
                    grid-column: 1 / -1;
                    margin-top: 10px;
                    margin-bottom: 0;

                    &:last-of-type {
                        border-bottom: 0px;
                    }

                }

            }
        }

        .container {
            .cartAssociations {
                width: 96%;
                max-width: 1379px;
                margin: 80px auto 48px;

                .cart_main_title {
                    font-family: $primaryFont;
                    font-size: clamp(30px, fs-vw(42, 1920), 42px);
                    font-style: italic;
                    font-weight: 400;
                    line-height: 50.4px;
                    text-align: center;
                    margin-bottom: 20px;
                }
                
                .cartAssocSwiper {
                    width: 100%;
                    height: auto;

                    .swiper-wrapper {
                        .swiper-slide {
                            width: 314px;
                            height: auto;

                            .imgWrapper {
                                .rolloverimgContainer {
                                    img {
                                        flex-shrink: 0;
                                    }
                                }

                                .product_infos {
                                    .wrapper_description {
                                        padding-top: 1.25rem;

                                        .product_header {                                            
                                            .item_name {
                                                max-height: 3.5em;
                                                margin: 0 auto 1rem;
                                                font-family: $primaryFont;
                                                // font-size: clamp(12px, fs-vw(14, 1920), 14px);
                                                font-size: 15px;
                                                letter-spacing: unset;

                                                &:first-letter {
                                                    text-transform: uppercase;
                                                }
                                            }
                                        }

                                        .item_price {
                                            margin-top: 15px;
                                            font-family: $primaryFont;
                                            font-size: 24px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                        }
                                    }
                                }

                                .cta_express {
                                    transform: translateY(4px);
                                }
                            }
                        }
                    }
                }
            }
        }

        #cart_total {
            .order_gift_form {
                order: 7;
                margin-bottom: 0.9rem;
            }

            .bill_line.giftCmd {
                order: 8;
            }

            .mot_personnalise_form {
                order: 9;
            }

            .formPanier_container {
                order: 10;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    body {
        .wrapper_cart_product_price {
            margin-left: 32%;
            width: 75px;
        }

        .cart_product_line {
            .wrapper_cart_product_desc {
                width: 100%;
            }

            .cart_product_desc {
                display: initial !important;
                height: calc(100% - 35px);
            }

            .cart_product_modify {
                width: 100%;
            }
        }

        #cart_total {
            .upselling.price p {
                font-size: 7px;
            }
        }
    }

    .cart_main_table.wishlist {
        .cart_product_line {
            .wrapper_cart_product_desc {
                display: block;
            }

            .wrapper_cart_product_price {
                margin-left: 48%;
                width: 75px;
            }
        }
    }
}

/**
 * Stripe
 */
.bancontact,
.card,
.giropay,
.ideal {
    .bank_title {
        span {
            &::after {
                position: absolute;
                background-color: $whiteColor;
                margin-left: 4.5px;
            }
        }

        &::after {
            content: '';
            display: block;
            width: 44px;
            height: 44px;
            background-size: contain;
            margin: 0 0 0.2rem 5.8rem;
        }
    }

    .title_wrapper {
        width: 100%;
        font-size: 15px;
        text-align: center;
        text-decoration: underline;

        strong {
            font-weight: 400;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    &:hover .title_wrapper {
        text-decoration: none;
    }
}

#save_alias_stripe {
    display: none;
}

.elem_global {
    margin-top: 20px;

}

#mes_err_contact {
    margin-top: 10px;
    margin-bottom: 10px;
}

.bancontact {
    .bank_title {
        span {
            &::after {
                content: 'Bancontact :'
            }
        }

        &::after {
            background: transparent url(../img/banque/logo/bancontact.png) no-repeat center;
            background-size: 85%;
            margin: 0 0 .2rem 8.5rem;
        }
    }
}

.card {
    .bank_title {
        span {
            &::after {
                content: 'Apple Pay :'
            }
        }

        &::after {
            background: transparent url(../banque/logo/applePay.svg) no-repeat center;
            background-size: 85%;
            margin: 0 0 .2rem 8.5rem;
        }
    }
}

.giropay {
    .bank_title {
        span {
            &::after {
                content: 'Giropay :'
            }
        }

        &::after {
            background: transparent url(../img/banque/logo/giropay.png) no-repeat center;
            background-size: 85%;
            margin: 0 0 .2rem 6.8rem;
        }
    }

}

.ideal {
    .bank_title {
        span {
            &::after {
                content: 'iDeal :'
            }
        }

        &::after {
            background: transparent url(../img/banque/logo/ideal.png) no-repeat center;
            background-size: 85%;
            margin: 0 0 .2rem 4.8rem;
        }
    }
}

.paypal {
    .bank_title {
        span {
            &::after {
                content: 'Paypal :'
            }
        }

        &::after {
            content: '';
            display: block;
            width: 92px;
            height: 22px;
            background: transparent url(../banque/logo/paypay_logo_txt.png) no-repeat center;
            background-size: contain;
            margin: 0 0 .2rem 0.7rem;
        }
    }
}

.accordion_container .accordion_item .accordion_contents {
    display: block !important;
}

body.cart.step_1 #cart_total.uspselling_visible,
#cart_total.uspselling_visible {
    margin-bottom: 115px !important;
}

.uspselling_hide {
    display: none !important;
}

// masquer icône avertissement code livraison gratuite
.cart.step_2.in_tunnel .bill_line.discount .error_text,
.checkout.in_tunnel .bill_line.discount .error_text {
    display: none;
}

// masquer temporairement la ligne livraison
.cart.step_2_0.in_tunnel .bill_line.discount {
    display: none !important;
}

// Changer texte cta récap commande (car clé de trad en double avec cta validation ajout adresse)
body {

    &.cart.step_2.in_tunnel #cart_total #formPanier .form_submit {

        #btn_cmd_valid {
            position: relative;

            span {
                opacity: 0;
            }
        }

        &.loading {

            #btn_cmd_valid::before {
                opacity: 0;
            }
        }
    }

    &.fr.cart.step_2.in_tunnel {

        #cart_total #formPanier .form_submit #btn_cmd_valid::before {

            content: "Valider ma commande";
            width: 100%;
            position: absolute;
            z-index: 2;
        }
    }

    &.en.cart.step_2.in_tunnel {

        #cart_total #formPanier .form_submit #btn_cmd_valid::before {

            content: "Validate my order";
            width: 100%;
            position: absolute;
            z-index: 2;
        }
    }

    &.de.cart.step_2.in_tunnel {

        #cart_total #formPanier .form_submit #btn_cmd_valid::before {

            content: "Meine bestellung bestätigen";
            width: 100%;
            position: absolute;
            z-index: 2;
        }
    }

    &.nl.cart.step_2.in_tunnel {

        #cart_total #formPanier .form_submit #btn_cmd_valid::before {

            content: "Mijn bestelling bevestigen";
            width: 100%;
            position: absolute;
            z-index: 2;
        }
    }
}

.transports {
    display: none;
}

// Tunnel buttons if no conveyor available.

.links_conveyor__main-container {
    margin-top: 2rem;

    ul.links_conveyor__list {
        list-style: disc;
        list-style-position: inside;

        li.links_conveyor__link {
            padding: 0.5rem 0;
            color: $ctaColor;

            a {
                color: $ctaColor;
                text-decoration: underline;
            }
        }
    }
}

/* Multiple delivery return btn*/
.return_btn.multi {
    position: relative;
    display: flex;
    align-items: center;
    font-family: $primaryFont;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 770px;
    margin: 59px auto 42px;
    padding-left: 0.3rem;

    &::before {
        position: absolute;
        top: -5px;
        left: -12px;
        content: "";
        display: block;
        width: 10px;
        height: 21px;
        background-size: 55%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../svg/arrow_prev.svg);
    }
}

.choix_delivery_multiple {
    max-width: 770px;

    .errormsgadd {
        position: relative;
        top: 0.1rem;
        color: #888c8d;
        font-size: 12px;
        font-weight: 400;
    }

    &.bloc_livraison {
        margin: 8px auto 30px;
        padding-top: 2rem;

        // We hide multi-delivery bloc by default
        &.cache {
            display: none;
            transition: display 0.3s ease;
        }

        /* inputs style */
        .w-input-element:not([type=radio]):not([type=checkbox]), input[type=email].w-input-element, input[type=password].w-input-element, input[type=text].w-input-element, textarea.w-input-element {
            color: $placeholder;
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
        }

        a.show_modif_info {
            display: flex;
        }

        .checkbox {
            top: fs-vw(57,1920);
        }

        .title_type_livraison {
            font-family: $primaryFont;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            white-space: nowrap;
            padding: 0 1.5rem;
        }

        .warning_msg {
            display: block;
            font-family: $primaryFont;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 23px;

            .bold {
                font-weight: 700;
            }
        }

        .step_number {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $primaryFont;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 45px;
            width: 100%;
            height: 45px;
            margin: 0 auto 10px;
            border-radius: 50%;
            color: $white;
            background: $ctaColor;
            opacity: 1;
            transition: opacity 0.3s ease-in-out;

            &.not_active {
                opacity: 0.5;
            }

            span {
                position: relative;
                top: 2px;
            }
        }

        .step_number_title {
            font-family: $ctaFont;
            font-size: 14px;
            font-style: normal;
            font-weight: 850;
            line-height: normal;
            letter-spacing: 2.8px;
            text-align: center;
            text-transform: lowercase;
            color: $primaryColor;

            &.two {
                margin-bottom: 0;
            }

            &.three {
                margin-bottom: 20px;
                line-height: 1.1;
            }

        }

        .step_number {
            &.step1 {
                margin-bottom: 8px;
            }

            &.step2 {
                .not_active {
                    .step_number_title.two {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        /* STEP 1 */
        #step1 {
            margin-top: 17px;

            #btn_add_destination {
                position: relative;
                max-width: 418px;
                margin-bottom: 15px;

                &:after {
                    position: absolute;
                    left: 30px;
                    content: " + ";
                    display: block;
                }
            }

            .destination_container {
                margin-bottom: 15px;
                position: relative;

                &.selected {
                    .w-input-element {
                        color: $primaryColor;
                    }
                }

                select {
                    option[value="0"] {
                        color: $placeholder;
                    }
                }

                &::after {
                    position: absolute;
                    top: 30%;
                    right: 21px;
                    content: "";
                    display: block;
                    width: 10px;
                    height: 21px;
                    background-size: 55%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url(../svg/arrow_next.svg);
                    transform: rotate(90deg);
                    z-index: -1;
                }

                option {
                    color: $primaryColor;
                }
            }

            .w-group-label.subtitle {
                margin-bottom: 0;
            }

            .form_submit_btn {
                max-width: 158px;
                width: 100%;
            }
        }

        #step_1_limit_message {
            color: $secondaryColor;
            font-family: $primaryFont;
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
            margin-top: 22px;
        }

        #btn_validate_step_one {
            max-width: 158px;
            width: 100%;
            margin: 33px auto 29px;
            background-color: $ctaColor;
            color: $white;
        }

        /* STEP 2 */
        #step2 {
            .multidelivery_product_container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 24px;

                &:first-child {
                    .delivery_address_container {
                        position: relative;
                        top: 8px;
                    }
                }

                &:last-child {
                    margin-bottom: 30px;
                }

                .content {
                    max-width: 241px;
                    width: 33.33%;
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    img {
                        max-width: 96px;
                        width: 100%;
                    }
                }

                .product_image {
                    max-width: 96px;
                }

                .product_details {
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $primaryColor;
                    max-width: 125px;
                    width: calc(100% - 96px);
                    padding-left: 0.4rem;

                    .name {
                        display: block;
                        margin-bottom: 5px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .price {
                        display: flex;
                        position: relative;
                        top: -7px;
                    }

                    .quantity {
                        position: relative;
                        top: 6px;
                        color: $secondaryColor;
                        text-transform: lowercase;
                    }
                }

                .delivery_address_container {
                    position: relative;
                    max-width: 417px;
                    width: 57.67%;

                    &::after {
                        position: absolute;
                        top: 30%;
                        right: 21px;
                        content: "";
                        display: block;
                        width: 10px;
                        height: 21px;
                        background-size: 55%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-image: url(../svg/arrow_next.svg);
                        transform: rotate(90deg);
                        z-index: -1;
                    }

                    select {
                        color:#000;
                        font-family: Arial;
                        font-size: 14px !important;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                        padding-left: 14px;
                    }
                }
            }

            .form_submit_btn {
                max-width: 164px;
                width: 100%;
            }

        }

        #btn_validate_step_two {
            max-width: 164px;
            width: 100%;
            margin: 0 auto 30px;
            background-color: $ctaColor;
            color: $white;
        }

        #liaison_produits_warning {
            font-family: $primaryFont;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 20px;
        }

        /* STEP 3 */
        .date_choise_msg {
            position: relative;
            top: -5px;
            left: -4px;
            font-family: $primaryFont;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $primaryColor;
            margin-bottom: 20px;
        }

        .modify_step {
            display: block;
            font-family: $primaryFont;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            text-align: center;
            text-decoration: underline;
            line-height: normal;
            color: $primaryColor;
            margin: 10px auto 30px;
            text-underline-offset: 4px;
            cursor: pointer;
        }

        #step3 {
            .multidelivery_product_container {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                padding-bottom: 30px;

                &:not(:last-child) {
                    border-bottom: 2px solid $lightBorder;
                }

                &:nth-child(even) .infos_details .destination {
                    padding-top: 0;
                }
            }

            .delivery_address_container {
                display: flex;
                flex-direction: column;
                width: 44.84%;

                &::after {
                    display: none;
                }
            }

            .delivered_items_container {
                width: 48.69%;
                border: 2px solid $ctaColor;
                padding: 3.2% 4% 2% 5%;
            }

            .delivered_items_details {
                width: 100%;
                margin-bottom: 20px;
            }

            .infos_details {
                display: flex;
                flex-direction: column;
            }

            .items_details_content {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 20px;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                .product_image {
                    max-width: 96px;
                }

                img {
                    max-width: 96px;
                    width: 100%;
                }
            }

            .items_amount_details_content {
                display: flex;
                flex-direction: column;
                font-family: $primaryFont;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $primaryColor;

                .amount_sub_total,
                .delivery_fees,
                .cart_total {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .amount_sub_total{
                    margin-bottom: 11px;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }

                .delivery_fees {
                    position: relative;
                    margin-bottom: 43px;

                    &::after {
                        position: absolute;
                        bottom: -24px;
                        content: "";
                        display: block;
                        width: 100%;
                        height: 2px;
                        background-color: $lightBorder;
                    }
                }

                .cart_total .title {
                    font-weight: 700;
                }
            }

            .product_details {
                display: flex;
                flex-direction: column;
                font-family: $primaryFont;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $primaryColor;
                max-width: 125px;

                .name {
                    position: relative;
                    top: 7px;
                    margin-bottom: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .price_container {
                    margin-bottom: 15px;
                }

                .price {
                    display: flex;
                    position: relative;
                }

                .quantity {
                    color: $secondaryColor;
                    text-transform: lowercase;
                }
            }

            .destination,
            .client_infos,
            .full_address,
            .phone_number,
            .carrier_type,
            .delivery_conveyor_name {
                font-family: $primaryFont;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.2;
                color: $primaryColor;
            }

            .destination,
            .carrier_type {
                font-size: 16px;
                font-weight: 700;
            }

            .destination,
            .carrier_type {
                margin-bottom: 6px;
            }

            .destination {
                padding-top: 16px;
                margin-bottom: 7px;
            }

            .destination:nth-child(even) {
                padding-top: 3px !important;
            }

            .phone_number,
            .block-delivery-date {
                margin-bottom: 10px;
            }

            .block-delivery-date {
                position: relative;
                margin-top: 7px;

                &::after {
                    position: absolute;
                    top: 55%;
                    right: 21px;
                    content: "";
                    display: block;
                    width: 10px;
                    height: 21px;
                    background-size: 55%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url(../svg/arrow_next.svg);
                    transform: rotate(90deg);
                }

                input {
                    max-width: 325px;
                    width: 100%;
                }
            }

            .block-delivery-date-title {
                font-family: $ctaFont;
                font-size: 14px;
                font-style: normal;
                font-weight: 850;
                line-height: normal;
                letter-spacing: 2.8px;
                margin-bottom: 8.16px;
            }

            #btn_validate_step_three {
                max-width: 297px;
                width: 100%;
                margin: 0 auto;
                background-color: $ctaColor;
                color: $white;

                &.invalid_form {
                    opacity: 0.5;
                }
            }
        }

        #infos_livr_multiple {

            &.on_step_one {
                .step_container.first {
                    #return_to_step_one {
                        display: none;
                    }

                    &::after {
                        display: none;
                    }
                }

                .step_container.second {
                    .step_number {
                        opacity: 0.5;
                    }
                    #return_to_step_two {
                        display: none;
                    }

                    &::after {
                        display: none;
                    }
                }

                #liaison_produits_warning {
                    display: none;
                }

                .step_container.third {
                    .step_number  {
                        margin-top: 28px;
                        opacity: 0.5;
                    }

                    .date_choise_msg {
                        display: none;
                    }

                    .step_number_title {
                        margin-bottom: 27px;
                    }
                }
            }

            &.on_step_two {
                #return_to_step_one {
                    // override _app vue inline style
                    display: block !important;
                    margin: 10px auto 33px;
                }

                .step_number.step2 {
                    margin: 0 auto 7px;
                }

                .step_number_title.two {
                    margin-bottom: 15px;
                }

                .step_number_title.three {
                    margin-bottom: 28px;
                }

                .step_container.second {
                    #return_to_step_two {
                        display: none;
                    }

                    &::after {
                        display: none;
                    }
                }

                .step_container.third {
                    .step_number  {
                        opacity: 0.5;
                    }

                    .date_choise_msg {
                        display: none;
                    }
                }

                .warning_msg_two {
                    br:first-child {
                        display: none;
                    }
                }

                .checkbox {
                    top: 5.8%;
                }

                #liaison_produits_warning {
                    // override _app vue inline style
                    display: block !important;
                }
            }

            &.on_step_three {
                .checkbox {
                    top: 4.3%;
                }

                .step_container.first {
                    #return_to_step_one {
                        margin-bottom: 20px;
                    }

                    &::after {
                        display: none;
                    }
                }

                .step_container.second {
                    margin-top: 30px;
                }

                .step_container.third {
                    margin-bottom: 20px;
                }

                #liaison_produits_warning {
                    display: none;
                }

                #step3_content {
                    position: relative;
                    top: -3px;
                }

                // override _app vue inline style
                #return_to_step_one,
                #return_to_step_two,
                .date_choise_msg {
                    display: block !important;
                }
            }
        }

        .show_modif_info.show_multiple.button {
            text-transform: inherit;
        }
    }

    // loaders
    .form_submit_btn {
        max-width: 290px;
        width: 100%;
        margin: 0 auto;
    }
    .loader_container {
        position: relative;

        &.loading {
            button {
                opacity: 0;

                + .loader {
                    opacity: 1;
                }
            }
        }
    }
}

.question_section {
    font-family: $primaryFont;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 1.2;
    width: 96.8%;
    max-width: 770px;
    margin: 0 auto;
    color: $secondaryColor;

    a {
        text-decoration: underline;
    }
}

.multidelivery_shipping_fees {
    color: $secondaryColor;
    font-family: $primaryFont;
    font-size: fs-vw(12,1920);
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin: 0 10px;
}
