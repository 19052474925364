$primaryColor : #333333;
$secondaryColor : #666666;
$ctaColor : #ce7a51;
$backgroundColor : #faefe9;
$borderColor : #e6e6e6;
$whiteColor : #fff;
$borderGrey : #b1b1b1;
$placeholder : #868686;
$green : #04ac6c;
$red : #ff0000;
$grey : #868686;
$lighterGrey : #b1b1b1;
$black : #000000;
$separator : #ced2d8;
$titleColor : #d3794a;
$greyForm : #262626;
$lightBorder : #faefe9;
$dawnPink : #ececec;
$lightGrey : #d9d9d9;
$springWood : #fbf5f2;
$tumbleweed : #e4a78849;
$mercury : #e5e5e5;
$lemongrass : #999;
$platinum : #e0e0e0;
$desertSand : #ebcab9;
$white : $whiteColor;
$aquaHaze : #F3F3F3;
$runefangSteel : #c3c3c6;
$darklategray  : #2f4f4f;
$yellow : #DBB120;