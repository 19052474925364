body {
    .satellite_breadcrumbs_wrapper {
        .main_ariane {
            .breadcrumbs {
                margin-top: -3px;
                padding-left: 9px;
                color: $secondaryColor;
            }
        }
    }

    .wrapper_content_sat {
        font-family: $primaryFont;
        font-size: $fs_mid;
        margin: 0 auto;
        line-height: 18px;

        h1 {
            font-family: $primaryFont;
            margin-bottom: 0.3rem;
            font-style: italic;
            font-weight: 400;
            font-size: $fs_xlarge;
            text-align: center;
            display: block;
            margin: 30px auto;
            margin-bottom: 44px;
        }

        h2 {
            font-size: $fs_subtitle;
            margin-bottom: 20px;
            text-transform: initial;
        }

        ul {
            padding-left: 20px;
            margin-bottom: 15px;

            li {
                list-style: disc;
                line-height: 17px;
            }
        }

        .content {
            margin-bottom: 10px;
        }
    }
}

/* PAGE TEXTES */
.wrapper_content_sat.page_text {
    width: 100%;
    max-width: $wrappermidMedium;
    margin: 0 auto;

    .title span {
        font-family: $primaryFont;
        font-size: $fs_xlarge;
        font-weight: 400;
        line-height: 60px;
        text-transform: none;
    }

    .content_sat {
        margin-top: 32px;

        .row .content {
            &:first-child .sub_title {
                margin-top: 36px;
            }

            .sub_title {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-transform: uppercase;
                margin-bottom: 16px;
            }

            .content_inside {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 15.6px;
                color: $primaryColor;
            }
        }
    }
}

.entretien_wrapper {
    display: flex;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;

    img {
        width: 100%;
    }
}

.conseil {
    background-color: $backgroundColor;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;

    .texte_conseil {
        display: flex;
        justify-content: space-between;
        padding: 57px 0;
        width: calc(100% - 210px);
        margin: 0 96px;

        .title {
            text-transform: uppercase;
            font-family: $primaryFont;
            font-style: normal;
            font-size: 70px;
            line-height: 84px;
            margin-left: 0.85%;

            strong {
                width: 607px;
                font-weight: 500;
            }

            span {
                width: 602px;
                font-family: $secondaryFont;
                font-style: normal;
                font-weight: 400;
            }
        }

        p {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            width: 766px;
            margin-top: 5px;
        }
    }
}

.content_conseil {
    max-width: 1920px;
    margin: 0 auto;

    #row {
        padding: 87px 0 94px 12%;

        .accordion_title {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 50px;
            line-height: 60px;
            text-decoration: underline;
            text-underline-offset: 2px;
            text-transform: uppercase;
            position: relative;
            padding-left: 4.5rem;
            cursor: pointer;

            @media screen and (max-width: 1377px) {
                font-size: 36px;
            }

            &.opened {
                text-decoration: none;
            }

            .accordion_num {
                position: absolute;
                left: 0;
                top: -1.15rem;
                font-size: 24px !important;
                text-decoration: none !important;
                padding-right: 44px;
                margin-top: 23px;
                font-weight: 500;
            }
        }

        .accordion_text {
            margin-top: 2rem;
            padding-left: 7.5rem;
            margin-bottom: -22px;
            display: none;
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;

            .bloc_img {
                position: relative;
                display: flex;
                justify-content: space-between;
                padding-left: 25px;
                padding-bottom: 51px;
                width: 100%;
                max-width: 762px;
                box-sizing: border-box;

                img {
                    margin-top: -8px;
                }

                .img_2,
                .img_3 {
                    padding-left: 16px;
                }

                span {
                    position: absolute;
                    display: block;
                    text-align: center;
                    top: 61px;
                    margin-left: -30px;
                    margin-top: 3px;
                    width: 17%;
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }

        .bloc_1 {
            width: 1069px;
            margin-left: 3px;

            @media screen and (max-width: 1246px) {
                width: 900px;
            }

            @media screen and (max-width: 1046px) {
                width: 817px;
            }

            .esso {
                span {
                    margin-left: -12px;
                    margin-top: -1px;
                }
            }

            .sac {
                span {
                    margin-left: -13px;
                    margin-top: 0px;
                }
            }

            .less {
                span {
                    margin-left: -27px;
                    margin-top: 0px;
                }
            }
        }

        .bloc_2 {
            max-width: 1194px;
            margin: 41px 0 0 3px;

            @media screen and (max-width: 1695px) {
                max-width: 1011px;
            }

            @media screen and (max-width: 1246px) {
                width: 900px;
            }

            @media screen and (max-width: 1046px) {
                width: 818px;
            }
        }

        .bloc_3 {
            width: 1470px;
            margin: 41px 0 0 3px;

            @media screen and (max-width: 1695px) {
                width: 1193px;
            }

            @media screen and (max-width: 1377px) {
                width: 1076px;
            }

            @media screen and (max-width: 1246px) {
                width: 900px;
            }

            @media screen and (max-width: 1046px) {
                width: 818px;
            }
        }

        .bloc_4 {
            width: 1202px;
            margin: 40px 0 0 3px;

            @media screen and (max-width: 1386px) {
                width: 1079px;
            }

            @media screen and (max-width: 1246px) {
                width: 900px;
            }

            @media screen and (max-width: 1046px) {
                width: 825px;
            }
        }
    }
}

.content_box {
    max-width: 1920px;
    width: 100%;
    background: $backgroundColor;

    .texte {
        font-family: $primaryFont;
        font-style: normal;
        font-size: 40px;
        line-height: 48px;
        display: flex;
        justify-content: space-between;
        padding: 69px 5.39%;
        margin-right: 23px;

        .brief {
            width: 649px;
            margin-left: 9px;
            font-weight: 500;
        }

        p {
            width: 748px;
            font-weight: 500;
            margin-right: 16px;
        }
    }
}

.produit {
    width: 100%;
    max-width: 1680px;
    padding: 79px 6.25% 0;
    margin: 0 auto;
    box-sizing: border-box;

    p {
        font-family: $secondaryFont;
        font-style: italic;
        font-weight: 400;
        font-size: 50px;
        line-height: 96px;
        text-align: center;
        margin-bottom: 54px;
    }

    .produit_img {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1618px;
        margin: 0 auto;

        .bloc {
            width: calc((100% - 388px) / 3);
            position: relative;

            img {
                width: 100%;
            }
        }

        a.link_plus {
            display: block;
            position: absolute;
            width: 30px;
            height: 30px;

            .pastille {
                position: initial;
            }
        }

        .bloc_1 {
            a.link_plus {
                top: 36%;
                left: 59%;
            }

            span {
                position: absolute;
                font-weight: 500;
                font-size: 150px;
                top: -84px;
                left: -55px;
            }

            @media only screen and (max-width: 1500px) {
                span {
                    font-size: 130px;
                    top: -74px;
                    left: -52px;
                }
            }

            @media only screen and (max-width: 1265px) {
                span {
                    font-size: 109px;
                    top: -62px;
                    left: -44px;
                }
            }

            @media only screen and (max-width: 1024px) {
                span {
                    font-size: 74px;
                    top: -42px;
                    left: -28px;
                }
            }
        }

        .bloc_2 {
            a.link_plus {
                top: 19%;
                left: 51%;
            }

            span {
                position: absolute;
                width: 250px;
                bottom: -73px;
                left: -60px;
                font-weight: 500;
                font-size: 150px;
                line-height: 192px;
            }

            @media only screen and (max-width: 1500px) {
                span {
                    font-size: 130px;
                    bottom: -77px;
                    left: -50px;
                }
            }

            @media only screen and (max-width: 1265px) {
                span {
                    font-size: 109px;
                    bottom: -79px;
                    left: -45px;
                }
            }

            @media only screen and (max-width: 1024px) {
                span {
                    font-size: 74px;
                    bottom: -82px;
                    left: -31px;
                }
            }
        }

        .bloc_3 {
            a.link_plus {
                top: 64%;
                left: 28%;
            }

            span {
                position: absolute;
                font-weight: 500;
                font-size: 150px;
                top: -78px;
                left: -61px;
            }

            @media only screen and (max-width: 1500px) {
                span {
                    font-size: 130px;
                    top: -78px;
                    left: -50px;
                }
            }

            @media only screen and (max-width: 1265px) {
                span {
                    font-size: 109px;
                    top: -57px;
                    left: -42px;
                }
            }

            @media only screen and (max-width: 1024px) {
                span {
                    font-size: 74px;
                    top: -40px;
                    left: -28px;
                }
            }
        }
    }
}

.question {
    position: relative;
    width: 312px;
    font-family: $secondaryFont;
    font-style: italic;
    font-weight: 400;
    font-size: 50px;
    margin: 133px 0 0 41.3%;

    p {
        width: 328px;
        font-family: $secondaryFont;
        font-style: italic;
        font-weight: 400;
        font-size: 50px;
        text-align: center;
    }
}

// PAGE RECYCLEZ
body.recycle,
body.recycling {
    .recycle_top {
        width: 100%;
        max-width: 1920px;
        margin: -13px auto;

        .recycle {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 220px;
            line-height: 200px;
            text-align: center;
            text-transform: uppercase;
            color: $green;
            padding-bottom: 2%;

            @media screen and (max-width: 1405px) {
                font-size: 190px;
            }

            @media screen and (max-width: 1226px) {
                font-size: 156px;
            }

            @media screen and (max-width: 1024px) {
                font-size: 157px;
            }

            span {
                width: 100%;
                font-family: $secondaryFont;
            }
        }

        p {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            text-transform: uppercase;
            padding: 0 2%;

            span {
                font-weight: 500;
            }
        }
    }

    .button_up {
        position: fixed;
        width: 24px;
        height: 24px;
        top: 92vh;
        right: 34px;
        opacity: 1;
        background-color: $primaryColor;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;

        &.show {
            opacity: 1;
            z-index: 3;
        }

        .btn_up {
            position: relative;
            cursor: pointer;
            width: 24px;
            height: 24px;
            background-color: $whiteColor;
            mask-image: url(../svg/spritesheet.svg);
            mask-repeat: no-repeat;
            mask-position: 18.5% 39.5%;
            mask-size: 500%;
            transform: rotate(270deg);
        }
    }

    .image {
        margin-left: 108px;
        margin-top: 77px;

        img {
            width: 100%;
            max-width: 1680px;
            padding-top: 0.3%;
            box-sizing: border-box;
        }
    }

    .recycle_texte {
        margin: 73px 12px;

        p {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            text-transform: uppercase;
        }

        .para {
            font-family: $secondaryFont;
            font-style: italic;
            font-weight: 400;
            font-size: 50px;
            line-height: 49px;
            text-align: center;
            padding-top: 32px;
            text-transform: none;

            span {
                font-weight: 500;
                font-style: normal;
            }
        }
    }

    .recyclez {
        padding: 38px 0;
        max-width: 1920px;
        margin: 0 auto;
        box-sizing: border-box;
        background-color: $green;
        display: flex;
        justify-content: center;
    }

    .slide {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 84px;
        width: 100%;
        max-width: 1920px;
        overflow: hidden;

        .etape_recycler {
            width: 100%;
            max-width: 403px;
            margin: -72px 56px 0 117px;

            h2 {
                font-family: $secondaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 60px;
                line-height: 60px;
                text-align: justify;

                span {
                    font-style: italic;
                }
            }

            p {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                padding-top: 25px;
                line-height: 16px;
            }

            .savoir {
                position: relative;
                text-decoration: underline;
                font-family: MillerBanner;
                font-size: 24px;
                line-height: 29px;
                top: 40px;
                font-style: italic;
            }
        }

        #swiper-container {
            overflow: hidden;
            width: 100%;
            max-width: 1730px;
        }

        #swiper-container .swiper-wrapper {
            width: 100%;
            display: flex;
            overflow: hidden;
        }

        #swiper-container .swiper-slide {
            margin: 0;
            width: 550px;
            margin-right: 40px;
        }

        .swiper-pagination.swiper-pagination-progressbar {
            position: absolute;
            top: auto;
            bottom: 0;
        }

        .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
            background-color: $black  !important;
        }

        .swiper-wrapper {
            padding-bottom: 45px;
            margin-top: 33px !important;
        }

        .swiper-slide {
            padding-right: 4px;

            .texte {
                width: 94%;

                h2 {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 41px;
                    padding-top: 7px;
                }

                span {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                }
            }

            .number {
                position: absolute;
                width: 44px;
                height: 29px;
                left: 0px;
                top: -34px;
                font-family: 'Graphik';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                text-align: justify;
            }

            .grid-image {
                width: 100%;
                max-width: 550px;
                padding-top: 14px;
            }
        }
    }

    .bloc {
        max-width: 1920px;
        margin: 121px auto 0;
        background: $green;
        height: 344px;
        display: flex;
        justify-content: space-evenly;

        .tonne {
            color: $whiteColor;
            padding-left: 23px;
            padding-top: 84px;

            h2 {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 70px;
                line-height: 59%;
                display: grid;

                span {
                    font-weight: 300;
                    font-size: 37px;
                    letter-spacing: 2px;
                    margin-top: 6.3%;
                }
            }

            p {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                padding-top: 20px;
                width: 244px;
            }
        }
    }

    .collective {
        .texts {
            padding: 74px 0;
            max-width: 1920px;
            width: 100%;
            margin: 0 auto;

            .cause {
                display: flex;
                justify-content: space-between;

                h2 {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 40px;
                    line-height: 48px;
                    text-align: justify;
                    padding-left: 11.5%;
                    width: 555px;
                }

                p {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: justify;
                    max-width: 750px;
                    width: 100%;
                    margin-right: 12.6%;
                    margin-top: 30px;

                    span {
                        color: $green;
                    }
                }
            }

            .engagement {
                display: flex;
                justify-content: space-between;
                margin-top: 40px;

                h2 {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 40px;
                    line-height: 48px;
                    text-align: justify;
                    width: 555px;
                    padding: 1.8% 0 0 11.5%;
                }

                p {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: justify;
                    max-width: 750px;
                    width: 100%;
                    margin-right: 12.6%;
                    padding-top: 3.4%;

                    span {
                        color: $green;
                    }
                }

                &:before {
                    position: absolute;
                    content: '';
                    height: 2px;
                    background-color: $primaryColor;
                    width: 88.3%;
                    left: 5.4%;
                    top: auto;
                    bottom: auto;
                }
            }
        }

        .collective_img {
            width: 100%;
            max-width: 1440px;
            padding-top: 0.3%;
            margin: 0 auto;
            box-sizing: border-box;

            img {
                width: 100%;
            }
        }

        .decouvrir {
            text-align: -webkit-center;
        }
    }

    .slogan {
        width: 100%;
        max-width: 1920px;
        color: $green;
        padding-top: 4.2%;

        p {
            font-family: $secondaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: 50px;
            line-height: 60px;
            text-align: center;
            text-transform: uppercase;

            span {
                text-transform: lowercase;
                font-style: italic;
            }
        }
    }
}

/* Page Cookies */
.misc_cookiesinfo {
    .title {
        margin-bottom: 2.35rem;
    }

    .big_title_charte {
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        margin-bottom: 0.95rem;
    }

    .content_inside {
        margin-bottom: 2.1rem;

        ul {
            list-style-type: '- ';
            padding-left: 1rem;

            li {
                line-height: 1.5;

                a {
                    display: inline-block;
                    margin-top: 0.25rem;
                    margin-bottom: 0.25rem;

                    &:first-of-type {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }

    #cookiesLightbox {
        &.displayCookies {
            display: block!important;
        }

        &.hideCookies {
            display: none!important;
        }

        .w-form-line.w-submit button.w-loader.hideLoader {
            z-index: -1;
            opacity: 0;
        }
    }
}

// PAGE LAMARQUE
body.la_marque,
body.the_brand {
    width: 100%;
    min-width: 1007px;

    .logo_marque {
        max-width: 900px;
        margin: 0 auto;

        img {
            width: 100%;
        }

        p {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            padding-top: 4%;
        }
    }

    .manifeste {
        font-weight: 400;
        font-size: 80px;
        line-height: 77px;
        max-width: 1344px;
        width: 100%;
        text-align: center;
        margin: 0 auto;
        padding-top: 49px;

        p {
            font-style: normal;
            font-family: $secondaryFont;

            .italic {
                font-style: italic;
            }

            .bold {
                font-weight: 500;
                font-family: $primaryFont;
            }

            .sep_1,
            .sep_2,
            .sep_3 {
                display: inline-flex;
                align-items: flex-end;
                width: 128px;
                height: 58px;
                position: relative;

                &::after {
                    content: ' ';
                    display: block;
                    width: 128px;
                    height: 3px;
                    background-color: $primaryColor;
                    position: absolute;
                    top: 55%;
                }
            }

            .sep_2 {
                width: 60px;

                &:after {
                    content: '';
                    width: 60px;
                }
            }

            .sep_3 {
                width: 89px;
                height: 36px;

                &:after {
                    content: '';
                    width: 89px;
                }
            }
        }
    }

    .home-module {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 80px;
        max-width: 1014px;
        margin: 0 auto;
        padding-top: 4.3%;

        a.home-module-cta {
            color: $primaryColor;
        }

        .menu {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            text-decoration-line: underline;
            text-transform: uppercase;
        }
    }

    .button_up {
        position: fixed;
        width: 24px;
        height: 24px;
        top: 92vh;
        right: 34px;
        opacity: 1;
        background-color: $primaryColor;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;

        &.show {
            opacity: 1;
            z-index: 3;
        }

        .btn_up {
            position: relative;
            cursor: pointer;
            width: 24px;
            height: 24px;
            background-color: $whiteColor;
            mask-image: url(../svg/spritesheet.svg);
            mask-repeat: no-repeat;
            mask-position: 18.5% 39.5%;
            mask-size: 500%;
            transform: rotate(270deg);
        }
    }

    .bloc_1,
    .bloc_2,
    .bloc_3,
    .bloc_5 {
        .creative {
            display: flex;
            width: 100%;

            .creatif {
                img {
                    width: 100%;
                }
            }

            .creatif_1 {
                .num {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                    text-decoration: none !important;
                }

                h2 {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 80px;
                    line-height: 96px;
                    text-transform: uppercase;
                }

                p {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: justify;
                    padding-top: 32px;
                }
            }
        }

        .creatif_2 {
            width: 100%;
            max-width: 1306px;
            margin: 0 auto;
            padding-top: 2.1%;

            p {
                font-family: $secondaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 50px;
                line-height: 60px;
                text-align: center;
                text-transform: uppercase;
                margin-left: -9px;

                span {
                    text-transform: lowercase;
                    font-style: italic;
                }
            }
        }
    }

    .bloc_1 {
        .creative {
            padding-top: 4.4%;

            .creatif {
                margin-left: 12.1%;
                width: 100%;
                max-width: 840px;

                img {
                    width: 133%;
                }

                span {
                    img {
                        margin-top: -117px;
                        margin-left: -121px;
                        max-width: 51.1%;

                        @media screen and (max-width: 1440px) {
                            margin-top: -84px;
                            margin-left: -77px;
                        }

                        @media screen and (max-width: 1270px) {
                            margin-top: -68px;
                            margin-left: -55px;
                        }

                        @media screen and (max-width: 1130px) {
                            margin-top: -47px;
                            margin-left: -39px;
                        }
                    }
                }
            }

            .creatif_1 {
                margin-left: 15.3%;
                padding-top: 2%;
                padding-right: 6%;
                width: 100%;
                max-width: 840px;
            }
        }
    }

    .bloc_2 {
        .creative {
            padding-top: 4.2%;

            .creatif {
                margin-left: 2.3%;
                padding-right: 116px;
                width: 67.2%;

                span {
                    img {
                        margin-top: -114px;
                        margin-left: 372px;
                        max-width: 38.1%;

                        @media screen and (max-width: 1620px) {
                            margin-top: -112px;
                            margin-left: 335px;
                        }

                        @media screen and (max-width: 1500px) {
                            margin-top: -81px;
                            margin-left: 299px;
                        }

                        @media screen and (max-width: 1426px) {
                            margin-top: -65px;
                            margin-left: 252px;
                        }

                        @media screen and (max-width: 1260px) {
                            margin-top: -65px;
                            margin-left: 216px;
                        }

                        @media screen and (max-width: 1084px) {
                            margin-top: -50px;
                            margin-left: 192px;
                        }
                    }
                }
            }
        }

        .creatif_1 {
            max-width: 985px;
            width: 100%;
            padding-left: 5.6% !important;
            padding-top: 26.2% !important;
            box-sizing: border-box;

            p {
                padding-bottom: 40px !important;
                width: 72.8%;
            }
        }

        .creatif_2 {
            max-width: 1370px;
            padding-top: 2.4%;

            p {
                font-family: $secondaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 50px;
                line-height: 49px;
                text-align: center;
                text-transform: uppercase;
                margin-left: 13px;
            }
        }
    }

    .bloc_3 {
        .creative {
            padding-top: 4.3%;

            .creatif {
                margin-left: 118px;
                max-width: 66%;
            }

            .creatif_1 {
                padding-top: 6.1%;
                max-width: 464px;
                width: 100%;
                margin-left: -2.1%;
                padding-right: 6%;

                @media screen and (max-width: 1504px) {
                    margin-left: 1.9%;
                }

                .duo {
                    width: 99%;
                    line-height: 16px;

                    span {
                        font-style: normal;
                        font-weight: 600;
                        text-decoration: none;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }

        .creatif_2 {
            max-width: 1680px;
            width: 100%;
            padding-top: 4.5% !important;

            h3 {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
                text-transform: uppercase;
            }

            p {
                font-family: $primaryFont;
                font-size: 100px;
                line-height: 80px;
                font-weight: 500;
                text-transform: capitalize;
                padding-top: 71px;

                .ita {
                    font-family: $secondaryFont;
                    font-style: italic;
                    text-transform: uppercase;
                    font-weight: 400;
                }

                .normal {
                    font-style: normal;
                    font-weight: 400;
                    text-transform: uppercase;
                    font-family: $secondaryFont;
                }

                .bord_1,
                .bord_2,
                .bord_3,
                .bord_4 {
                    display: inline-flex;
                    align-items: flex-end;
                    width: 128px;
                    height: 58px;
                    position: relative;

                    &::after {
                        content: ' ';
                        display: block;
                        width: 128px;
                        height: 3px;
                        background-color: $primaryColor;
                        position: absolute;
                        top: 55%;
                    }
                }

                .bord_1 {
                    width: 134px;

                    &:after {
                        content: '';
                        width: 134px;
                    }
                }

                .bord_2 {
                    width: 99px;

                    &:after {
                        content: '';
                        width: 99px;
                    }
                }

                .bord_3 {
                    width: 67px;

                    &:after {
                        content: '';
                        width: 67px;
                    }
                }

                .bord_4 {
                    width: 83px;

                    &:after {
                        content: '';
                        width: 83px;
                    }
                }
            }
        }
    }

    .bloc_4 {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;

        .marque_wrapper {
            padding-top: 86px;

            img {
                width: 100%;
            }
        }

        .marque {
            display: flex;
            padding: 37px 5.8% 0;
            box-sizing: border-box;

            .wrapper_header {
                .num {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                }

                h2 {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 80px;
                    line-height: 96px;
                    text-transform: uppercase;
                }
            }

            .wrapper_texte {
                max-width: 748px;
                width: 100%;
                padding-top: 161px;
                padding-right: 2px;
                box-sizing: border-box;

                p {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: justify;
                    margin-left: -6px;
                    padding-bottom: 40px;
                }

                span {
                    font-family: $secondaryFont;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    text-decoration: underline;
                    color: $primaryColor;
                }
            }
        }
    }

    .bloc_5 {
        .creative {
            padding-top: 4.4%;

            .creatif {
                margin-left: 5.1%;
                width: 45%;

                img {
                    width: 100%;
                }
            }

            .creatif_1 {
                padding-top: 19.6%;
                padding-left: 4%;
                width: 40.01%;

                p {
                    padding-bottom: 42px;
                }

                a {
                    text-decoration: none !important;
                }

                span {
                    font-style: italic;
                    font-family: $secondaryFont;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    text-decoration-line: underline;
                    text-underline-offset: 6px;
                    color: $primaryColor;
                }
            }
        }
    }

    .collection {
        text-align: -webkit-center;
    }
}