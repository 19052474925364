/**
 * Payment general styles
 */
.bank_left_side {
    width: 62%;
    max-width: 797px;
    padding-top: 8px;
}

.bank_right_side {
    width: 35%;
    max-width: 445px;
}

/**
 * Payment types
 */
.wrapper_basket_content .bank_left_side .wrapper_bloc_banque {
    // _app override
    margin-bottom: 3.2rem;
    padding: 2.2rem 1.4rem 1rem;

    &.ideal {
        padding-bottom: 3rem;
    }

    &.flexco {
        padding: 1.3rem 1.4rem 1rem;
    }
}

.wrapper_bloc_banque {
    position: relative;
    width: 97%;
    margin: 0 0 0 2%;
    border: 1px solid $lighterGrey;
    transition: border 0.3s ease-in-out;

    &:not(.credit_card) {
        &.isLoading {
            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
                display: block;
                width: 95%;
                height: 96%;
                background-color: rgba(255, 255, 255 ,0.6);
                z-index: 2;
                
            }
            &::after {
                position: absolute;
                top: 55%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
                display: block;
                background: $whiteColor url(../svg/loader.svg) no-repeat center;
                background-size: auto 100%;
                width: 97%;
                height: 60%;
                z-index: 3;
            }     
        }
    }

    .radio {
        display: block !important;
        position: absolute;
        top: 50%;
        left: -7.5px;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        border: 1px solid $lighterGrey;
        border-radius: 50%;
        background-color: $whiteColor;
        box-shadow: inset 0 0 0 2px $whiteColor;
        transition: all 0.3s ease-in-out;
    }

    &:hover,
    &.actif {
        border-color: $ctaColor;

        .radio {
            background-color: $ctaColor;
            border-color: $ctaColor;
        }
    }

    .bank_title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 2rem;
        padding: 0 2rem;
        background-color: $whiteColor;
        font-size: 24px;
        font-weight: 400;
        z-index: 4;

        .input-img-paypal {
            display: none;
        }
    }

    .w-giftcard-form .w-form-line.giftcard-intro {
        position: initial;
        justify-content: center;

        .w-submit-button {
            border: 0;
            text-decoration: underline;
            color: $primaryColor;
            font-weight: 400;
            letter-spacing: 0;
            font-family: $primaryFont;
            text-transform: initial;
            font-size: 15px;

            &:hover {
                background-color: $white;
                 text-decoration: none;
            }
        }
    }

    .text_info {
        color: $red;
        margin: 1rem 0 0;
        font-size: 0.85rem;
    }
}

/**
 * Gift card
 */
.w-giftcard-form {
    .giftcard_desc {
        font-size: 14px;
    }

    .w-input-element:not([type='radio']):not([type='checkbox']),
    input[type='text'].w-input-element {
        box-sizing: border-box;
        border-radius: 0;
    }
}

/**
 * Credit card
 */
.flexco {

    .bank_subtitle,
    .total_transaction {
        font-size: 15px;
        line-height: 21px;

        & > span {
            &:after {
                content: ':';
                height: 5px;
                width: 5px;
            }

            &.pricetag {
                &:after {
                    content: '';
                    height: 5px;
                    width: 5px;
                }
            }
        }

        strong {
            font-weight: 400;
        }

        img {
            vertical-align: -2px;
        }
    }

    .cards-wrapper {
        margin: 12px 0 19px;

        img {
            width: auto;
            height: 30px;
            vertical-align: top;

            &#inputAMEX_big {
                height: 28px;
            }
        }
    }
}

/**
 * PayPal
 */
.paypal {

    .title_wrapper {
        width: 100%;
        font-size: 15px;
        text-align: center;
        text-decoration: underline;

        strong {
            font-weight: 400;
        }
    }

    input#inputPAYPAL {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    &:hover .title_wrapper {
        text-decoration: none;
    }
}

/**
 * IDEAL
 */

.wrapper_bloc_banque.ideal {
    &:hover {
        .bank_subtitle {
            text-decoration: none;
        }
    }

    .bank_subtitle {
        position: absolute;
        bottom: 10px;
        left: 50%;
        bottom: 1.5rem;
        transform: translate(-50%, 50%);
        text-decoration: underline;
    }
}

/**
 * Payment right side
 */
.wrapper_payment {
    padding-top: 0.4rem;

    .payment_addr_wrapper {
        position: relative;
        margin-bottom: 1.6rem;
        padding: 15px;
        &:nth-child(3){
            background: #FAEFE9;
        }

        &.delivery {
            width: 100%;

            .title {
                width: 90%;
            }
        }
    }

    .payment_warning_msg {
        font-family: $primaryFont;
        line-height: 1.2;
        color: $primaryColor;
        margin-bottom: 1.5rem;
    }

    .title {
        font-size: 24px;
        margin-bottom: 0.7rem;
    }

    .address {
        font-size: 15px;
        line-height: 1.2;
    }

    a.address_mod {
        position: absolute;
        right: 15px;
        bottom: 15px;
        font-size: 15px;
        text-decoration: underline;
        text-transform: lowercase;

        &:hover {
            text-decoration: none;
        }
    }
}

#wrapper_payement_secur {
    .recap_cart_title {
        font-family: $ctaFont;
        font-size: 14px;
        text-transform: lowercase;
        letter-spacing: 0.2rem;
        margin-bottom: 1rem;
    }

    .tunel_paiement_right_description {
        line-height: 1.2;
    }
}

/**
 * Payment validated
 */
body.bankThanks {
    nav.tunnel_track {
        margin: 23px auto 27px;
    }
}

.thanksWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1680px;
    height: 700px;
    margin: 5rem auto 4rem;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        max-width: 840px;
        padding-bottom: 700px;
        background: transparent url(../img/remerciement.jpg) no-repeat center;
        background-size: cover;
    }

    .bankThanksTop {
        width: 42%;
        max-width: 670px;
        margin: 0 0 0 55%;

        #around_me_map {
            display: none;
        }

        h1 {
            flex-direction: column;
            align-items: flex-start;
            font-size: 60px;
            font-weight: 400;
            font-style: italic;
            letter-spacing: initial;

            span {
                font-size: 24px;
                font-style: initial;
                margin-top: 0.6rem;
            }

            &:before {
                display: none;
            }
        }
    }

    .thanksRecap {
        width: 42%;
        max-width: 670px;
        margin: 0 0 0 55%;
        flex-direction: column;

        .thanksLeft,
        .thanksRight {
            width: 100%;
        }

        .thanksLeft {
            .seeOrderEvo {
                line-height: 1.2;

                &.multidestination {
                    margin-bottom: 0;

                    + p {
                        margin-bottom: 14px;
                    }
                }
            }

            .thanksTrust {
                display: none;
            }
        }

        .thanksRight {
            background-color: $lightBorder;
            text-align: left;
            margin-top: 1rem;
            padding: 2.5rem 0 1.5rem 3rem;

            p {
                font-size: 15px;
                margin-bottom: 0.4rem;

                &.txt_thanks_transaction {
                    margin: 1.8rem 0 0 0;
                    text-transform: initial;
                }
            }
        }

        .w-form-line {
            margin-bottom: 1.5rem;
        }

        .w-input-element:not([type='radio']):not([type='checkbox']),
        input[type='password'].w-input-element {
            box-sizing: border-box;
            border-radius: 0;
        }

        a.bankThanksBtn {
            width: fit-content;
            padding: 0 3em;
        }
    }
}

body {
    &.checkout {
        padding-top: 0;

        .wrap_reassurance {
            display: none;
        }

        .w-submit-button {
            &.cancel {
                background-color: #ce7a51;
                color: #ffffff;
                border-color: #ce7a51;
                transition: background-color .2s ease-out;

                &:hover {
                    background-color: #fff;
                    color: #ce7a51;
                    border-color: #ce7a51;
                }
            }
        }

        .giftcard_total_amount {
            line-height: 1.1rem;
        }
    }
}