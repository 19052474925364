body {
    &.cms_page,
    &.product_page,
    &.cms_page_new {
        .menu_wrapper {
            display: none;
        }

        #cms_page_btn_back {
            display: none;
        }

        .breadcrumbs {
            margin-bottom: 0.375rem;
            margin-top: -2px;
        }

        .page_title {
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
            background-color: $backgroundColor;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 360px;

            .title {
                width: 96%;
                max-width: 1280px;
                margin: 0 auto;
                font-family: $primaryFont;
                font-style: italic;
                font-size: 3.75rem;
            }
        }

        //
        .cms_page {

            &.cms_subcategory_19,
            &.cms_subcategory_6,
            &.cms_subcategory_4,
            &.cms_subcategory_5,
            &.cms_subcategory_7,
            &.cms_subcategory_8,
            &.cms_subcategory_9,
            &.cms_subcategory_2,
            &.cms_subcategory_20,
            &.cms_subcategory_11,
            &.cms_subcategory_10,
            &.cms_subcategory_12,
            &.cms_subcategory_13,
            &.cms_subcategory_14,
            &.cms_subcategory_15,
            &.cms_subcategory_16 {

                #cms_page_ariane {
                    .breadcrumbs {
                        .breadcrumb {
                            &:nth-child(2) {
                                display: none;
                            }
                        }

                    }
                }

                @media only screen and (max-width: 1200px) {
                    #cms_page_ariane {
                        margin-top: 10px;
                    }

                }

            }
        }

        .cms_page {

            &.cms_category_1,
            &.cms_category_2 {
                #cms_page_ariane {
                    .breadcrumbs {
                        .breadcrumb {
                            &:nth-child(2) {
                                display: none;
                            }
                        }

                    }
                }

                @media only screen and (max-width: 1200px) {
                    #cms_page_ariane {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {

        &.cms_page,
        &.product_page,
        &.cms_page_new {
            .page_title {
                min-height: 220px;

                .title {
                    font-size: 3.05rem;
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {

        &.cms_page,
        &.product_page,
        &.cms_page_new {
            .page_title {
                min-height: 220px;

                .title {
                    font-size: 3.05rem;
                }
            }
        }
    }

    #product_cms_bloc {
        position: relative;

        &.cms-page-icons {
            .text-image {
                position: relative;

                :after {
                    content: '';
                    background: transparent url(../svg/losange.svg) no-repeat center;
                    background-size: contain;
                    width: 70px;
                    height: 16px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    max-width: 70px;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .cms_page_content {
        padding-top: 40px;

        .cms-page-module-body .cms-page-module-contents {
            .right, 
            .left {
                .slide_video {
                    iframe {
                        height: 100%;
                        width: 100%;
                        aspect-ratio: auto 1 / 1;
                    }
                }
            }
        }
    }

    .cms_page_content,
    .cms-page-icons {
        .cms-page-module {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 98%;
            max-width: $wrappermidMedium;
            margin: 0 auto;
            padding: 40px 0;

            .cms-page-module-link {
                transition: all ease-in 250ms;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                min-width: 246px;
                border: 1px solid;
                padding: 16px 42px;
                max-width: 349px;
                margin: auto;

                &:hover {
                    border: 1px solid rgba(206, 122, 81, 0.8) !important;
                    ;
                    background: rgba(206, 122, 81, 0.8);
                    box-shadow: 0 0 0 2px rgba(250, 250, 250, 0.7) inset;

                    .cms-page-module-cta {
                        transition: color ease-in 250ms;
                        color: $whiteColor !important;
                    }
                }

            }

            /* Module 1 -  2 Images 1 Texte */
            &.text-image {
                padding: 40px 0 85px;

                .cms-page-columns-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    article {
                        width: 30%;

                        img {
                            width: 100%;
                            height: auto;
                            vertical-align: top;
                        }

                        &:nth-child(2) {
                            margin-left: 6px;
                        }
                        .slide_video {
                                a {
                                    display: block;
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    padding-top: calc(350/350*100%);
                                    
                                }
                                iframe {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    z-index: -1;
                                }
                            }
                    }

                    .cms-page-module-contents {
                        padding-left: 4.3%;
                        width: calc(40% - 6px);

                        .cms-page-module-title {
                            font-family: $primaryFont;
                            font-size: $fs_xlarge;
                            font-style: italic;
                            line-height: 1;
                            color: $primaryColor;
                            margin-bottom: 5px;
                            text-align: left;

                            @media only screen and (max-width: 1360px) {
                                font-size: $fs_large;
                            }
                        }

                        .cms-page-module-text {
                            font-family: $primaryFont;
                            font-weight: 400;
                            font-size: $fs_mid;
                            line-height: 18px;
                        }

                        .cms-page-module-link {
                            margin-top: 25px;
                            border: 1px solid $ctaColor;

                            &.button {
                                max-width: 198px;
                            }
                        }
                    }
                }
            }

            // Module 2 - 1 image
            &.text-image-1 {
                padding: 70px 0 40px;

                .cms-page-module-body {
                    width: 100%;

                    .cms-page-module-contents {
                        width: 100%;
                    }
                }

                .cms-page-columns-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    .cms-page-module-contents {
                        article {
                            position: relative;

                            .picture {
                                .cms-page-module-img {
                                    max-width: 100%;
                                    height: auto;
                                    display: block;
                                }
                            }

                            .slide_video {
                                display: flex;
                                position: relative;
                                height: 0;
                                width: 100%;
                                z-index: 1;
                                padding-bottom: 42.969%;

                                iframe {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 1;
                                    transform: unset;
                                }
                            }

                            .content {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                max-width: 800px;
                                width: 100%;
                                flex-direction: column;
                                z-index: 2;

                                .cms-page-module-title {
                                    font-family: $primaryFont;
                                    font-style: italic;
                                    font-weight: 400;
                                    font-size: 40px;
                                    line-height: 40px;
                                    color: $whiteColor;
                                    margin-bottom: 33px;
                                    text-align: center;
                                }

                                .cms-page-module-link {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    min-width: 246px;
                                    border: 1px solid;
                                    padding: 16px 42px;
                                    color: $whiteColor;

                                    .cms-page-module-cta {
                                        font-family: $ctaFont;
                                        font-style: normal;
                                        font-weight: 850;
                                        font-size: $fs_mid;
                                        line-height: 17px;
                                        text-align: center;
                                        letter-spacing: 0.2em;
                                        text-transform: lowercase;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Module 3 - 2 Images 2 Textes
            &.text-image-2 {
                .cms-page-module-body {
                    width: 100%;
                }
                
                .cms-page-columns-container {
                    .cms-page-module-contents {
                        article {
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            /* flex-wrap: wrap; */
                            flex-direction: row;
                        }

                        .cms-page-module-text {
                            padding: 20px 0 0;
                        }

                        .left {
                            width: 50.781%;
                            padding-left: 6.25%;
                            padding-right: 5.07%;

                            .picture {
                                max-width: 530px;

                                .cms-page-module-img {
                                    max-width: 100%;
                                    height: auto;
                                }

                            }

                            .cms-page-module-title {
                                font-family: $primaryFont;
                                font-style: italic;
                                font-weight: 400;
                                font-size: 40px;
                                line-height: 40px;
                                color: $primaryColor;
                                margin-bottom: 5px;
                            }

                            .cms-page-module-text {
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $fs_txt;
                                line-height: 22px;
                                color: $primaryColor;
                            }

                            .slide_video {
                                display: flex;
                                position: relative;
                                height: 0;
                                width: 100%;
                                padding-bottom: 100%;

                                iframe {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                }

                                a.container_video + a.cta_video_redirect {
                                    display: block !important;
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 2;
                                }
                            }
                        }

                        .right {
                            width: 49.219%;
                            padding-right: 6.25%;

                            .picture {
                                max-width: 530px;

                                .cms-page-module-img {
                                    max-width: 100%;
                                    height: auto;
                                }
                            }

                            .slide_video {
                                display: flex;
                                position: relative;
                                height: 100%;
                                width: 100%;
                                z-index: 1;
                                padding-bottom: 100%;
                                min-height: unset;

                                iframe {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 1;
                                    transform: unset;
                                }
                            }

                            .cms-page-module-title {
                                font-family: $primaryFont;
                                font-style: italic;
                                font-weight: 400;
                                font-size: 40px;
                                line-height: 40px;
                                color: $primaryColor;
                                margin-bottom: 5px;
                            }

                            .cms-page-module-text {
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $fs_txt;
                                line-height: 18px;
                                color: $primaryColor;
                            }
                        }

                        .column_2 {
                            .left {
                                width: 49.219%;
                                padding-left: 6.25%;
                                padding-right: 0;

                                .picture {
                                    max-width: 530px;

                                    .cms-page-module-img {
                                        max-width: 100%;
                                        height: auto;
                                    }
                                }

                                .slide_video {
                                    display: flex;
                                    position: relative;
                                    height: 100%;
                                    width: 100%;
                                    z-index: 1;
                                    padding-bottom: 100%;
                                    min-height: unset;

                                    iframe {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        z-index: 1;
                                        transform: unset;
                                    }
                                }

                                .cms-page-module-title {
                                    font-family: $primaryFont;
                                    font-style: italic;
                                    font-weight: 400;
                                    font-size: 40px;
                                    line-height: 40px;
                                    color: $primaryColor;
                                    margin-bottom: 5px;
                                }

                                .cms-page-module-text {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: $fs_txt;
                                    line-height: 18px;
                                    color: $primaryColor;
                                }
                            }

                            .right {
                                width: 50.781%;
                                padding-left: 6.25%;
                                padding-right: 5.07%;

                                .picture {
                                    max-width: 530px;

                                    .cms-page-module-img {
                                        max-width: 100%;
                                        height: auto;
                                    }

                                }

                                .cms-page-module-title {
                                    font-family: $primaryFont;
                                    font-style: italic;
                                    font-weight: 400;
                                    font-size: 40px;
                                    line-height: 40px;
                                    color: $primaryColor;
                                    margin-bottom: 5px;
                                }

                                .cms-page-module-text {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: $fs_txt;
                                    line-height: 22px;
                                    color: $primaryColor;
                                }

                                .slide_video {
                                    display: flex;
                                    position: relative;
                                    height: 0;
                                    width: 100%;
                                    padding-bottom: 100%;

                                    iframe {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                    }

                                    a.container_video + a.cta_video_redirect {
                                        display: block !important;
                                        width: 100%;
                                        height: 100%;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        z-index: 2;
                                    }
                                }
                            }
                        }
                    }

                    .cms-page-module-link {
                        transition: all ease-in 250ms;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        min-width: 246px;
                        border: 1px solid $ctaColor;
                        padding: 16px 42px;
                        max-width: 325px;
                        margin: 1rem auto 0;
                        overflow: hidden;

                        span {
                            font-family: $ctaFont;
                            font-style: normal;
                            font-weight: 850;
                            font-size: .875rem;
                            line-height: 17px;
                            text-align: center;
                            letter-spacing: .2em;
                            text-transform: lowercase;
                        }
                    }
                }
            }

            // Module 4 - Textes
            &.text-1 {
                &.nopadding {
                    padding-bottom: 0;
                }

                .home-column-title {
                    font-family: $primaryFont;
                    font-size: $fs_xlarge;
                    font-style: italic;
                    line-height: 72px;
                    color: $primaryColor;
                    margin-bottom: 5px;
                    text-align: center;

                    @media only screen and (max-width: 1360px) {
                        font-size: $fs_large;
                        line-height: 65px;
                    }
                }

                .home-column-txt {
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: $fs_mid;
                    line-height: 18px;
                    text-align: center;
                    max-width: 754px;
                    margin: 0 auto;
                }
            }

            // Module 5 - 3 blocs + CTA
            &.text-image-3 {
                display: block;
                padding: 0 0 21px 0;

                .cms-page-columns-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    .cms-page-module-contents {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        column-gap: 25px;
                        row-gap: 25px;
                        width: 100%;

                        article {
                            position: relative;
                            width: 100%;

                            .slide_video {
                                display: flex;
                                position: relative;
                                height: 100%;
                                width: 100%;
                                z-index: 1;
                                padding-bottom: 85.366%;
                                min-height: unset;

                                iframe {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 1;
                                    transform: unset;
                                }
                            }

                            .picture {
                                .cms-page-module-img {
                                    max-width: 100%;
                                    height: auto;
                                }

                                .bg {
                                    background: linear-gradient(0deg,
                                        rgba(0, 0, 0, 0.3),
                                        rgba(0, 0, 0, 0.3));
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    height: 99%;
                                    width: 100%;
                                }
                            }

                            .content_2 {
                                position: absolute;
                                top: 80%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                max-width: 360px;
                                z-index: 2;

                                .cms-page-module-link {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    min-width: 246px;
                                    border: 1px solid;
                                    padding: 16px 42px;
                                    position: absolute;

                                    .cms-page-module-cta {
                                        font-family: $ctaFont;
                                        font-style: normal;
                                        font-weight: 850;
                                        font-size: $fs_mid;
                                        line-height: 17px;
                                        text-align: center;
                                        letter-spacing: 0.2em;
                                        text-transform: lowercase;
                                    }
                                }
                            }

                            .content {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                max-width: 360px;
                                z-index: 2;

                                .cms-page-module-title {
                                    font-family: $primaryFont;
                                    font-style: italic;
                                    font-weight: 400;
                                    font-size: 40px;
                                    line-height: 40px;
                                    color: $whiteColor;
                                    margin-bottom: 25px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 1380px) {
                &.text-image-3 {
                    .cms-page-columns-container {
                        .cms-page-module-contents {
                            align-items: unset;
                            justify-content: inherit;
                            flex-direction: unset;
                            flex-wrap: unset;
                            column-gap: unset;
                            row-gap: unset;
                            text-align: left;
                            width: 100%;

                            article {
                                display: inline-block;
                                width: 29.5%;
                                margin: 0 1%;
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: 881px) and (max-width: 1260px) {
                &.text-image-3 {
                    .cms-page-columns-container {
                        .cms-page-module-contents {
                            article {
                                .content {
                                    .cms-page-module-title {
                                        font-size: 30px;
                                        line-height: 30px;
                                    }

                                    .cms-page-module-link {
                                        min-width: 200px;
                                        padding: 16px 0;

                                        .cms-page-module-cta {
                                            font-size: 0.675rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 880px) {
                &.text-image-3 {
                    .cms-page-columns-container {
                        .cms-page-module-contents {
                            text-align: center;

                            article {
                                margin-bottom: 15px;
                                width: 100%;
                                max-width: 410px;

                                .content {
                                    .cms-page-module-title {
                                        font-size: 25px;
                                        line-height: 25px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            //Module 6 - 6 blocs + Textes
            &.text-image-6 {
                display: block;

                .cms-page-columns-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    .title {
                        font-family: $primaryFont;
                        font-style: italic;
                        font-weight: 400;
                        font-size: $fs_xlarge;
                        line-height: 72px;
                        text-align: center;
                        color: $primaryColor;
                    }

                    .cms-page-module-contents {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-direction: row;
                        column-gap: 25px;
                        flex-wrap: nowrap;
                        padding: 0 0 21px 0;
                        width: 100%;

                        article {
                            position: relative;
                            width: 100%;

                            .slide_video {
                                display: flex;
                                position: relative;
                                height: 100%;
                                width: 100%;
                                z-index: 1;
                                padding-bottom: 85.366%;
                                min-height: unset;

                                iframe {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 1;
                                    transform: unset;
                                }
                            }

                            .picture {
                                .cms-page-module-img {
                                    max-width: 100%;
                                    height: auto;
                                }

                                .bg {
                                    background: linear-gradient(0deg,
                                            rgba(0, 0, 0, 0.3),
                                            rgba(0, 0, 0, 0.3));
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    height: 99%;
                                    width: 100%;
                                }
                            }

                            .content {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 100%;
                                text-align: center;
                                z-index: 2;

                                .cms-page-module-title {
                                    font-family: $primaryFont;
                                    font-style: italic;
                                    font-weight: 400;
                                    font-size: 40px;
                                    line-height: 40px;
                                    color: $whiteColor;
                                    margin-bottom: 5px;
                                    text-align: center;
                                }

                                .cms-page-module-text {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: $fs_txt;
                                    line-height: 18px;
                                    color: $whiteColor;
                                }
                            }
                        }

                        .cms-page-module-cta {
                            font-family: $ctaFont;
                            font-style: normal;
                            font-weight: 850;
                            font-size: $fs_mid;
                            line-height: 17px;
                            text-align: center;
                            letter-spacing: 0.2em;
                            text-transform: lowercase;
                        }
                    }
                }
            }
        }
    }
}

.cms_page .cms_page_content .cms-page-module.text-image-1 .cms-page-columns-container .cms-page-module-contents article .content,
.cms_page_new .cms_page_content .cms-page-module.text-image-1 .cms-page-columns-container .cms-page-module-contents article .content {
    flex-direction: column;

    .cms-page-module-link {
        width: fit-content;
        margin: auto;
    }
}

body .cms_page_content .cms-page-module.text-1 .ctaModuleText {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 246px;
    padding: 16px 42px;
    max-width: 325px;
    margin: 1rem auto 0;
    overflow: hidden;
    transition: border, opacity, background-color ease-in-out 250ms;
    border: 1px solid $ctaColor;
    box-shadow: inset 0 0 0 2px white;
    cursor: pointer;
    outline: 0;

    a {
        font-family: $ctaFont;
        font-style: normal;
        font-weight: 850;
        font-size: .875rem;
        line-height: 17px;
        text-align: center;
        letter-spacing: .2em;
        text-transform: lowercase;
    }

    &:hover {
        border: 1px solid rgba(206, 122, 81, .8);
        background: rgba(206, 122, 81, .8);
        box-shadow: 0 0 0 2px rgba(250, 250, 250, .7) inset;

        a {
            color: $whiteColor !important;
        }
    }

}

// Module cover carousel

.cms-module:last-of-type {
    padding-bottom: 0;
}

.cms-module.cover-module {
    margin-top: 30px;

    .coverSwiper:not(.swiper-initialized),
    &:has(:not(.coverSwiper)) {
        
        .swiper-slide {
            width: calc(25% - 20px);
            margin: 0 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        ~ .swiper-cover-button {
            display: none;
        }

        .cms-module-title {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            font-family: $primaryFont;
            font-size: clamp(20px, 2.833vw, 42px);
            font-style: italic;
            padding: 0 10px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .coverSwiper {
        max-width: 100%;

        .swiper-slide {
            position: relative;
            margin: 0;
            line-height: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        ~ .swiper-cover-button {
            display: flex;
        }

        .cms-module-bg-container {
            display: block;
            width: 100%;
            max-width: 390px;
            position: relative;
            aspect-ratio: 390 / 450;

            img {
                width: 100%;
                height: 100%;
                pointer-events: none;
            }
        }

        .slide_video {
            display: block;
            width: 100%;
            max-width: 390px;
            position: relative;
            aspect-ratio: 390 / 450;
            height: unset;
            min-height: unset;
            max-height: unset;

            iframe {
                width: 100%;
                height: 100%;
                pointer-events: none;
            }
        }

        .container_video{
            height: 100%;
            display: block;
        }

        .boxText {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;

            .cms-module-contents {
                max-width: 100%;
            }

            h2 {
                font-family: "Sabon LT Pro";
                font-size: 2.1875vw;
                font-style: italic;
                font-weight: 400;
                line-height: normal;
                text-align: center;
                position: initial;
                color: $white !important;

                @media screen and (min-width: 1921px) {
                    font-size: 42px;
                }
            }
        }
    }

    .cms-columns-container {
        position: relative;
        padding: 0 40px;

        .swiper-cover-button {
            display: flex;
        }

        .cms-module-contents {
            padding: 0 10px;
        }
    }

    .swiper-cover-button {
        position: absolute;
        top: 50%;
        z-index: 1;
        background-size: 10px 20px;
        background-position: center;
        background-repeat: no-repeat;
        width: 27px;
        height: 44px;
        margin-top: -22px;
        z-index: 10;
        cursor: pointer;
    
        &.swiper-cover-button-prev {
            background-image: url(../svg/arrow_prev.svg);
            left: 0;
        }
    
        &.swiper-cover-button-next {
            background-image: url(../svg/arrow_next.svg);
            right: 0;
        }

        &.swiper-button-disabled {
            opacity: .35;
            cursor: auto;
            pointer-events: none;
        }
    }

    .swiper-pagination {
        display: none;
    }

    .d-none {
        display: none;
    }
}

body.cms_category,
.cms_subcategory {
    #cms {
        width: 100%;
        padding: 0 25px;
        margin-bottom: 50px;

        .page_title {
            h1 {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 42px;
                font-style: italic;
                font-weight: 400;
                line-height: normal;
                text-align: center;
                margin-bottom: 20px;
            }
        }

        .categoryNav {
            #breadcrumbs {
                .breadcrumbs {
                    margin-top: 7px;
                }
            }
        }
    }

    #blog_texte_seo {
        color: $primaryColor;
        font-family: $primaryFont;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        max-width: 880px;
        max-height: 100px;
        margin: 0 auto 30px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    #cms_subcategory_menu {
        margin: 0 auto;
        width: 100%;
        max-width: 1440px;

        .blog-rubriques {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 60px;
            margin-bottom: 35px;

            .item {
                span {
                    color: $primaryColor;
                    text-align: center;
                    font-family: $ctaFont;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 100;
                    line-height: 17px;
                    letter-spacing: 2.8px;
                    text-transform: lowercase;
                    cursor: pointer;

                    &:hover {
                        color: $ctaColor;
                    }
                }

                &.selected {
                   position: relative;

                    span {
                        color: $ctaColor;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        display: block;
                        height: 1px;
                        width: 100%;
                        background-color: $ctaColor;
                    }
                }
            }
        }
    }

    .wrapper_container_trier {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        @media screen and (max-width:1024px) {
            max-width: 890px;
        }

        #nb_article {
            color: $secondaryColor;
            font-family: $primaryFont;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            text-transform: capitalize;
        }

        .filter {
            position: relative;
            margin-left: auto;
            margin-right: 8px;
            cursor: pointer;
            list-style: none; 

            .date_filter_title {
                color: $primaryColor;
                font-family: $ctaFont;
                font-size: 14px;
                font-style: normal;
                font-weight: 100;
                line-height: 17px;
                letter-spacing: 2.8px;
                text-transform: lowercase;
                display: flex;
                align-items: flex-end;
                gap: 10px;

                &:after {
                    content: '';
                    @include bgIconPath("../svg/blog_arrow.svg");
                    background-size: 100%;
                    background-repeat: no-repeat;
                    display: block;
                    width: 10px;
                    height: 10px;
                }
            }

            .date_filter_wrapper {
                transition: all .3s ease-out;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
                height: 0;
                width: 150px;
                padding: 2vmin 0.5vmin 2vmin;
                opacity: 0;
                overflow: hidden;
                background-color: $white;

                .menu_cms {
                    display: none;
                }

                li {
                    margin: 2vmin 0;
                    padding-left: 2.5vmin;
                    list-style: none;

                    input {
                        display: none;
                    }

                    input:checked+label {
                        font-weight: bold;
                        text-decoration: underline;

                        &::before {
                            box-shadow: inset 0 0 0 .1em $white;
                            background-color: $primaryColor;
                        }
                    }

                    label {
                        cursor: pointer;
                        transition: all .3s ease-out;
                        font-family: $primaryFont;
                        font-size: 14px;
                        display: flex;

                        &::before {
                            content: "";
                            display: block;
                            background-color: $white;
                            width: 13px;
                            height: 13px;
                            border: 1px solid $primaryColor;
                            transition: all .3s ease;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }

            &.open {
                .bgSprite:after {
                    transform: rotate(180deg);
                }

                .date_filter_wrapper {
                    z-index: 1;
                    height: auto;
                    opacity: 1;
                }
            }
        }
    }

    #content_previsu_cms_page {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        max-width: 1440px;
        row-gap: 40px;
        column-gap: 30px;
        margin: 0 auto;

        .previsu_cms_page {
            width: calc((100% - 60px) / 3);
            max-width: 460px;

            a {
                img {
                    width: 100%;
                    height: auto;
                }
            }

            .cms_page_previsu_items {
                margin-top: 10px;

                .cms_page_previsu_cat {
                    p {
                        color: $primaryColor;
                        text-align: left;
                        font-family: $ctaFont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 100;
                        line-height: 17px;
                        letter-spacing: 2.8px;
                        text-transform: lowercase;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }
                }

                .cms_page_previsu_title {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 10px;
                }

                .rubrique_description {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    width: 100%;
                    max-width: 460px;
                    max-height: 76px;
                    margin-bottom: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }

                .rubrique_date {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 18px;
                }

                .cms_page_previsu_btn {
                    span {
                        color: $ctaColor;
                        text-align: center;
                        font-family: $ctaFont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 100;
                        line-height: 17px;
                        letter-spacing: 2.2px;
                        text-transform: lowercase;
                        text-decoration: underline;
                        text-underline-offset: 4px;
                    }
                }
            }
        }
    }

    .see_more_txt_cta {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 187px;
        height: 50px;
        margin: 80px auto 0;
        color: $ctaColor;
        text-align: center;
        text-transform: lowercase;
        font-family: $ctaFont;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.8px;
        text-decoration: none;
        border: 1px solid $ctaColor;
        box-shadow: inset 0 0 0 2px $white;
        cursor: pointer;

        &:hover {
            background-color: $ctaColor;
            color: $white;
        }
    }
}
