//NL
body.nl {
    &.wishlist {
        .wrapper_moncompte #btn_add_wishlist {
            width: 356px !important;
        }
    }

    #top_wrapper .header .top_header_wrapper .block_top_links .block_top_item.customer_service .serviceRollover {
        &::before {
            width: 492px;
        }
    }

    .w-newsletter-form .w-form-line.w-submit {
        right: 10px;
    }

    .footer .footer_content .topWrapper .w-newsletter-form .w-submit {
        width: 125px;
    }

    #top_wrapper .header .bot_header_wrapper .main_menu_contents .main_menu_itm{
        &#categ_1{
            .submenu_wrapper{
                .ss_cat{
                    max-width: 326px;
                }
            }
        }
    }
}

//DE
body.de {
    &.wishlist {
        .wrapper_moncompte #btn_add_wishlist {
            width: 356px !important;
        }
    }

    #show_top_cart .wrap_title .title,
    #show_top_wish .wrap_title .title {
        font-size: 3.45rem !important;
    }

    #top_wrapper .header .top_header_wrapper .block_top_links .block_top_item.customer_service .serviceRollover {
        &::before {
            width: 492px;
        }
    }

    .w-newsletter-form .w-form-line.w-submit {
        right: 10px;
    }

    #top_wrapper .header .bot_header_wrapper .main_menu_contents .main_menu_itm{
        &#categ_1{
            .submenu_wrapper{
                .ss_cat{
                    max-width: 326px;
                }
            }
        }
    }
}

//EN
body.en {
    .wrapper_retailers_map_details .storeActions .button.primary {
        padding: 22px 0;
    }
}