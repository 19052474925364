.page_produit {
    .is_sticky {
        z-index: 1000;
        position: fixed;
        top: 76px;
        left: 0;
        width: 100%;
        border-top: 1px solid $ctaColor;
        border-bottom: 1px solid $ctaColor;
        background-color: $whiteColor;

        &.product_info {
            max-width: unset;
            padding-right: 2rem;
            justify-content: space-between;

            .eclat_wrapper,
            #itm_name small {
                display: none !important;
            }

            .item_price_wrapper,
            #addToWishlistButtonContainer,
            .description,
            .productColorFieldset {
                display: none;
            }
        }
    }

    #itm_name .swiper-slide {
        display: none;
    }

    .arrow {
        background-size: 10px 20px;
        background-position: center;
        background-repeat: no-repeat;

        &.swiper-button-next {
            background-image: url(../svg/arrow_next.svg);
        }

        &.swiper-button-prev {
            background-image: url(../svg/arrow_prev.svg);
        }
    }
}

.product_page {
    .cta--secondary.coffret span {
        border-bottom: 1px solid $ctaColor;
    }

    #product_selects {
        #ligne_couleur,
        #ligne_pointure {
            display: none;
        }
    }

    .footer {
        padding-top: 3.75rem;
    }
}

.page_produit:not(.achat_express) {
    width: 96%;
    max-width: $wrapperContainer;
    margin: 0 auto;
    padding-top: 15px;

    .product_main_wrapper {
        width: 100%;
    }

    .prod_top_wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 40px;

        .itm_back_btn {
            display: none;
        }

        .product_left_image {
            width: 50%;
            max-width: 840px;
            min-height: 700px;

            .block_vid {
                position: relative;
                overflow: hidden;
                padding-top: 56.25%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .product_info_wrapper {
            position: relative;
            z-index: 2;
            width: 40%;
            max-width: 670px;
            margin-right: 3.5%;

            .pos_sticky {
                top: 90px;
                position: sticky;
            }

            .store_availibility_wrapper {
                margin: 1.5rem auto;
                font-family: $ctaFont;
                font-size: $fs_mid;
                text-transform: uppercase;
                text-align: center;
                color: $ctaColor;
                line-height: 1.0625rem;
                letter-spacing: 0.2em;
                font-weight: 850;

                .store_availibility {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 1rem;

                    .availibility_without_store, .availibility_with_store {
                        position: relative;
                        display: flex;
                        margin: 0 auto;
                        padding-bottom: 0.5rem;
                        border-bottom: 2px solid $ctaColor;
                    }

                    .availibility_with_store {
                        position: relative;
                    
                        &:before {
                            position: absolute;
                            content: '•';
                            left: -20px;
                            font-size: 2rem;
                        }
                    
                        &.available:before { color: $green; }
                        &.low_stock:before { color: $ctaColor; }
                        &.not_available:before { color: $red; }
                    
                        .available, .low_stock, .not_available {
                            display: none;
                        }
                    
                        &.available .available,
                        &.low_stock .low_stock,
                        &.not_available .not_available {
                            display: inline;
                        }
                    
                        .store_name:before {
                            content: '|';
                            margin: 0 0.5rem;
                        }
                    }
                }
            
                .checkout_store {
                    display: flex;
                    justify-content: center;
                    gap: 0.5rem;
                    .cta_change_store {
                        text-decoration: underline;
                        color: $ctaColor;
                        text-transform: lowercase;
                        text-underline-offset: 0.2em;
                        font-family: $primaryFont;
                        font-weight: 400;
                        font-size: 1rem;
                        letter-spacing: normal;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .specifics_eclats {
                position: relative;
                top: 0;
                z-index: 10;
                left: -9%;

                img {
                    position: absolute;

                    &:nth-child(1) {
                        left: 0;
                    }

                    &:nth-child(2) {
                        left: 235px;
                    }

                    &:nth-child(3) {
                        left: 470px;
                    }
                }
            }

            #eclat_stock_limited {
                position: absolute;
                right: 0;
                top: -4px;
            }

            form.product_info {
                #product_selects {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }

                #product_addcart {
                    .prod_btn_wrapper {
                        margin-bottom: 1rem;
                        #bloc_add_basket {
                            #btn_add_cart {
                                width: 100%;
                                background-color: $ctaColor;
                                color: white;
                                min-width: 244px;

                                &:hover {
                                    background-color: white;
                                    color: $ctaColor;
                                }

                                span {
                                    padding-left: 4px;
                                    transform: translateY(-1.5px);
                                }

                                .item_price {
                                    display: none;
                                }
                            }
                        }

                        #btn_add_alert {
                            width: 100%;
                        }
                    }

                    #bloc_availability {
                        text-align: center;
                        #ctaAvailabilityInStore {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            margin: 0 auto;
                            color: $ctaColor;
                            font-family: $ctaFont;
                            font-size: 11px;
                            text-transform: lowercase;
                            font-weight: 850;
                            letter-spacing: .2em;
                            cursor: pointer;

                            .clickandgo_product_info {
                                text-decoration: underline;
                                text-underline-offset: 0.2em;
                            }
                        }
                    }
                }

                #favorite_store {

                    .check_availability {
                        display: block;
                        color: $ctaColor;
                        border-bottom: 1px solid $ctaColor;
                        width: fit-content;
                        padding-top: 10px;
                        padding-bottom: 1px;
                        margin-top: 8px;
                        margin-left: auto;
                        margin-right: auto;
                        font-family: $ctaFont;
                        text-transform: lowercase;
                        font-size: 11px;
                        font-weight: 850;
                        text-align: center;
                        letter-spacing: .2em;
                    }

                    .change_store {
                        color: $ctaColor;
                        border-bottom: 1px solid $ctaColor;
                        width: fit-content;
                        padding-top: 10px;
                        margin-top: 8px;
                        margin-left: auto;
                        margin-right: auto;
                        font-family: $primaryFont;
                        text-transform: lowercase;
                        font-size: 15px;                        
                        font-weight: 400;
                        text-align: center;
                        letter-spacing: normal;
                    }

                    .store_pickup {
                        display: block;
                        margin: 10px auto 0;
                        text-align: center;
                        color: $ctaColor;
                        font-family: $ctaFont;
                        font-size: 11px;
                        font-weight: 850;
                        text-transform: lowercase;
                        letter-spacing: .2em;
                    }

                    .stock_availability {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;

                        position: relative;
                        padding-left: 1rem;
                        padding-top: 10px;
                        margin-top: 8px;
                        text-align: center;

                        .stock_status {
                            display: block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;

                            &.stock_available {
                                background-color: $green;
                            }

                            &.stock_unavailable {
                                background-color: $red;
                            }

                            &.stock_limited {
                                background-color: $yellow;
                            }
                        }

                        .stock_status_txt {
                            text-decoration: underline;
                            text-underline-offset: 2px;
                            color: $ctaColor;
                            font-family: sackersgothicstdheavy, sans-serif;
                            font-size: 11px;
                            text-transform: lowercase;
                            text-align: center;
                            letter-spacing: .2em;
                            width: fit-content;
                        }
                    }
                }
            }
        }
    }

    // LIVRAISON
    .delivery {
        padding: 1.85rem 0;

        ul {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            li {
                position: relative;
                padding-left: 33px;
                display: inline-block;
                vertical-align: middle;
                color: $ctaColor;
                font-family: $primaryFont;
                font-style: normal;
                font-size: $fs_mid;
                line-height: 18px;

                &:not(:last-child) {
                    margin-right: 2%;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.shipping_returns {
                    &::before {
                        background: url(../svg/icon_48h.svg) no-repeat 100%;
                        background-size: contain;
                        width: 23px;
                        height: 23px;
                    }
                }

                &.delivery_offers {
                    &::before {
                        background: url(../svg/icon_livraison_offerte.svg) no-repeat 100%;
                        background-size: contain;
                        width: 23px;
                        height: 21px;
                    }
                }

                &.home_delivery {
                    padding-left: 46px;
                    &::before {
                        background: url(../svg/icon_home_delivery.svg) no-repeat 100%;
                        background-size: contain;
                        width: 36px;
                        height: 21px;
                    }
                }

                &.store_delivery {
                    &::before {
                        background: url(../svg/icon_store_delivery.svg) no-repeat 100%;
                        background-size: contain;
                        width: 24px;
                        height: 22px;
                    }
                }

                span {
                    color: $ctaColor;
                    font-weight: 400;
                    font-size: 15px;
                    font-family: linotype-sabon, sans-serif;
                    line-height: 18px;
                    font-style: normal;
                    opacity: 0.9;

                    &.livraison_retour,
                    &.livraison_offerte,
                    &.delivery_to {
                        display: block;
                        font-family: $primaryFont;
                        font-style: normal;
                        font-size: $fs_mid;
                        line-height: 17px;
                        font-weight: 700;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .product_feats_wrapper {
        .thumbnav {
            padding: 0;
            list-style: none;

            @include smallScreen {
                margin: 0.5rem 0;
            }

            .thumb {
                &.tab.active {
                    padding-bottom: 2.3125rem;
                }
            }

            .thumblnk {
                cursor: pointer;
                font-size: $fs_mid;
                text-transform: lowercase;
                font-family: $ctaFont;
                letter-spacing: 0.2em;
                text-transform: lowercase;

                @include smallScreen {
                    font-size: 0.675rem;
                }

                &.active {
                    text-decoration: underline;
                }
            }

            .thumbslide {
                &.active {
                    display: block;
                    opacity: 1;
                }
            }

            /*
            Generic Styling, for Desktops/Laptops
            */
            table {
                width: 100%;
                border-collapse: collapse;
            }

            /* Zebra striping */
            th {
                background: $backgroundColor;
                color: $ctaColor;
                text-align: center;
                vertical-align: middle;
                width: 11.11%;
            }

            td,
            th {
                padding: 6px;
                border: 1px solid $whiteColor;
                text-align: center;
                min-height: 3.125rem;
                font-family: $primaryFont;
                font-size: $fs_mid;
                font-weight: 400;
            }

            td {
                background: $whiteColor;
                text-transform: capitalize;
                border: 1px solid $backgroundColor;
                height: 50px;
                vertical-align: middle;
                font-family: $primaryFont;
                font-size: $fs_mid;
                word-break: break-word;
            }

            /*
            Max width before this PARTICULAR table gets nasty
            This query will take effect for any screen smaller than 760px
            and also iPads specifically.
            */
            @media only screen and (max-width: 1300px),
            (min-device-width: 768px) and (max-device-width: 1024px) {

                /* Force table to not be like tables anymore */
                table,
                thead,
                tbody,
                th,
                td,
                tr {
                    display: block;
                }

                /* Hide table headers (but not display: none;, for accessibility) */
                thead tr {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }

                tr {
                    border: 1px solid $backgroundColor;
                }

                td {
                    /* Behave  like a "row" */
                    border: none;
                    border-bottom: 1px solid $backgroundColor;
                    position: relative;
                    padding-left: 50%;
                    line-height: 50px;
                    color: $primaryColor;

                    &[data-panel='description_longue'] {
                        height: auto;
                        text-align: left;
                        line-height: 1.2;
                    }
                }

                td:before {
                    /* Now like a table header */
                    position: absolute;
                    /* Top/left values mimic padding */
                    top: 0px;
                    left: 0px;
                    width: 45%;
                    height: 100%;
                    line-height: 50px;
                    padding-right: 10px;
                    white-space: nowrap;
                    background-color: $backgroundColor;
                    color: $ctaColor;
                    font-family: $primaryFont;
                    font-size: $fs_mid;
                    text-transform: capitalize;
                    border-bottom: 1px solid $whiteColor;
                }

                /*
                Label the data
                */
                td:nth-of-type(1):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(2):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(3):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(4):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(5):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(6):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(7):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(8):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(9):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(10):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(11):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(12):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(13):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(14):before {
                    content: attr(data-panel);
                }

                td:nth-of-type(15):before {
                    content: attr(data-panel);
                }
            }
        }
    }

    .gift_size {
        padding-top: 2.5rem;
        max-width: 670px;

        .title {
            font-family: $primaryFont;
            font-size: $fs_mediumsubtitle;
            font-weight: 400;
            font-style: italic;
        }
    }

    section.section {
        padding: 40px 0 0 0;
    }

    .box_content {
        &.productContents_wrapper {
            position: relative;

            .assoc_titre {
                margin-bottom: 17px;

                h2 {
                    font-family: $primaryFont;
                    font-style: italic;
                    font-weight: 400;
                    font-size: $fs_xlarge;
                    line-height: 4.5rem;
                    text-align: center;
                    text-transform: lowercase;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
            }

            .productContents {
                .swiper-wrapper {
                    justify-content: center;
                    column-gap: 40px;

                    .swiper-slide {
                        text-align: center;
                        max-width: 258px;

                        .gamme_preview {
                            width: 154px;
                            top: 0;
                            opacity: 1;
                            position: relative;

                            &:first-child {
                                top: 0;
                                opacity: 1;
                                position: relative;
                                text-align: left;
                                transition: all 0.3s ease-in-out;

                                &:hover {
                                    opacity: 0;
                                }
                            }

                            &:nth-child(2) {
                                top: 0;
                                position: absolute;
                                left: 52px;
                                z-index: -1;
                            }
                        }

                        .title {
                            font-family: $ctaFont;
                            color: $ctaColor;
                            font-style: normal;
                            text-transform: lowercase;
                            font-size: $fs_mid;
                            line-height: 1.0625rem;
                            /* identical to box height */
                            text-align: center;
                            letter-spacing: 0.2em;
                            margin-top: 28px;
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-height: 51px;
                        }

                        .description {
                            display: block;
                            width: 100%;
                            font-family: $primaryFont;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 0.9375rem;
                            line-height: 1.125rem;
                            text-align: center;
                            overflow: hidden;
                        }
                    }
                }
            }

            .swiper-button-prev {
                background-image: url(../svg/arrow_prev.svg) !important;
                background-size: 10px 21px;
                width: 10px;
                height: 21px;
                z-index: 1;
            }

            .swiper-button-next {
                background-image: url(../svg/arrow_next.svg) !important;
                background-size: 10px 21px;
                width: 10px;
                height: 21px;
                z-index: 1;
            }
        }
    }

    .productAssoc,
    .visitedAssoc {

        .swiper-button-next-assoc.swiper-button-disabled,
        .swiper-button-prev-assoc.swiper-button-disabled {
            opacity: 0;
        }
    }
}

body.en {
    .page_produit:not(.achat_express) {
        .delivery {
            ul {
                li {
                    span {
                        &.delivery_to {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/**
 * Product bottom associations
 */
.productAssoc {
    position: relative;
    width: 96%;
    max-width: 1730px;
    margin: 80px auto 0;

    .swiper-wrapper {
        justify-content: center;
    }

    .product_header {
        h3 {
            font-weight: bold;
            font-family: $ctaFont;
            color: $primaryColor;
            letter-spacing: 0.2em;
            line-height: 16.8px;
            text-transform: lowercase;
            font-size: 14px;
        }
    }

    .item_subtitle {
        display: none;
    }

    .assoc_title {
        h2 {
            font-family: $primaryFont;
            font-style: italic;
            font-weight: 400;
            font-size: $fs_xlarge;
            line-height: 4.5rem;
            text-align: center;
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .wrapperProdVertical {
        max-width: 316px;

        .cta_express {
            position: initial;
            margin: -4.5em auto 0;
            padding-bottom: 1em;

            @media screen and(max-width: 1400px) { 
                margin: 0 auto;
                padding-bottom: 0;
            }
        }

        &.out_of_stock .cta_express {
            margin: -6.8em auto 0;
        }
    }

    .wishlistBtn {
        font-size: 0;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        z-index: 10;
        top: 0.6rem;
        right: 0.6rem;
        opacity: 0;
        transition: opacity .3s ease-in-out;

        @media screen and(max-width: 1400px) {
            width: 1rem;
            height: 1rem;
            top: 0.3rem;
        }
    }

    .swiper-button-prev {
        left: -1rem;
    }

    .swiper-button-next {
        right: -1rem;
    }
}

/**
 * Product right part associations
 */
#productAssoc {

    .wishlistBtn,
    .wrapper_description .item_price {
        display: none;
    }

    .wrapperProdVertical {
        .eclat_product {
            position: relative;
            left: -80px;
            top: 6px;
            display: none;
        }

        .imgWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;

            @media screen and(max-width: 1300px) {
                flex-wrap: wrap;

                .rolloverimgContainer {
                    margin: 0 auto;
                }

                .product_infos,
                .cta_express {
                    flex: initial;
                    width: 100%;
                }
            }
        }

        .crossed {
            text-decoration: line-through;
        }

        .wrapper_description {
            height: auto;
            padding-top: 0;
        }
    }

    .rolloverimgContainer {
        position: relative;
        width: 144px;

        .imgProd {
            width: 100%;
            height: auto;
            vertical-align: top;

            +.imgProd {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
            }
        }
    }

    .product_infos {
        flex: 1;
        font-family: $ctaFont;
        font-size: 14px;
        text-align: center;
        padding: 0 30px;
        letter-spacing: 0.2em;
        text-transform: lowercase;

        @media screen and(max-width: 1400px) {
            padding: 0 10px 0 0;

            .item_name {
                font-size: 12px;
            }
        }

        .item_subtitle {
            display: none;
        }
    }

    .cta_express {
        position: relative;
        bottom: auto;
        left: auto;
        width: auto;
        opacity: 1;
    }

    .wrap_rolloverproduit {
        width: 244px;

        @media screen and(max-width: 1400px) {
            margin: 0 auto;
        }
    }

    .w-submit-button,
    button.w-submit-button {
        font-family: $ctaFont;
        font-size: 14px;
        color: $ctaColor;
        margin: 0;
        height: 3.2rem;
        background-color: $whiteColor;
        border: 1px solid $ctaColor;
        transition: all 0.2s ease-in-out;
        letter-spacing: 0.2em;
        text-transform: lowercase;

        &:hover {
            background-color: $ctaColor !important;
            color: $whiteColor !important;
            box-shadow: inset 0 0 0 2px $whiteColor;
        }
    }

    .button {
        margin: 0;
        line-height: initial;
    }
}

.productSwiperWrapper {
    position: relative;
    width: 100%;
    max-width: 840px;
    top: 32px;
    left: 0px;

    .swiper-lazy {
        max-width: 840px;
        width: 100%;
    }
}

// ALERTE STOCK
#keep_alive_stock {
    width: 100%;

    .w-alertestock-form {
        position: relative;
        width: 100% !important;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 30px;

        .w-form-line:not(.w-submit) {
            margin-bottom: 0;
        }

        .w-input-label {
            span {
                line-height: 17px;
            }
        }

        .w-input {
            width: 100%;
        }

        .w-form-line.w-submit {
            width: fit-content;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 100%;
            right: 0;

            button.w-submit-button {
                padding: 0 2em;
            }
        }

        #mail_alerte_stock {
            box-shadow: unset;
            padding: 0 0 0 0.875rem;
            border: 1px solid $lighterGrey;
            height: 48px;
            border-radius: 0;
            box-shadow: unset;
            box-sizing: border-box;
        }

        .w-nonempty #mail_alerte_stock {
            padding-top: 7px;
        }
    }
}

.bloc_add_alert_confirmation {
    background-color: $whiteColor;

    p {
        font-size: $fs_min;
        color: $green;
        line-height: 1.3;
        font-family: $primaryFont;
    }
}

/* For Product Page 'FP' */
.page_produit {
    .product_info_wrapper {
        /* Add to cart Sticky: Hide éclats */
        &.sticky_eclats .specifics_eclats {
            display: none;
        }

        #keep_alive_stock {
            .w-alertestock-form {
                .w-form-line{
                    margin-bottom: unset !important;
                }
            }
        }
    }
}

form.product_info {
    top: -76px;
    transition: all 0.3s ease-in-out;

    &.sticky {
        position: fixed;
        z-index: 20;
        left: 0;
        top: 87px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 120px;
        background: $whiteColor;
        border-top: 1px solid $ctaColor;
        border-bottom: 1px solid $ctaColor;
        transition: all 0.3s ease-in-out;

        @media screen and (max-width: 1200px) {
            top: 75px;
        }

        &::after {
            content: '';
            position: absolute;
            background-color: $whiteColor;
            width: 100%;
            top: 1px;
            left: 0;
        }

        #itm_name small,
        .description,
        .productColorFieldset,
        .find_retailer,
        .item_price_wrapper,
        #addToWishlistButtonContainer,
        #favorite_store {
            display: none !important;
        }

        .product_item_name {
            width: 53%;
            margin: 0;
            padding: 0;

            .block_vid {
                display: none;
            }

            .header_product small {
                display: none;
            }
        }

        #itm_name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: $ctaFont !important;
            font-style: normal !important;
            font-size: 14px !important;
            text-transform: lowercase !important;
            line-height: initial !important;
            letter-spacing: 0.2em;

            strong {
                white-space: nowrap;
                margin-left: 10%;
            }

            .swiper-slide {
                display: flex;
                align-items: center;
                width: 120px;

                img {
                    width: 100%;
                    height: auto;
                    font-variant: top;
                }
            }
        }

        fieldset#product_addcart {
            display: block;
        }

        #btn_add_alert {
            padding: 0 3em;
        }

        #btn_add_cart {
            background: $ctaColor;
            color: $whiteColor;
            padding: 0 3em;

            &:hover {
                background: $whiteColor;
                color: $ctaColor;
            }

            .item_price {
                display: flex !important;

                .price_tag,
                .price_tag span {
                    color: $whiteColor;
                    font-family: $primaryFont;
                    margin-top: 4px;

                    +.price_tag,
                    +.price_tag span {
                        color: $whiteColor;
                        font-family: $primaryFont;
                        text-decoration: line-through;
                    }

                    +.price_tag {
                        margin-left: 0.5rem;
                    }
                }

                .price_tag.crossed,
                .price_tag.crossed span {
                    color: $primaryColor;
                }

                &:before {
                    content: '|';
                    margin: 0 1rem;
                }
            }

            &:hover {
                .item_price {

                    .price_tag,
                    .price_tag span {
                        color: $ctaColor;

                        +.price_tag,
                        +.price_tag span {
                            color: $ctaColor;
                            text-decoration: line-through;
                        }

                        +.price_tag {
                            margin-left: 0.5rem;
                        }
                    }

                    .price_tag.crossed,
                    .price_tag.crossed span {
                        color: $ctaColor;
                    }
                }
            }
        }

        #keep_alive_stock {
            .w-alertestock-form {
                margin: 20px auto 0.625rem;

                #mail_alerte_stock {
                    width: 300px;
                }
            }

            .w-form-line.w-submit {
                position: absolute;
                top: 24px;
                right: 1px;
                width: 100px;
                height: 46px;
                border: 0;
            }
        }

        .bloc_add_alert_confirmation {
            position: relative;
            bottom: unset;
            top: unset;
        }
    }
}

.alerte_container_img {
    max-width: 300px;
    margin: auto;
}

#gondole_derniers_articles_vus {
    display: none;
}

//style "existe également.." quand le stock est à 0
.product_page .item.alt_sld_view.assoc.swiper-slide.out_of_stock {
    .item_price {
        color: $black;
    }

    .rolloverimgContainer .imgProd {
        filter: grayscale(1);
    }

    &:hover {
        .product_infos {
            color: inherit;
        }

        .w-form-line {
            border: 1px solid $borderGrey;
        }

        .button.dark.w-submit-button {
            display: none;
        }

        .expressAlertStock {
            position: relative;
            z-index: 1;

            .alert_return {
                display: none !important;
            }

            .alert_stock,
            .bloc_add_alert_confirmation {
                display: flex;
                justify-content: center;
            }

            .w-input-element {
                width: 244px;
                border: none;

                &:focus,
                &:active {
                    box-shadow: inherit;
                }

                &.inputErr {
                    background-color: rgba(255, 0, 0, 0.1);
                }
            }
        }
    }
}

#bloc_add_basket>div.loader {
    max-height: 48px;
    bottom: 0;
    top: initial;
}

.roll_preco {
    margin-bottom: 15px;
    color: $titleColor;
    font-style: normal;
    font-weight: 400;
    font-size: .9375rem;
}

#availabilityInStore {
    width: 100%;
    max-width: 754px;
    max-height: 75vh;

    #stepStoreList {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;

        .availabilityInStoreTitle {
            font-size: 24px;
            font-weight: 400;
            font-family: $primaryFont;
        }

        .availabilityInStoreDescription {
            display: none;
        }

        #storeListForm {
            .field_address {            
                #searchAddress {
                    width: calc(100% - 2rem);
                    font-size: 14px;
                    line-height: 21px;
                    font-family: $secondaryFont;
                    border: 1px solid $borderGrey;
                    padding: 0 1rem;
                    outline: 0;
                    height: 45px;
                    color: $placeholder;

                    &:focus {
                        outline: none;
                    }
                }
            }
            button {
                display: none;
            }
        }
    }

    .liste_distributeur_scrollbar {
        max-height: calc(75vh - (45px + 2rem + (2rem *2))); // 45px = height of the title, 2rem = margin bottom of the title/padding of the lightbox
        padding-right: 1rem;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: $ctaColor transparent;

        .elem_liste_distributeur {
            border-top: 1px solid $ctaColor;
            padding-top: 1rem;
            position: relative;
            height: fit-content;
            font-size: 15px;

            &:first-child {
                border-top: none;
                padding-top: 0;
            }

            .title {
                font-size: 24px;
                font-style: normal;
                margin-bottom: 0;
            }

            .store_details {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                font-family: $primaryFont;

                .store_address {
                    color: #333;
                    font-weight: 400;
                }

                .see_map {
                    display: none;
                }

                .link_store_wrapper {
                    margin-bottom: 1rem;

                    .show_schedule {
                        text-decoration: underline;
                        cursor: pointer;
                        text-underline-offset: 2px;
                        margin-bottom: 0.5rem;
                    }
                
                    .store_week_schedule {
                        display: none;
                
                        &.actif {
                            display: flex !important;
                            flex-direction: column;
                            gap: 0.25rem;
                        }
                    }
                }

                .clickandgo_information {
                    margin-bottom: 1rem;

                    .product_size_title, .size-info, .separator {
                        display: none;
                    }

                    &>p {
                        margin-bottom: 0.5rem;
                    }

                    .storeStock {

                        &:not(.static) {
                            position: absolute;
                            right: 5px;
                            bottom: 48%;
                        }

                        &::before {
                            content: "";
                            width: 10px;
                            height: 10px;
                            display: inline-block;
                            border-radius: 50%;
                            margin-right: 5px;
                            top: -1px;
                            position: relative;
                        }

                        &.high {
                            &::before {
                                background-color: $green;
                            }
                        }

                        &.medium {
                            &::before {
                                background-color: #FF9900;
                            }
                        }

                        &.low {
                            &::before {
                                background-color: $red;
                            }
                        }
                    }

                    .clickandgo_withdrawal {
                        margin-bottom: 1rem;

                        .picto_withrawal {
                            background-image: url('../svg/logo_withrawal.svg');
                            height: 15px;
                            width: 16.8px;
                            display: inline-block;
                            background-repeat: no-repeat;
                            position: relative;
                            top: 4px;
                            margin-right: 8px;
                        }
                    }

                    .btn-select-store {
                        position: absolute;
                        top: 35%;
                        right: 0;
                        width: 15rem;

                        .w-submit-button {
                            background-color: $ctaColor;
                            color: $whiteColor;
                        }
                    }
                }
            }
        }
    }

    .close_pop {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: none;
        border: none;
        font-size: 0;
        line-height: 0;
        background: url('../svg/icon_close.svg');
        width: 12px;
        height: 12px;
    }
}
