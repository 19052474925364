/* PRODUCT PAGE */
/* Class item_name is #itm_name's container*/
.page_produit {
    .bloc_add_alert_confirmation {
        button {
            display: none;
        }
    }

    form.product_info {
        .product_item_name {
            position: relative;
            margin-bottom: 0.9rem;
            padding-top: 2.7rem;

            @media screen and (max-width: 1200px) {
                margin-bottom: 1.9rem;
            }

            .header_product {
                position: relative;

                .eclat_wrapper {
                    position: absolute;
                    top: -49px;
                    left: 0;
                    min-height: 17px;
                    width: auto;
                    z-index: 22;
                }

                #itm_name {
                    font-family: $primaryFont;
                    font-style: italic;
                    font-size: $fs_large;
                    line-height: 50px;

                    strong {
                        display: block;
                        font-weight: 400;
                        margin-left: 0;
                    }
                }

                small, a {
                    display: block;
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: $fs_txt;
                    line-height: 18px;
                    color: $ctaColor;
                    padding: 4px 0;
                    text-transform: none;
                }

                small,
                a:hover {
                    color: $primaryColor;
                    transition: color ease-in 250ms;
                }

                .item_price_wrapper {
                    position: absolute;
                    right: 0;
                    bottom: 0.2rem;

                    .item_price {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        font-family: $primaryFont;
                        font-size: 1.375rem;

                        @include smallScreen {
                            margin-bottom: 0.5rem;
                        }

                        .price_tag {
                            font-family: $primaryFont;
                            font-size: 1.375rem;

                            + .price_tag {
                                color: $ctaColor;
                                text-decoration: line-through;
                                margin-left: 1rem;
                            }
                        }
                    }
                }
            }

            #addToWishlistButtonContainer {
                position: absolute;
                right: 0;
                top: 0;
                width: 25px;
                height: 22px;

                .btnWishlist {
                    width: 100%;
                    height: 100%;
                    border: 0;
                    box-shadow: none;
                    cursor: pointer;

                    i {
                        &.wish {
                            background: url(../svg/wishlist_25x22.svg);
                            width: 25px;
                            height: 22px;
                        }
                    }

                    &:hover {
                        background-color: white;

                        i {
                            &.wish {
                                background: url(../img/wishlist_exist_25x22.png);
                                width: 25px;
                                height: 22px;
                            }
                        }
                    }
                }
            }
        }

        .description {
            display: block;
            padding: 1rem 0 0.2rem;
            font-size: 0.9375rem;
            line-height: 1.125rem;
            min-height: 51px;
        }

        .ligne_form,
        .size_holder {
            margin-bottom: 1.2rem;

            @include smallScreen {
                margin-bottom: 0.5rem;
            }
        }
    }

    #product_selects {
        margin-bottom: 1.2rem;

        @include averageScreen {
            margin-bottom: 1rem;
        }

        .cta--secondary {
            width: fit-content;
            font-size: 0.6875rem;
            line-height: 13px;
            text-align: center;
            letter-spacing: 0.2em;

            &:hover {
                &::after {
                    background-color: $primaryColor;
                }
            }
        }
    }

    #ligne_qte {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 103px;
        margin: 0 auto 0 0;

        label[for='qteProd'] {
            text-transform: uppercase;
            font-family: $primaryFont;
            font-size: $fs_mid;
            padding-right: 1.625rem;
            display: none;
        }

        .holder_qte_selector {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 0.5rem;

            .qte_error_msg {
                color: $red;
                display: none;
            }
        }

        .restock_date {
            width: 100%;
            font-family: $primaryFont;
        }

        .restock_date,
        .qte_error_msg {
            font-size: $fs_mid;
            font-family: $primaryFont;
        }

        #qteProd {
            text-align: center;
            font-family: $primaryFont;
            font-size: 1.25rem;
        }

        .qte_selector {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            border: 1px solid $ctaColor;
            color: $ctaColor;
            background-color: $whiteColor;

            &:not(.selector_disabled) {
                cursor: pointer;
            }

            &.qte_minus,
            &.qte_plus {
                position: relative;

                &::after {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    color: $ctaColor;
                    font-size: $fs_txt;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            &.qte_plus {
                &::after {
                    content: '+';
                }
            }

            &.qte_minus {
                &::after {
                    content: '-';
                }
            }

            &.selector_disabled {
                border-color: rgba(206, 122, 81, 0.5);
                color: rgba(206, 122, 81, 0.5);
                pointer-events: none;
            }
        }

        &.ligne_form {
            &#ligne_couleur {
                .choices_list {
                    display: flex;
                    column-gap: 1rem;
                }
            }

            &#ligne_qte #qteProd {
                outline: none;
                border: 0;

                &:focus {
                    border: 1px solid $borderGrey;
                }

                option {
                    text-align: center;

                    &:hover {
                        background-color: $borderGrey;
                    }
                }
            }

            &#ligne_qte #qteProd,
            &.productSizeFieldset {
                position: relative;
                width: 40px;
                height: 33px;
                padding: 0 0px 0 0px;
                margin-bottom: 0;
                box-sizing: border-box;
                display: inline-block;
                text-align: end;

            }

            @-moz-document url-prefix() {
                &#ligne_qte #qteProd,
                &.productSizeFieldset  {
                    text-align: center;
                }
            }

            &.productSizeFieldset {
                .prod_listes {
                    height: 33px;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                &.actif {
                    background-color: $borderGrey;

                    &:after {
                        transform: translateY(-50%) rotate(-180deg);
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    top: 50%;
                    right: 0.5rem;
                    transform: translateY(-50%);
                    cursor: pointer;
                }

                span.choosenSize {
                    font-family: $primaryFont;
                    font-size: $fs_def;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 0.02rem;
                }
            }
        }
    }

    .btnWishlist {
        position: absolute;
        padding: 0;
        right: 0;

        > span {
            display: none;
        }

        &.existToWishlistButton {
            background-image: url(../img/wishlist_exist_25x22.png);
            width: 25px;
            height: 22px;
        }
    }

    .form_submit:not(#addToWishlistButtonContainer) {
        .find_retailer {
            width: 100%;
            margin-top: 3px;
        }
    }

    #btn_add_alert {
        border: 1px solid $ctaColor;

        &:hover {
            color: white;
        }
    }
}
