body {
    font-weight: 100;

    .w-radio-input {
        .w-input-label {
            span {
                color: $primaryColor  !important;
                font-size: 14px;
            }
        }
    }

    .email-form-line,
    .pwd-form-line,
    .w-lostpass-form {
        .w-nonempty {
            .w-input-label {
                top: 47%;

                span {
                    font-size: 12px;
                }
            }
        }
    }

    .w-oneclick-connect {
        .w-social-btn {
            & > div {
                width: 90%;
            }
        }

    }
    
    &.customer.body_login {
        .w-oneclick-connect {
            #googleConnect {
                background: $aquaHaze;

                .g_id_signin {
                    div:first-child div:first-child div:first-child {
                        width: 2.4rem;
                        height: 2.4rem;
                    }
                }
            }
        }
    }

    .login_politique_confidentialite_msg {
        font-size: 13px;
        line-height: 15.6px;
        font-family: $primaryFont;
        color: $secondaryColor;
        display: block !important;
        margin-top: 15px;

        a {
            text-decoration: underline;
        }
    }

    .w-oneclick-connect {
        display: flex;
        width: 100%;
    }

    #bottom_savoir_plus {
        text-align: center;
        line-height: 30px;
    }

    .btn_fermer {
        width: 20px;
        height: 20px;
        display: block;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;

        &::before {
            content: 'X';
            width: 20px;
            height: 20px;
            font-family: $ctaFont;
        }
    }

    .newlogin_page {
        width: 100%;
        flex-direction: inherit;
        position: relative;
        margin-bottom: 95px;
        max-width: $wrapperRayon;
        margin: 0 auto;

        .wrapper_bandeau_connexion {
            position: relative;
            padding-right: 3%;
            max-width: 910px;
            width: 53%;

            span {
                position: absolute;
                width: 100%;
                width: 0;
                height: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-size: 0 0 !important;
            }

            @media screen and (min-width: 1730px) {
                min-height: 712px;

                span {
                    min-height: 712px;
                }
            }

            img {
                max-width: 100%;

                &.fade_in {
                    animation: fadeIn 0.2s ease-in-out forwards;
                    pointer-events: initial;
                    transition: all 0.1s ease-in-out 0.2s;
                }
            }
        }

        .login_wrapper {
            color: $primaryColor;
            margin-top: -25px;
            width: 47%;
            min-height: 712px;
            opacity: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &.fade_in {
                animation: fadeIn 0.3s ease-in-out forwards;
                pointer-events: initial;
                transition: all 0.2s ease-in-out 0.3s;
            }

            .w-form-line {
                &.email-form-line {
                    margin-bottom: 1.875rem;
                }

                .w-password-input,
                .w-email-input {
                    min-height: 50px;
                }
            }
        }

        .w-newlogin-form {
            .intro {
                margin-top: -30px;
                text-align: left;
                font-size: 12px;
                font-weight: 100;
                color: $secondaryColor;
                font-family: $primaryFont;
            }

            #login_optin_fidelity + .intro {
                margin-top: 0;
            }

            .optin_container {
                &.column {
                    flex-direction: inherit;
                    align-items: inherit;
                }
            }

            .w-fieldset-label {
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: $primaryColor;
                font-family: $primaryFont;
                letter-spacing: 0;
                margin: 0 0;
                padding: 15px 0 0 0;
            }
        }

        .w-client-form {
            display: block;
        }

        .w-line-label {
            width: 25%;
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 0.6875rem;
            color: $primaryColor;
            letter-spacing: 0.04545em;
        }

        .w-input-label {
            color: $black;
        }

        .w-newlogin-form {
            width: 96.5%;
            margin: 10px 0 20px 0;
            min-height: 50px;

            .optin_container {
                .w-input-note.w-input-error {
                    text-align: left;
                    margin-bottom: 20px;
                    margin-bottom: 0;
                }
            }

            .optin_container.column > .w-form-line {
                justify-content: left;
                width: 175px;
                margin-right: 35px;
                display: -webkit-box;
                font-family: $primaryFont;
            }

            .optin_container {
                justify-content: inherit;
            }

            .w-form-line.w-submit {
                margin-top: 0px;
                margin-bottom: 0;
                max-width: fit-content;

                button.w-submit-button {
                    padding: 0 3em;
                }
            }

            .optin_container.column > .w-form-line .w-line-label {
                min-width: 50px;
                margin: 2px 5px 0px 0px;
                font-size: 15px;
                display: block;
            }
        }

        .w-newlogin-form .w-pwd-reset {
            margin: 10px 0 0 0;
            color: $primaryColor;
            font-size: 15px;
        }

        .w-newlogin-form {
            .knowMoreOptin {
                display: none;

                .info_site {
                    a {
                        color: $primaryColor;
                        font-size: 15px;
                        text-transform: lowercase;
                    }
                }
            }
        }

        #subtitlePassword {
            margin-bottom: 10px;
        }

        .w-form-line {
            margin-bottom: 15px;
        }

        .login_bloc {
            &:last-of-type {
                padding-top: 1.45rem;
                width: 100%;
                padding-left: 5%;
                align-items: normal;
            }

            &:first-of-type {
                padding-bottom: 0rem;
                border-bottom: 2px solid $lightBorder;
                width: 84%;
                margin-left: 5%;
                align-items: normal;
            }
        }

        .w-input {

            input[type='email'],
            input[type='password'],
            input[type='text'] {
                border-radius: 0;
                width: 100%;

                &:focus-visible {
                    outline-offset: 0px;
                    outline: -webkit-focus-ring-color auto 0px;
                }
            }
        }

        .w-input-note {
            position: absolute;
            margin: 0.33333em 0 0.66667em;
        }

        .w-lines-group {
            position: relative;
            width: 100%;
            margin-bottom: 0;
            border: 0;
            padding: 10px 0;
        }

        .w-input-label {
            span {
                color: $secondaryColor;
                font-size: 14px;
            }
        }

        .login_bloc {
            font-family: $primaryFont;
            width: 100%;
            text-align: left;

            .title {
                font-family: $primaryFont;
                text-align: left;
                width: 100%;
                margin-bottom: 0;
                color: $primaryColor;

                .acc_login_title {
                    font-family: $primaryFont;
                    letter-spacing: 0;
                    margin-bottom: 0.3rem;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 60px;
                    line-height: 72px;
                    text-transform: capitalize;
                    color: $primaryColor;
                }

                .subtitle {
                    line-height: 18px;
                    color: $primaryColor;
                    margin-top: 1.2rem;
                }

                &.my_account {
                    .subtitle {
                        display: none;
                    }
                }

                :has(.vp_connexion) & {
                    .acc_login_title + .subtitle {
                        display: block;

                        font-family: $primaryFont;
                        color: $primaryColor;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 1.3;
                        text-transform: none;
                        letter-spacing: normal;

                        margin-top: 5px;
                        margin-bottom: 20px;
                    }   
                }

                #subtitleLogin {
                    font-size: 15px;
                }

                .my_account_title {
                    font-family: $primaryFont;
                    letter-spacing: 0rem;
                    margin-bottom: 0.3rem;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 60px;
                    line-height: 72px;
                    text-transform: capitalize;
                    color: $primaryColor;
                }

                h3 {
                    font-family: $primaryFont;
                    margin-top: 0;
                    font-size: 24px;
                    letter-spacing: 0;
                    margin-bottom: 0.5rem;
                    font-weight: 400;
                    text-transform: initial;
                    color: $primaryColor;
                }

                h4,
                .acc_quick_access {
                    font-family: $primaryFont;
                    font-size: 24px;
                    letter-spacing: 0rem;
                    margin-bottom: 1.2rem;
                    font-weight: 400;
                    text-transform: initial;
                    color: $primaryColor;
                }
            }
        }
        
        #login_optin_fidelity {
            display: flex;
            padding: 15px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 10px;
            background-color: #FAEFE9;
            margin-bottom: 20px;

            p {
                font-size: 15px;
                line-height: 1.2;
            }

            .w-checkbox-input {
                height: auto;
                .w-input-element+.w-input-label::before {
                    flex-shrink: 0;
                }

                span {
                    font-size: 15px;
                    font-family: $primaryFont;
                    color: $primaryColor;
                }

                a {
                    color: $primaryColor;
                    text-decoration: underline;
                }
            }
        }
    }
}

.w-lostpass-form {
    .w-submit-button {
        width: 188px;

        &.w-secondary-btn {
            background-color: $ctaColor  !important;
            color: $whiteColor  !important;
            box-shadow: 0 0 0 1px $whiteColor inset !important;
        }

        &:hover {
            span {
                padding: 0px 0px !important;
                box-shadow: none !important;
            }
        }
    }
}

.optin_container .w-input-note.w-input-error {
    display: none !important;
}

#js-signUpForm .w-submit-button {
    span {
        padding: 13px 35px;
    }
}

#auth .w-submit-button {
    padding: inherit;
    span {
        padding: 13px 38px;
    }
}

@media only screen and (max-width: 1400px) {

    body.body_login {
        .newlogin_page {
            .login_wrapper {
                color: $primaryColor;
                margin-top: -8px;
                width: 47%;
                min-height: auto;
                opacity: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
            .w-newlogin-form {
                .optin_container.column > .w-form-line {
                    justify-content: left;
                    width: 175px;
                    margin-right: 5px;
                    display: -webkit-box;
                    font-family: $primaryFont;
                }
                .optin_container.column > .w-form-line .w-line-label {
                    min-width: 35px;
                    margin: 2px 5px 0 0;
                    font-size: 15px;
                    display: block;
                }
            }
        }
    }

    body.body_login #auth .w-contact-form .file-input-row label,
    #auth .w-submit-button,.w-contact-form .file-input-row #auth label {
        width: 140px !important;
    }
    #auth {
        .w-submit-button {
            width: auto;
        }
    }
}

@media only screen and (max-width: 1200px) {
    body.body_login {
        .newlogin_page {
            .w-newlogin-form {
                .optin_container.column > .w-form-line {
                    justify-content: left;
                    width: 175px;
                    margin-right: 5px;
                    display: -webkit-box;
                    font-family: $primaryFont;
                }
                .optin_container.column > .w-form-line .w-line-label {
                    min-width: 35px;
                    margin: 2px 5px 0 0;
                    font-size: 15px;
                    display: block;
                }
            }
        }
    }

    body {
        .newlogin_page {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            min-height: 453px;
            padding-top: 30px;

            .w-lines-group {
                .optin_container.column {
                    flex-direction: column !important;
                    align-items: left !important;
                }
            }

            #js-signUpForm .w-submit-button {
                width: 100%;

                span {
                    padding: 13px 35px;
                }
            }

            #auth .w-submit-button {
                width: 60%;

                span {
                    padding: 13px 38px;
                }
            }
        }

        .body_login {
            .login_bloc:last-of-type {
                padding-top: 1.45rem;
                width: 100%;
                padding-left: 5%;
                align-items: normal;
            }
        }
    }
}