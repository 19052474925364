/** 
 * Home ADV 
 */

/**
 * Cover module
 */
.cover-module {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto 80px;

    .swiper-slide {
        overflow: hidden;
    }

    .wrapperProdVertical {
        background: linear-gradient(90deg, #c48268 0, #fdc0a4 49%, #c48268 100%);

        @media only screen and (min-device-width: 768px) and (max-device-width: 1280px) {
            .pricetag {
                font-size: 19px;
            }
        }
    }

    .slide_video {
        display: flex;
        position: relative;
        height: 100%;
        width: 100%;
        z-index: 1;
        padding-bottom: 47.505%;
        min-height: unset;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            transform: unset;
        }
    }

    a.home-module-link {
        display: block;
        position: relative;
        width: 100%;
        height: calc(100vw / (1920 / 930));
        min-height: 496px;
        max-height: 930px;
    }

    .home-module-title {
        font-family: $primaryFont;
        font-style: italic;
        font-weight: 400;
        font-size: 80px;
        margin-bottom: 3px;
    }

    .cover-module-cta {
        position: relative;
        font-family: $ctaFont;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: .2em;
        text-transform: lowercase;
        padding-bottom: 5px;

        span {
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            transition: left ease-in .2s;
        }

        &:hover span {
            left: 100%;
        }
    }

    .home-module-contents {
        position: absolute;
        z-index: 1;

        &.align-left {
            left: 5%;
        }

        &.align-center {
            left: 50%;
            transform: translateX(-50%);
        }

        &.align-right {
            right: 30%;
        }

        &.align-top {
            top: 15%;
        }

        &.align-middle {
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
        }

        &.align-bottom {
            bottom: 15%;
        }

        &.align-center.align-middle {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .home-module-bg {
        width: 100%;
        height: calc(100vw / (1920 / 930));
        min-height: 496px;
        max-height: 930px;
    }

    .tg-module {
        position: absolute;
        z-index: 5;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
        width: 17.8vw;
        max-width: 314px;
        min-width: 220px;
        margin-bottom: 0;

        .swiper-wrapper {
            flex-direction: column;
        }

        .wrapperProdVertical {
            width: 100%;
            margin-bottom: 1.7rem;

            &:last-of-type {
                margin-bottom: 0;
            }

            .imgWrapper {
                height: 21vw;
                max-height: 391px;
            }

            a.block_lnk {
                width: auto;
                max-width: none;
                padding-bottom: 0;
                height: calc(337 / 426 * 15.4vw);
                max-height: 246px;
            }

            .imgProd {
                width: 100%;
                min-width: 160px;
                max-width: 100%;
            }

            .item_name {
                height: unset;
                min-height: unset;
                padding-bottom: 8px;
                font-size: 10px;
                width: 85%;

                @media screen and (min-width: 1400px) {
                    padding-bottom: 15px;
                    font-size: 16px;
                }
            }

            .bloc_add_color .w-submit-button span,
            .bloc_add_color button.w-submit-button span {
                margin-block: auto;
                font-size: clamp(7px, .7vw, 14px);
            }

            .bloc_add_color .w-submit-button>span,
            .bloc_add_color button.w-submit-button>span {
                max-width: 70%;
                display: inline-block;
            }

            .item_price .price,
            .wrapper_description>.item_price>.pricetag {
                font-size: clamp(13px, 1.1vw, 24px);
            }

            .bloc_add_color .w-submit-button .pricetag,
            .bloc_add_color button.w-submit-button .pricetag {
                font-size: clamp(7px, .7vw, 14px);
            }
        }
    }

    .swiper-pagination {
        bottom: 50px;
        width: 100%;

        &-bullet {
            margin: 4px;
            background: #fff;
            opacity: .4;

            &-active {
                opacity: 1;
            }
        }
    }
}

/* code apply only on chrome */ 
html.chrome {
    .cover-module {
        .wrapperProdVertical {
            .bloc_add_color .w-submit-button .pricetag,
            .bloc_add_color button.w-submit-button .pricetag {
                // le .1px car gulp remplace 0px par 0 et clamp(0 ne fonctionne pas
                margin-top: clamp(.1px, 0.2vw, 5px);
            }
        }
    }
}

/**
 * TG Modules
 */
.tg-module {
    margin-bottom: 80px;

    .home-module-title {
        font-family: $primaryFont;
        font-style: italic;
        font-weight: 400;
        font-size: 60px;
        text-align: center;
        text-transform: initial;
        margin-bottom: 10px;
    }

    .tgHomeSwiper {
        max-width: 1022px;
    }

    .swiper-wrapper {
        justify-content: space-between;
    }

    .wrapperProdVertical {
        width: 31%;
    }

    .btn-product {
        width: fit-content;
        padding: 0 3em;
        margin: 1rem auto 0;
    }

    .wrapperProdVertical .bloc_add_color button.w-submit-button .item_price:before {
        content: '|';
        width: 1em;
    }
}

/**
 * Texte + bg image
 */
.text-image {
    position: relative;
    max-width: 1920px;
    margin-bottom: 80px;

    &.text-no-container {
        margin-top: 2rem;

        .slide_video {
            display: flex;
            position: relative;
            height: 100%;
            width: 100%;
            z-index: 1;
            padding-bottom: 18.2299%;
            min-height: unset;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                transform: unset;
            }
        }
    }

    &.container-block {
        display: flex;
        width: 68%;
        max-width: 1280px;
        padding: 0;
        margin: 0 auto;
        margin-bottom: 80px;

        .slide_video {
            display: flex;
            position: relative;
            height: 100%;
            width: 57%;
            z-index: 1;
            min-height: unset;
            max-width: 714px;
            padding-bottom: calc(670 / 1150* 100%);

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                transform: unset;

                .vp-center {
                    display: block;
                }
            }
        }

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            align-items: center;
        }

        .home-module-link {
            width: 57%;
            max-width: 714px;
            padding-bottom: calc(670/1150*100%);
        }

        .home-module-bg.loaded {
            width: 100%;
            height: auto;
            vertical-align: top;
            margin: 4% 0;
        }

        .block-txt {
            width: 43%;
            margin: auto;
            padding-left: 4%;

            h2.home-module-title {
                font-size: 3.125vw;
            }

            .home-module-text {
                font-family: $primaryFont;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: normal;
                text-transform: none;
                color: $primaryColor;
            }

            .home-module-cta.secondary {
                display: inline-block;
                width: auto;
                padding: 16px 22px;
                @extend .button;

                &:hover span {
                    left: 0;
                    color: $whiteColor;
                }

                span {
                    position: inherit;
                    top: auto;
                    height: auto;
                    white-space: nowrap;
                    color: inherit;
                    transition: none;
                }
            }

            @media screen and (max-width: 1024px) {
                width: 100%;
                padding: 12.5% 0 0 0;

                .home-module-cta.secondary {
                    display: flex;
                    width: fit-content;
                    margin: 0 auto;
                }
            }
        }

    }

    a.home-module-link {
        display: block;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: calc(350 / 1920 * 100%);
    }

    .home-module-bg {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
    }

    .block-text {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
    }

    .home-module-title {
        font-family: $primaryFont;
        font-style: italic;
        font-weight: 400;
        font-size: 60px;
        color: #fff;
    }

    .home-module-text {
        font-family: $ctaFont;
        font-size: 14px;
        letter-spacing: .2em;
        color: #fff;
        text-transform: lowercase;
        margin: .6rem 0 1.5rem;
    }

    .home-module-cta {
        position: relative;
        font-family: $ctaFont;
        font-size: 14px;
        letter-spacing: .2em;
        color: #fff;
        text-align: center;
        text-transform: lowercase;

        span {
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            transition: left ease-in .2s;
        }

        &:hover span {
            left: 100%;
        }

        &:hover {
            color: black !important;
        }
    }
}

/**
 *
 */
.three-images {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 12%;
    width: 96%;
    max-width: 1280px;
    margin: 0 auto 80px;

    &.container-block {
        .slide_video {
            display: flex;
            position: relative;
            height: 100%;
            width: 100%;
            z-index: 1;
            padding-bottom: 83.334%;
            min-height: unset;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                transform: unset;
            }
        }
    }

    .home-column {
        width: 24%;
        max-width: 300px;
        text-align: center;
        margin-bottom: 1rem;

        &:hover {
            .home-module-cta span {
                border-bottom: 0;
            }
        }
    }

    .home-module-img {
        width: 100%;
        height: auto;
        vertical-align: top;
    }

    .home-module-title {
        font-size: 35px;
        font-style: italic;
        font-weight: 400;
        margin: .5rem 0 1rem;
    }

    .home-module-cta {
        position: relative;
        font-family: $ctaFont;
        font-size: 14px;
        text-transform: lowercase;
        letter-spacing: .1rem;
        margin-top: 1rem;

        span {
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            transition: left ease-in .2s;
            background-color: $ctaColor;
        }

        &:hover span {
            left: 100%;
        }
    }
}

/**
 * Instagram module
 */
.social-module {
    width: 98%;
    max-width: 1280px;
    margin: 0 auto 80px;

    .home-module-contents {
        margin-bottom: 95px;
    }

    .home-module-pretitle {
        font-size: 60px;
        line-height: 72px;
        font-style: italic;
        margin-top: 78px;
        z-index: 4;
        position: relative;
    }

    #hashtag_module_frame {
        margin-top: -38px;

        .swiper-container {
            display: flex;
            height: 320px;

            .swiper-slide {
                width: 25%;
                height: 0;
                overflow: hidden;
                padding-bottom: calc(300 / 300 * 25%);

                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }

                video {
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                }
            }
        }
    }
}

.homepage .tg-module.module-tree.container-block .btn-product.secondary:hover {
    color: $whiteColor !important;
}

body.homepage {
    .text-image.container-block {
        top: 70px;
    }
}

body.cms_page .container {

    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
}

// MODULE TG DESKTOP

.tg-module.module_adv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1726px;
    margin: 0 auto;
    padding: 40px 0;

    .home-module-contents {
        width: 100%;
    }

    .swiper-wrapper {

        @media only screen 
        and (min-width: 768px) 
        and (max-width: 1280px) {
            column-gap: 5%;
        }
    }

    .tgHomeSwiper.swiper-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .wrapperProdVertical {
            width: 20%;

            @media only screen 
            and (min-width: 768px) 
            and (max-width: 1280px) {
                width: 21%;
            }
        }
    }

    

    .home-column-txt {
        max-width: 1140px;
        width: 100%;
        margin: 1rem auto;
        font-family: $primaryFont;
        color: $primaryColor;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: 0;
        text-align: center;
    }

    @media screen and (max-width: 1281px) {

        .wrapperProdVertical .wrapper_description {
            padding-top: 2rem;
        }
    }
}

// MODULE images-2textes DESKTOP

.cms_page_content .cms-page-module.images-2textes {
    max-width: 1403px;
    width: 100%;

    .cms-page-columns-container {
        display: flex;
    }

    .cms-page-column.column_1 {
        position: relative;
        width: 55.438vw;
        overflow: hidden;
        cursor: pointer;

        div.link_img_2textes {
            display: block;
            position: relative;
            padding-top: calc(822 / 1014 * 100%);
            height: 100%;

            .cms-page-module-img {
                height: 100% !important;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }
 
        @media screen and (max-width: 1160px) {
            height: auto;
        }

        .slide_video {
            display: flex;
            position: relative;
            height: 100%;
            width: 100%;
            z-index: 1;
            padding-bottom: 81.066%;
            min-height: unset;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                transform: unset;
            }
        }
    }

    .cms-page-contents__2textes {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        flex-direction: column;
        z-index: 3;

        .contents-textes-container {
            width: 100%;
            align-content: center;
            justify-content: center;

            .cms-page-module-cta {
                display: flex;
                font-family: $ctaFont;
                flex-direction: row;
                justify-content: center;
                width: 100%;
                height: 48px;
                align-items: center;
                border: 1px solid $whiteColor;
                color: $whiteColor;
                background-color: transparent;
                padding: 16px 42px;
                max-width: 349px;
                margin: 0 auto;
                font-size: 14px;
                letter-spacing: .2em;
                text-transform: lowercase;
                text-decoration: none;
                line-height: .9;
            }

            .cms-page-module-cta:hover {
                transition: background-color 0.2s ease-in-out;
                background-color: $ctaColor;
            }
        }

        @media screen and (max-width: 1200px) {
            .cms-page-module-title {
                height: 60px;

                span {
                    font-size: 28px;
                }
            }
            
            .cms-page-module-text {
                margin: 0rem auto 1rem !important;

                span {
                    font-size: 10px !important;

                    h1 {
                        font-size: 20px;
                    }
                }
            }
        }

        @media screen and (max-width: 1160px) {
            .cms-page-module-title {
                margin: 30px auto 0;
            }
        }

        .cms-page-module-title {
            width: 80%;
            margin: 0 auto;
            font: italic 400 60px/72px $primaryFont;
            font-size: clamp(25px, calc(3vw + 4px), 60px);
            text-align: center;
        }

        .cms-page-module-text {
            width: 80%;
            margin: 1rem auto 3rem;
            text-align: center;
            font-family: $ctaFont;

            span {
                letter-spacing: 0.2em;
                color: $whiteColor;
                text-align: center;
                text-transform: lowercase;
            }
        }

        .cms-page-module-link {
            background-color: transparent;
            box-shadow: none;
            margin: 0px auto;

            &:hover {
                background-color: $ctaColor;
                box-shadow: inset 0 0 0 2px #fff;
            }
        }
    }

    .cms-page-module-contents {
        max-width: 301px;
        width: 31%;
        margin-left: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .cms-page-module-title {
            font-family: $primaryFont;
            font-size: 2.08vw;
            font-weight: 400;
            font-style: italic;
            line-height: 1;
            color: $primaryColor;
            margin-bottom: 5px;
        }

        .cms-page-module-text {
            font-family: $primaryFont;
            font-weight: 400;
            line-height: 22px;
            color: $primaryColor;
        }

        .cms-page-module-link {
            margin: 0;
            border-color: $ctaColor;
        }
    }
}

// MODULE 1image texte DESKTOP

.cms_page_content .cms-page-module.text-image-1__fullwidth {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    padding: 40px 0;

    .cms-page-column.column_1 {
        position: relative;
        display: block;
        max-width: 1920px;
        width: 100vw;

        .link_img {
            width: 100%;
        }

        .picture {
            position: relative;
            padding-top: calc(550 / 1920 * 100%);
            width: 100%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
            }
        }

        .slide_video {
            display: flex;
            position: relative;
            height: 100%;
            width: 100%;
            z-index: 1;
            padding-bottom: 28.646%;
            min-height: unset;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                transform: unset;
            }
        }
        .content {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 10%;
            left: 0;
            top: 34%;
            text-align: center;
            z-index: 2;

            @media screen and (max-width: 1250px) {
                top: 23%;
            }
        }

        .cms-page-module-title {
            font-family: $primaryFont;
            font-size: clamp(30px, 2.833vw, 60px);
            font-style: italic;
            font-weight: 400;
            line-height: 72px;
            letter-spacing: 0;
        }

        a.link_text_fullwidth:hover {
            color: inherit;
        }

        .home-column-txt {
            margin: 5px 0 25px 0;
            font-family: $ctaFont;
            font-size: clamp(12px, 0.833vw, 16px);
            line-height: 17px;
            letter-spacing: 0.2em;
            text-transform: lowercase;
        }

        .cms-page-module-cta {
            font-family: $ctaFont;
            font-size: 14px;
            letter-spacing: .2em;
            text-transform: lowercase;
            text-decoration: none;
            line-height: .9;
        }
    }
}

// Integration champs textes

.cms_page_content {

    .cms-page-module.images-2textes,
    .cms-page-module.text-image-1__fullwidth,
    .tg-module.module_adv,
    .text-1 {

        .cms-page-module-text,
        .home-column-txt {
            margin-bottom: 2rem;
            font-size: .9375rem;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-weight: 600;
            }

            h1 {
                font-size: 24px;
            }

            h2 {
                font-size: 22px;
            }

            h3 {
                font-size: 20px;
            }

            h4 {
                font-size: 18px;
            }

            h5 {
                font-size: 16px;
            }

            h6 {
                font-size: 14px;
            }
        }
    }
}

body.homepage,
body.category,
body.cms_page_new {
    .newGondoleSwiper {
        padding: 0 2rem;
        position: relative;
        margin: 0 auto;

        @media only screen and (width: 1024px) {
            width: calc(1022px - 1rem);
        }

        @media only screen and (min-width: 1224px) {
            width: calc(1022px + 4rem);
        }

        #gondole_tghome {
            margin: 0 auto;
            position: relative;
            overflow: hidden;
            list-style: none;
            padding: 0;
            z-index: 1;

            .swiper-wrapper {
                justify-content: inherit;
            }
        }

        .swiper-button-prev.bgSprite.bfr.arrow {
            display: block;
            background-size: 10px 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../svg/arrow_prev.svg);
            left: 0.5rem;
        }
    
        .swiper-button-next.bgSprite.bfr.arrow {
            display: block;
            background-size: 10px 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../svg/arrow_next.svg);
            right: 0.5rem;
        }
        
        .arrow.swiper-button-disabled.swiper-button-lock {
            display: none !important;
        }
    }

    .slide_video {
        width: 100%;
        height: calc(100vw / (1920 / 930));
        min-height: 496px;
        max-height: 930px;

        iframe {
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
