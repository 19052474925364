.breadcrumbs {
    margin-bottom: 1.375rem;
    margin-top: 25px;

    .breadcrumb {
        display: inline;
        color: $secondaryColor  !important;
        font-family: $primaryFont;
        font-size: $fs_min;
        width: auto;

        &::first-letter {
            text-transform: uppercase;
        }

        a {
            color: $secondaryColor;
        }

        &::after {
            padding: 0 0 0 5px;
            content: '|';
        }

        &:last-child() {
            &::after {
                padding: 0 0 0 5px;
                content: '';
            }
        }
    }
}