/**
 *
 */
.wrapperProdVertical {
    position: relative;
    background: linear-gradient(90deg,#fff 0,#fff 49%,#fff 100%);

    // On mouse hover
    &:hover, &.actif {
        background: linear-gradient(90deg,#c48268 0,#fdc0a4 49%,#c48268 100%);

        .imgProd +.imgProd,
        .wishlistBtn, .add_wishlist {
            opacity: 1;
        }

        .cta_express {
            display: block;
            opacity: 1;
            transition: opacity .3s ease-in-out;
            margin-left: auto;
            margin-right: auto;
            transform: translateY(-1px);
        }

        .wrapper_description {
            background: $white; 

            @media screen and (max-width: 1400px) {
                height: 55px;
            }

            .item_price {
                display: none;
            }

            .delivery {
                display: none !important;
            }
        }
    }

    // Special case when Out Of Stock
    &.out_of_stock {
        .imgProd {
            filter: grayscale(1);
        }

        &:hover {
            background: linear-gradient(90deg,#929292 0,#cdcdcd 50%,#929292 100%);

            .expressAlertStock {
                display: block !important;
            }
        }

        .bloc_add_color {
            display: none !important;
        }
    }

    #eclat_new,
    #eclat_stock_limited,
    #eclat_temporary_edition,
    #eclat_web_exclusivity,
    .specifics_eclats{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        transition: all ease-in-out 250ms;
        @media only screen 
        and (min-width: 768px) 
        and (max-width: 1280px) {
            max-width: 180px;
        }

    }

    .eclat_product_2,
    .eclat_product {
        position: absolute;
        left: 20px;
        top: 5px;
        z-index: 20;

        @media only screen 
        and (min-width: 768px) 
        and (max-width: 1280px) {
            left: 15px;
        }
    }

    .imgWrapper {
        background-color: #fff;
        position: relative;
        margin: 7px;
        overflow: hidden;
    }

    a.block_lnk {
        display: block;
        position: relative;
        width: 100%;
        max-width: 426px;
        height: 0;
        padding-bottom: calc(355 / 426 * 100%);
        @media only screen 
        and (min-width: 768px) 
        and (max-width: 1280px) {
            height: 0;
            padding-bottom: calc(228 / 314 * 100%);
        }
    }

    .imgProd {
        position: absolute;
        opacity: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        transition: opacity .3s ease-in-out;

        +.imgProd {
            opacity: 0;
        }
    }

    .wrapper_description {
        height: 8.5rem;
        text-align: center;
        padding-top: 0.4rem;
        box-sizing: border-box;
        position: relative;
        background-color: $white;

        @media only screen and (min-width: 1400px) {
            background-color: transparent;
        }

        @media only screen 
        and (min-width: 600px) 
        and (max-width: 960px) {
            height: 6rem;
        }

        .delivery:not(.home_delivery):not(.store_delivery) {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 13px;
            color: $ctaColor;
            font-size: 14px;
            margin-top: 16px;
            
            .home_delivery,
            .store_delivery {
                display: flex;
                flex-direction: row;
                justify-content: center;

                &:has(+ :is(.store_delivery, .home_delivery)) {
                    padding-right: 13px;
                    border-right: 1px solid $ctaColor;
                }
            }

            .home_delivery {

                &::before {
                    content: ' ';
                    background: url('../svg/icon_home_delivery.svg') no-repeat 100%;
                    display: block;
                    width: 21px;
                    height: 14px;
                    margin-right: 6px;
                    transform: translateY(-2px);
                }
            }

            .store_delivery::before {
                content: ' ';
                background: url('../svg/icon_store_delivery.svg') no-repeat 100%;
                display: block;
                width: 14px;
                height: 14px;
                margin-right: 6px;
                transform: translateY(-2px);
            }
        }
    }

    .item_name {
        display: block;
        width: 90%;
        height: 3.5em;
        margin: 0 auto;
        font-family: $ctaFont;
        font-size: 14px;
        letter-spacing: .1rem;
        line-height: 1.2;
        text-transform: lowercase;
        @media only screen 
        and (min-width: 768px) 
        and (max-width: 1180px) {
            width: 99%;
            height: 6em;
            font-size: 12px;
        }

        @media only screen 
        and (min-width: 600px) 
        and (max-width: 960px) {
            height: 3.7em;
            overflow: hidden;
        }

    }

    .item_subtitle {
        display: none;
    }

    .pricetag {
        font-size: 24px;
        
        @media only screen 
        and (min-width: 768px) 
        and (max-width: 1180px) {
            font-size: 20px;
        }
        
        @media only screen 
        and (min-width: 600px) 
        and (max-width: 960px) 
        and (orientation: portrait) {
            margin-top: 5px;
        }
    }

    .delivery {
        display: block;
        color: $ctaColor;
        font-size: 12px;
    }

    .home_delivery::before {
        content: ' ';
        background: url('../svg/icon_home_delivery.svg') no-repeat 100%;
    }

    .store_delivery::before {
        content: ' ';
        background: url('../svg/icon_store_delivery.svg') no-repeat 100%;
    }

    .cta_express {
        display: none;
        width: 90%;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        margin: 20.5px 0;

        @media only screen and (min-width: 1400px) {
            position: absolute;
            bottom: 3%;
            left: 5%;
            margin-bottom: unset;
        }

        @media only screen 
        and (min-width: 768px) 
        and (max-width: 1224px) {
            .bloc_add_color {
                button.w-submit-button {  
                    font-size: 10px;
                    letter-spacing: 0.1em;
                    padding: 0 5px;

                    .item_price {
                        &:before {
                            font-size: 13px;
                        }

                        .pricetag {
                            font-size: 11px;
                        }
                    }
                }
            }
        }

        @media only screen 
        and (min-width: 1224px) 
        and (max-width: 1700px) {
            .bloc_add_color {
                button.w-submit-button {  
                    font-size: 12px;
                    letter-spacing: 0.1em;
                    padding: 0 5px;

                    .item_price {
                        &:before {
                            font-size: 12px;
                        }

                        .pricetag {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    .bloc_add_color {
        .button,
        .w-submit-button,
        button.w-submit-button {
            width: 100%;
            height: 40px;

            @media only screen and (min-width: 1400px) {
                height: 54px;
            }

            .item_price {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &:before {
                    content: '|';
                    display: inline-block;
                    width: 2em;
                    text-align: center;
                    font-family: sans-serif;
                    font-size: 14px;
                }
            }

            .pricetag {
                font-family: $primaryFont;
                font-size: 18px;
            }
        }
    }

    // Wishlist BTN
    .wishlistBtn, .add_wishlist {
        position: absolute;
        right: 15px;
        top: 15px;
        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    // Stock alert
    .expressAlertStock {
        background-color: white;
        padding-top: 1.5rem;

        .alert_return {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }

        .alert_stock {
            text-align: center;
            margin-bottom: .5rem;
        }

        .bloc_add_alert_form {
            .form_submit {
                position: absolute;
                right: 10px;

                button {
                    cursor: pointer;
                }
            }

            input {
                padding-right: 2.5rem;
    
                &.inputErr {
                    border-color: red;
                }
            }
        }
    }
}

/**
 * General Wishlist btn styles
 */
.wishlistBtn, .add_wishlist {
    width: 26px;
    height: 26px;
    background: transparent url(../svg/icon_wishlist.svg) no-repeat center;
    background-size: contain;

    span{
        font-size: 0;
    }

    &:hover,
    &.existToWishlistButton {
        width: 26px;
        height: 26px;
        background: transparent url(../img/wishlist_exist_25x22.png) no-repeat center;
        background-size: contain;
        cursor: pointer;
    }
}

/* code apply only on chrome */ 
html.chrome {
    .cta_express .pricetag{
        margin-top: 0.5rem;

        @media only screen 
        and (min-width: 768px) 
        and (max-width: 1180px) {
            margin-top: 0.25rem;
        }
    }
}