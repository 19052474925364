
$wrapperXLarge : 1920px;
$wrapperLarge : 1680px;
$wrapperRayon : 1730px;
$wrapperContainer : 1700px;
$wrapperBandeauRayon : 1399px;
$wrapperMedium : 1480px;
$wrappermidMedium : 1280px;
$wrappermidSmall : 1023px;
$wrapperSmall : 1040px;
$wrapperXSmall : 970px;
$ipadPortrait : 720px;
$ipadLandscape : 1180px;

$maxWidthNavHouse: 1130px;

$fs_txt : .9375rem;
$fs_min : .75rem;
$fs_mid : .875rem;
$fs_subtitle : 1.5rem;
$fs_mediumsubtitle : 2rem;
$fs_midlarge : 2.1875rem;
$fs_large : 2.625rem;
$fs_xlarge : 3.4375rem;

$fs_def : 1rem;

$max_pad : 7.5rem;
$med_pad : 1.6rem;
$min_pad : 0.9rem;

$headerHeight : 170px;
$headerHeightMedium : 130px;
$headerHeightSmall : 75px;
